import { HStack, Text } from '@chakra-ui/react'
import PropTypes from 'prop-types'
import { useCallback } from 'react'

import { ExternalLink } from '@/components/Elements'

export const CorporationUrl = ({ label, url, children }) => {
    const corporationUrl = useCallback(() => {
        if (!url) {
            return undefined
        }
        const isUrl = /^https?:\/\/[\w/:%#\$&\?\(\)~\.=\+\-]+$/.test(url)
        if (isUrl) {
            return url
        }
    }, [url])()
    return (
        children && (
            <HStack>
                {label && (
                    <Text
                        fontSize="md"
                        whiteSpace="nowrap"
                        fontWeight="bold"
                        color="grey"
                    >
                        {label}
                    </Text>
                )}
                {corporationUrl ? (
                    <ExternalLink href={corporationUrl} wordBreak="break-all">
                        {children}
                    </ExternalLink>
                ) : (
                    <Text>{children}</Text>
                )}
            </HStack>
        )
    )
}

CorporationUrl.propTypes = {
    label: PropTypes.string.isRequired,
    url: PropTypes.string,
    children: PropTypes.string,
}
