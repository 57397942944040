export const clinicalDepartmentChoices = [
    { label: '美容', value: '美容外科' },
    {
        label: '小児科',
        value: '小児科',
    },
    {
        label: '内科',
        value: '内科',
    },
    {
        label: '産科',
        value: '産科',
    },
    {
        label: '婦人科',
        value: '婦人科',
    },
    {
        label: '整形外科',
        value: '整形外科',
    },
    {
        label: '循環器科',
        value: '循環器科',
    },
    {
        label: '消化器科',
        value: '消化器科',
    },
    {
        label: '心臓血管外科',
        value: '心臓血管外科',
    },
    {
        label: '脳神経外科',
        value: '脳神経外科',
    },
    {
        label: '眼科',
        value: '眼科',
    },
    {
        label: '形成外科',
        value: '形成外科',
    },
    {
        label: '歯科',
        value: '歯科',
    },
    {
        label: '精神科・神経科',
        value: '精神科・神経科',
    },
    {
        label: '血液内科',
        value: '血液内科',
    },
    {
        label: '外科',
        value: '外科',
    },
    {
        label: '泌尿器科',
        value: '泌尿器科',
    },
    {
        label: '神経内科',
        value: '神経内科',
    },
    {
        label: '呼吸器科',
        value: '呼吸器科',
    },
    {
        label: '皮膚科',
        value: '皮膚科',
    },
    {
        label: 'リハビリテーション科',
        value: 'リハビリテーション',
    },
    {
        label: '人工透析',
        value: '人工透析',
    },
    {
        label: '耳鼻咽喉科',
        value: '耳鼻咽喉科',
    },
    {
        label: '緩和ケア',
        value: '緩和ケア',
    },
    {
        label: '救急科',
        value: '救急科',
    },
    {
        label: '総合診療科',
        value: '総合診療科',
    },
    {
        label: 'その他',
        value: 'その他',
    },
]
