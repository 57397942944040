import { Text } from '@chakra-ui/react'
import { format, parseISO } from 'date-fns'

import { searchTimesTableCellPropType } from '../types/searchTimesTableCellPropTypes'

// 検索回数
export const SearchTimes = ({ data: searchHistories, row: { index } }) => {
    const searchHistory = searchHistories[index]
    return <Text>{searchHistory.searchTimes}&emsp;</Text>
}
SearchTimes.propTypes = searchTimesTableCellPropType

// 検索条件、検索履歴の詳細情報
export const SearchCriteria = ({ data: searchHistories, row: { index } }) => {
    const searchHistory = searchHistories[index]
    const description = <Text>{searchHistory.description}</Text>
    const historyDetail = (
        <>
            <Text fontSize="10px" color="#90A4AE">
                <Text as="span" mr="2">
                    {searchHistory.createdBy
                        ? `検索者 : ${searchHistory.createdBy}`
                        : ''}
                </Text>
                <Text as="span" mr="2">
                    {searchHistory.createdAt
                        ? `検索日 : ${format(
                              parseISO(searchHistory.createdAt),
                              'yyyy年MM月dd日 HH:mm'
                          )}`
                        : ''}
                </Text>
                <Text as="span" mr="2">
                    {searchHistory.updatedAt
                        ? `更新日 : ${format(
                              parseISO(searchHistory.updatedAt),
                              'yyyy年MM月dd日 HH:mm'
                          )}`
                        : ''}
                </Text>
                <Text as="span" mr="2">
                    {searchHistory.deletedAt
                        ? `削除日 : ${format(
                              parseISO(searchHistory.deletedAt),
                              'yyyy年MM月dd日 HH:mm'
                          )}`
                        : ''}
                </Text>
            </Text>
        </>
    )
    return (
        <>
            {description}
            {historyDetail}
        </>
    )
}
SearchCriteria.propTypes = searchTimesTableCellPropType
