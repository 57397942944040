import { Flex, Box } from '@chakra-ui/react'
import PropTypes from 'prop-types'

import { RecruitmentItem } from './'

export const TextWorkingHours = ({ label, children }) => {
    return (
        children && (
            <Flex>
                <RecruitmentItem>{label}</RecruitmentItem>
                <Box>{children}</Box>
            </Flex>
        )
    )
}

TextWorkingHours.propTypes = {
    label: PropTypes.string.isRequired,
    children: PropTypes.any.isRequired,
}
