import {
    Box,
    Button,
    Grid,
    GridItem,
    HStack,
    IconButton,
    Menu,
    MenuButton,
    MenuList,
    Spacer,
    Text,
} from '@chakra-ui/react'
import PropTypes from 'prop-types'

import { LogoMenu } from '../LogoMenu'
import { Footer } from '../MainLayout/Footer'
import { HamburgerMenu } from '../MainLayout/Header/HamburgerMenu'

import { ExtraMenuIcon, NewFacilityIcon } from '@/assets/icon'

export const AdminLayout = ({ children }) => {
    const OpenInNewTabButton = () => {
        const handleButtonClick = () => {
            window.open('/new-development', '_blank', 'noopener,noreferrer')
        }
        return (
            <IconButton
                icon={<NewFacilityIcon />}
                rounded="md"
                variant="ghost"
                fontSize="100px"
                border="none"
                minWidth="96x"
                height="38px"
                _hover={{
                    bg: '#EAEEFA',
                }}
                _active={{
                    bg: '#EAEEFA',
                }}
                onClick={handleButtonClick}
            />
        )
    }
    return (
        <Box
            minHeight="100vh"
            sx={{
                '--header-height': '100px',
            }}
        >
            <Grid
                h="full"
                gridTemplateRows="auto 1fr auto"
                className="admin-layout"
                overflow="auto"
            >
                <GridItem
                    as="header"
                    position="sticky"
                    top={0}
                    zIndex="banner"
                    bg="white"
                    minW={0}
                >
                    <HStack p="16px 10px 16px 10px">
                        <LogoMenu home="/admin/" />
                        <Spacer />
                        <OpenInNewTabButton />
                        <Box>
                            <Menu>
                                <MenuButton
                                    as={IconButton}
                                    icon={<ExtraMenuIcon />}
                                    rounded="md"
                                    variant="ghost"
                                    fontSize="24.38px"
                                    width="50px"
                                    height="50px"
                                    _hover={{
                                        bg: '#EAEEFA',
                                    }}
                                    _active={{
                                        bg: '#EAEEFA',
                                    }}
                                />
                                <MenuList>
                                    <HamburgerMenu />
                                </MenuList>
                            </Menu>
                        </Box>
                    </HStack>
                </GridItem>
                <GridItem as="main">{children}</GridItem>
                <GridItem as="footer">
                    <Footer />
                </GridItem>
            </Grid>
        </Box>
    )
}
AdminLayout.propTypes = {
    children: PropTypes.node.isRequired,
}
