import {
    Button,
    Text,
    ButtonGroup,
    VStack,
    HStack,
    Spacer,
    Box,
    FormErrorMessage,
    FormControl,
    Badge,
    Menu,
    MenuButton,
    IconButton,
    MenuList,
    Heading,
    Flex,
    useDisclosure,
} from '@chakra-ui/react'
import PropTypes from 'prop-types'
import { useContext, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import Site from 'shared/utils/site'

import { LogoMenu } from '../../LogoMenu'
import { ToggleViewingModeStateContext } from '../MainLayout'
import { HamburgerMenu } from './HamburgerMenu'
import { HeaderSearchButton } from './HeaderSearchButton'
import { HeaderSfId } from './HeaderSfId'

import { ExtraMenuIcon, NewFacilityIcon } from '@/assets/icon'
import { FreewordInput } from '@/components/Elements'
import { setFreeword } from '@/stores'
import { backendApi } from '@/stores/backendApi'

export const HeaderButton = ({ children, onClick }) => (
    <Button
        variant="solid"
        h="56px"
        w="56px"
        textAlign="center"
        fontSize="10px"
        borderRadius="lg"
        onClick={onClick}
    >
        {children}
    </Button>
)
HeaderButton.propTypes = {
    children: PropTypes.node,
    onClick: PropTypes.func,
}

export const MenuListButton = ({ children, onClick }) => (
    <Button
        variant="solid"
        w="200px"
        borderRadius="lg"
        onClick={() => onClick()}
    >
        {children}
    </Button>
)
MenuListButton.propTypes = {
    children: PropTypes.node,
    onClick: PropTypes.func,
}

export const Header = () => {
    const { onOpen, isOpen, onClose } = useDisclosure()
    const navigate = useNavigate()
    const { isJobseekerViewing } = useContext(ToggleViewingModeStateContext)
    const NewDevlopmentPageChecker = () => {
        return location.pathname.startsWith('/new-development') ? true : false
    }
    const TopPageChecker = () => {
        return location.pathname.startsWith('/top') ? true : false
    }
    const isTopPage = TopPageChecker()
    const OpenInNewTabButton = () => {
        const isNewDevlopmetPage = NewDevlopmentPageChecker()
        const handleButtonClick = () => {
            window.open('/new-development', '_blank', 'noopener,noreferrer')
        }
        return (
            !isNewDevlopmetPage && (
                <IconButton
                    icon={<NewFacilityIcon />}
                    rounded="md"
                    variant="ghost"
                    fontSize="100px"
                    border="none"
                    minWidth="96x"
                    height="38px"
                    _hover={{
                        bg: '#EAEEFA',
                    }}
                    _active={{
                        bg: '#EAEEFA',
                    }}
                    onClick={handleButtonClick}
                />
            )
        )
    }

    // TODO: 環境が増えるときに追加を忘れそうなのでいい方法に変えたい
    //同じようなの使ってるところ：frontend\src\components\Head\Head.jsx
    const siteName = Site.isKaigo()
        ? '介護'
        : Site.isHoiku()
        ? '保育'
        : Site.isKango()
        ? '看護'
        : Site.isPos()
        ? 'POS'
        : null

    return (
        <HStack p="16px 10px 16px 10px">
            <HStack align="center" justify="center" spacing="10px">
                <LogoMenu />
                <Heading
                    size="lg"
                    color="primary.500"
                    pb="1px"
                    fontSize="20px"
                    w="150px"
                    whiteSpace="nowrap"
                >
                    | {siteName}事業所検索
                </Heading>
            </HStack>
            <Spacer />
            <HStack>
                <Box
                    as="h3"
                    fontSize="15px"
                    color="#3CB37A"
                    fontWeight="bolder"
                    whiteSpace="nowrap"
                >
                    施設検索
                </Box>
                <Box
                    w="350px"
                    //minW="250px"
                    sx={{
                        '@media print': {
                            display: 'none',
                        },
                    }}
                    pt="16px"
                >
                    <FreewordForm />
                </Box>
            </HStack>
            <Spacer />
            <HStack
                whiteSpace="nowrap"
                minW="300px"
                sx={{
                    '@media print': {
                        display: 'none',
                    },
                }}
            >
                {!isJobseekerViewing ? (
                    <>
                        {!isTopPage ? <HeaderSfId /> : <Spacer />}
                        <OpenInNewTabButton />
                    </>
                ) : (
                    <Spacer />
                )}
                {/* 候補者モードであることが候補者に分からないようにするとのこと */}
                {/* {!isJobseekerViewing ? (
                    <>
                        <HeaderSfId />
                        <OpenInNewTabButton />
                    </>
                ) : (
                    <Badge
                        fontSize="16px"
                        variant="outline"
                        boxShadow="0 0 0 1px #3CB37A"
                        color="#3CB37A"
                    >
                        候補者モード
                    </Badge>
                )} */}
                <Box>
                    <Menu isOpen={isOpen} onClose={onClose}>
                        <MenuButton
                            as={IconButton}
                            icon={<ExtraMenuIcon />}
                            rounded="md"
                            variant="ghost"
                            fontSize="24.38px"
                            width="50px"
                            height="50px"
                            _hover={{
                                bg: '#EAEEFA',
                            }}
                            _active={{
                                bg: '#EAEEFA',
                            }}
                            onClick={onOpen}
                        />
                        <MenuList>
                            <HamburgerMenu onClose={onClose} />
                        </MenuList>
                    </Menu>
                </Box>
            </HStack>
        </HStack>
    )
}

const FreewordForm = () => {
    const value = useSelector((state) => state.search.freeword)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const [writeSearchLog] = backendApi.endpoints.writeSearchLog.useMutation()
    const inputRef = useRef(null)
    return (
        <form
            onSubmit={(e) => {
                e.preventDefault()
                const trimmedValue = value.trim()
                dispatch(setFreeword(trimmedValue))
                inputRef.current.value = trimmedValue

                writeSearchLog({
                    data: {
                        q: trimmedValue,
                    },
                    msg: 'フリーワード検索が実行されました。',
                    function: 'freeword-search',
                })
                const to =
                    location.pathname.replace(/(.+)\/$/, '$1') ===
                    '/facilities/map'
                        ? '/facilities/map'
                        : '/facilities'
                navigate(to, {
                    state: {
                        mode: 'freewordOnly',
                        isNavigate: true,
                    },
                })
            }}
        >
            <FormControl
                isInvalid={
                    location.state?.error?.payload?.data?.name ===
                        'MongoServerError' &&
                    location.state?.error?.payload?.data?.searchWord === value
                }
            >
                <FreewordInput
                    placeholder="施設検索　施設名　法人名　特徴　など"
                    value={value ?? ''}
                    inputRef={inputRef ?? ''}
                    onClear={(e) => {
                        inputRef.current.value = ''
                        dispatch(setFreeword(e.target.value))
                    }}
                    onChange={(e) => {
                        dispatch(setFreeword(e.target.value))
                    }}
                    onBlur={(e) => {
                        const trimmedValue = e.target.value.trim()
                        dispatch(setFreeword(trimmedValue))
                        inputRef.current.value = trimmedValue
                    }}
                />
                <FormErrorMessage>
                    検索時にエラーが発生しました。
                </FormErrorMessage>
            </FormControl>
        </form>
    )
}
// FreewordForm.whyDidYouRender = true
