import { Box, Spacer } from '@chakra-ui/react'
import { addDays, formatISO } from 'date-fns'
import PropTypes from 'prop-types'
import { useCallback, useMemo } from 'react'
import { joi } from 'shared'

import {
    useCreateAnnouncementMutation,
    useEditAnnouncementMutation,
} from '../api'
import { NotificationForm } from './NotificationForm'

/**
 * @typedef {{
 *     _id: string
 *     period: { begin: Date; end: Date }
 *     status: number
 * }} Notification
 */
/** @typedef {{ content: string; url?: string } & Notification} Announcement */

/**
 * @type {React.FC<{
 *     onSuccess?: () => void | Promise<void>
 *     onCancel?: () => void | Promise<void>
 *     initAnnouncement: Announcement | null
 * }>}
 */
export const CreateAnnouncementContainer = ({
    onSuccess,
    onCancel,
    initAnnouncement,
}) => {
    const [trigger] = useCreateAnnouncementMutation()
    const [triggerEdit] = useEditAnnouncementMutation()

    // 編集モードの場合、初期データを設定する
    const initialFormValues = useMemo(() => {
        if (initAnnouncement) {
            return {
                begin: formatISO(initAnnouncement.period.begin).slice(0, 10),
                content: initAnnouncement.content,
                url: initAnnouncement.url,
                period:
                    // @ts-ignore
                    // すでに保存されてしまっているものの小数点以下を切り捨て
                    Math.floor(
                        (initAnnouncement.period.end -
                            // @ts-ignore
                            initAnnouncement.period.begin) /
                            (1000 * 60 * 60 * 24)
                    ), // 日数に変換
                hidden: initAnnouncement.status === 2,
            }
        }

        return null
    }, [initAnnouncement])

    const validator = useMemo(
        () =>
            joi.object({
                begin: joi.date().required().messages({
                    'date.base': '日付を入力してください。',
                    'any.required': '日付を入力してください。',
                }),
                content: joi.string().required().messages({
                    'string.empty': '内容を入力してください。',
                    'any.required': '内容を入力してください。',
                }),
                url: joi.string().empty('').uri().optional().messages({
                    'string.uri': 'URLで入力してください',
                }),
                period: joi.number().empty('').required(),
                hidden: joi.bool().required(),
            }),
        []
    )
    const onSubmit = useCallback(async (data) => {
        // eslint-disable-next-line no-console
        console.info('[submit]', { data }) // for develop
        // 時間が9時になっているので0時に変更する
        const begin = new Date(data.begin)
        begin.setHours(0)
        const end = data.period ? addDays(begin, data.period) : undefined
        const announcement = {
            period: { begin, end },
            content: data.content ?? '',
            url: data.url,
            status: data.hidden ? 2 : 1,
        }
        // eslint-disable-next-line no-console
        console.log('[send request]', { data: announcement }) // for develop
        if (initAnnouncement) {
            await triggerEdit({
                id: initAnnouncement._id,
                announcement: announcement,
            })
                .unwrap()
                .then(() => {
                    alert('編集成功しました。')
                    onSuccess()
                })
        } else {
            await trigger(announcement)
                .unwrap()
                .then(() => {
                    alert('作成成功しました。')
                    onSuccess()
                })
        }
    }, [])

    return (
        <CreateAnnouncement
            onSubmit={onSubmit}
            validator={validator}
            onCancel={onCancel}
            initFormData={initialFormValues}
        />
    )
}

CreateAnnouncementContainer.propTypes = {
    onSuccess: PropTypes.func,
    onCancel: PropTypes.func,
    initAnnouncement: PropTypes.any,
}

/**
 * @type {React.FC<{
 *     onSubmit: (data: object) => void | Promise<void>
 *     onCancel?: (data: object) => void | Promise<void>
 *     validator?: any
 *     initFormData: {
 *         begin: string
 *         content: string
 *         url?: string
 *         period: number
 *         hidden: boolean
 *     } | null
 * }>}
 */
const CreateAnnouncement = ({
    onSubmit,
    onCancel,
    validator,
    initFormData,
}) => {
    const selectOptions = useMemo(
        () =>
            [...Array(14)].map((_, i) => ({
                label: `${i + 1}日間`,
                value: (i + 1).toString(),
            })),
        []
    )

    return (
        <NotificationForm
            createOnSubmit={({ reset }) =>
                async (data) => {
                    await onSubmit(data)
                    reset()
                }}
            validator={validator}
            defaultValues={initFormData}
        >
            <NotificationForm.Control isRequired>
                <NotificationForm.Label>日付</NotificationForm.Label>
                <NotificationForm.DateInput
                    name="begin"
                    type="date"
                    width="auto"
                />
            </NotificationForm.Control>
            <NotificationForm.Control isRequired>
                <NotificationForm.Label
                    style={{
                        display: 'flex',
                        whiteSpace: 'nowrap',

                        alignItems: 'center',
                    }}
                >
                    内容
                </NotificationForm.Label>
                <NotificationForm.Input name="content" type="text" />
            </NotificationForm.Control>

            <NotificationForm.Control>
                <NotificationForm.Label>URL</NotificationForm.Label>
                <NotificationForm.Input name="url" type="text" />
            </NotificationForm.Control>
            <NotificationForm.Control isRequired>
                <NotificationForm.Label>掲載期間</NotificationForm.Label>
                <NotificationForm.Select
                    name="period"
                    options={selectOptions}
                    width="auto"
                />
            </NotificationForm.Control>
            <Box display="flex">
                <NotificationForm.CheckBox name="hidden">
                    非表示
                </NotificationForm.CheckBox>
                <Spacer />
                <NotificationForm.Button
                    type="button"
                    onClick={onCancel}
                    marginLeft="8px"
                >
                    キャンセル
                </NotificationForm.Button>
                <NotificationForm.Button type="submit" marginLeft="8px">
                    OK
                </NotificationForm.Button>
            </Box>
        </NotificationForm>
    )
}

CreateAnnouncement.propTypes = {
    onSubmit: PropTypes.func,
    validator: PropTypes.any,
    onCancel: PropTypes.func,
    initFormData: PropTypes.any,
}
