import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import Axios from 'axios'
import qs from 'shared/utils/qs'

import { getActiveJobseekerId } from './jobseeker'
import { sfApi } from './sfApi'

import { API_URL, REVISION_URL, WEBPACK_HASH } from '@/config'
import { getUser } from '@/features/auth'
import { updateRevisionDialogIsOpen, getNextRevisionCheckTime } from '@/stores'

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

const baseQuery = fetchBaseQuery({
    baseUrl: API_URL,
    prepareHeaders: (headers, { getState }) => {
        const user = getUser()
        const token = user?.id_token
        if (token) {
            headers.set('authorization', `Bearer ${token}`)
        }
        /** @type {any} */
        const state = getState()
        const jobseekerSFID = getActiveJobseekerId(state)
        if (jobseekerSFID) {
            headers.set('x-jobseeker-sfid', jobseekerSFID)
        }
        return headers
    },
    paramsSerializer: (params) => {
        return qs.stringify(params)
    },
})

const baseQueryWithRevisionCheck = async (args, api, extraOptions) => {
    const nextRevisionCheckTime = getNextRevisionCheckTime()
    if (!nextRevisionCheckTime || Date.now() > nextRevisionCheckTime) {
        const axios = Axios.create()
        try {
            const d = new Date()
            // YYYYMMDDhhmmフォーマット
            const formattedDate = `${d.getFullYear()}${(d.getMonth() + 1)
                .toString()
                .padStart(2, '0')}${d.getDate().toString().padStart(2, '0')}${d
                .getHours()
                .toString()
                .padStart(2, '0')}${d.getMinutes().toString().padStart(2, '0')}`
            const res = await axios.get(`${REVISION_URL}?t=${formattedDate}`)
            if (res.data?.hash !== WEBPACK_HASH) {
                api.dispatch(
                    updateRevisionDialogIsOpen({ revisionDialogIsOpen: true })
                )
                while (api.getState().revision.revisionDialogIsOpen) {
                    await sleep(1000)
                }
            }
        } catch (error) {
            if (
                error?.code === 'ERR_BAD_RESPONSE' &&
                error?.response?.status === 503
            ) {
                window.location.reload()
            }
            return {
                error: {
                    status: 400,
                    data: JSON.stringify(error),
                },
                meta: {
                    request: error?.request,
                    response: error?.response,
                },
            }
        }
    }

    let result = await baseQuery(args, api, extraOptions)
    if (result.error && result.error.data.message === 'jwt expired') {
        window.location.href = '/logout'
    }
    return result
}

export const backendApi = createApi({
    reducerPath: 'backendApi',
    tagTypes: [
        'Proposal',
        'Selection',
        'SearchHistory',
        'Notification',
        'Announcement',
        'Maintenance',
        'listRailwayStations',
        'listBusStations',
        'BusinessOffice',
    ],
    baseQuery: baseQueryWithRevisionCheck,
    endpoints: (builder) => ({
        getGeocode: builder.query({
            query: (address) => ({
                url: '/v1/geocode',
                params: {
                    address,
                },
            }),
        }),
        // ログ書き込み
        writeLog: builder.mutation({
            queryFn: async (
                { level, category, ...body },
                api,
                extraOptions,
                baseQuery
            ) => {
                /** @type {any} */
                const state = api.getState()
                // see: https://redux-toolkit.js.org/rtk-query/usage/usage-without-react-hooks
                const me = sfApi.endpoints.getMe.select()(state)
                /** @type {any} */
                const sfUser = me.data
                const result = await baseQuery({
                    url: `/v1/logs/${level}/${category}`,
                    method: 'POST',
                    body: {
                        ...body,
                        office: sfUser?.SalesOfficeName__c,
                    },
                })
                return result
            },
        }),
        // 検索ログ用 see: https://a-sw.backlog.com/view/TRYTDX-223
        writeSearchLog: builder.mutation({
            queryFn: async (args = {}, { dispatch }) => {
                try {
                    const params = {
                        level: 'info',
                        category: 'search-log',
                        ...args,
                    }
                    console.log('writeSearchLog', params)
                    const res = await dispatch(
                        backendApi.endpoints.writeLog.initiate(params)
                    )
                    // console.log(res)
                    return res
                } catch (error) {
                    // console.log('error', error)
                    return { error }
                }
            },
        }),
        writeProposalLog: builder.mutation({
            queryFn: async (args, api) => {
                const params = {
                    level: 'info',
                    category: 'proposal-log',
                    ...args,
                }
                const result = await api.dispatch(
                    backendApi.endpoints.writeLog.initiate(params)
                )
                return result
            },
        }),
        // NOTE: auth API に書くと参照がループしてしまうので
        getMyAdminInfo: builder.query({
            query: () => ({
                url: '/v1/admins/me',
                validateStatus: (response) => {
                    if (response.status === 404) {
                        return true
                    } else {
                        return defaultValidateStatus(response)
                    }
                },
            }),
        }),
    }),
})

export const defaultValidateStatus = (response) =>
    response.status >= 200 && response.status <= 299
