import { Box, useDisclosure } from '@chakra-ui/react'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

// eslint-disable-next-line no-restricted-imports
import { useLazyGetJobseekersQuery } from '@/features/jobseekers/api'
// eslint-disable-next-line no-restricted-imports
import { useLazyGetSfAccountDetailQuery } from '@/features/jobseekers/api'
// eslint-disable-next-line no-restricted-imports
import { ConfirmSfIdDialog } from '@/features/jobseekers/components/ConfirmSfIdDialog'
// eslint-disable-next-line no-restricted-imports
import { InputSfIdModal } from '@/features/jobseekers/components/InputSfIdModal'
import {
    confirmJobseeker,
    jobseekerSelector,
    resetJobseeker,
    updateJobseekerDetail,
    updateSfId,
} from '@/stores'

const sfIdLength = 10

export const WithJobseekerSelectorLink = (
    { href, children, onConfirm, onCancel } = {
        href: undefined,
        children: undefined,
        onConfirm: () => {},
        onCancel: () => {},
    }
) => {
    const jobseeker = useSelector(jobseekerSelector)
    const { sfId, detail, isConfirmed } = jobseeker

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [stage, setStage] = useState('input')
    const { isOpen, onOpen, onClose } = useDisclosure({
        defaultIsOpen: false,
    })

    const [isError, setIsError] = useState(false)
    const [getJobseeker, { isFetching: jobseekerIsFetching }] =
        useLazyGetJobseekersQuery()
    const [getSfJobseeker, { isFetching: sfJobseekerIsFetching }] =
        useLazyGetSfAccountDetailQuery()

    const setSfId = useCallback(
        (sfId) => {
            dispatch(updateSfId({ sfId }))
        },
        [dispatch]
    )

    const resetSfId = useCallback(() => {
        dispatch(resetJobseeker())
        setIsError(false)
    }, [dispatch])

    const updateJobseeker = useCallback(
        (jobseeker) => {
            dispatch(updateJobseekerDetail({ detail: jobseeker }))
        },
        [dispatch]
    )

    const confirm = useCallback(() => {
        dispatch(confirmJobseeker())
    }, [dispatch])

    const reset = useCallback(() => {
        setStage('input')
        resetSfId()
        onCancel()
        onClose()
        setIsError(false)
    }, [setSfId, setStage])

    const onClick = useCallback(() => {
        if (isConfirmed) {
            onConfirm()
            // 求職者を変更せずに遷移する処理
            href && navigate(href, { state: { isNavigate: true } })
            return
        }
        onOpen()
    }, [isConfirmed, navigate, onOpen])

    useEffect(() => {
        if (!isOpen || sfId.length !== sfIdLength) {
            return setIsError(sfId.length > sfIdLength)
        }

        Promise.allSettled([
            getJobseeker(sfId).unwrap(),
            getSfJobseeker(sfId).unwrap(),
        ]).then((results) => {
            if (
                results[0].status === 'rejected' &&
                (results[1].status === 'rejected' ||
                    results[1].value === undefined)
            ) {
                return setIsError(true)
            }

            const [{ value: js }, { value: sfJs }] = results
            const jobseeker = { ...js, ...sfJs, sfId }
            updateJobseeker(jobseeker)
            setIsError(false)
        })
    }, [sfId])

    return (
        <>
            <Box onClick={onClick}>{children}</Box>
            {stage === 'input' && (
                <InputSfIdModal
                    onOk={() => {
                        setStage('confirm')
                    }}
                    onCancel={reset}
                    isOpen={isOpen}
                    sfId={sfId}
                    setSfId={setSfId}
                    isLoading={jobseekerIsFetching || sfJobseekerIsFetching}
                    isError={isError}
                />
            )}
            {stage === 'confirm' && (
                <ConfirmSfIdDialog
                    jobseeker={detail}
                    onOk={() => {
                        confirm()
                        onClose()
                        setStage('input')
                        onConfirm()
                        navigate(href, { state: { isNavigate: true } })
                    }}
                    onCancel={reset}
                    isOpen={isOpen}
                />
            )}
        </>
    )
}

WithJobseekerSelectorLink.propTypes = {
    href: PropTypes.string,
    children: PropTypes.element.isRequired,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func,
}

WithJobseekerSelectorLink.defaultProps = {
    onConfirm: () => {},
    onCancel: () => {},
}
