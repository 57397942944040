import { Button, Input, FormControl, HStack, Text } from '@chakra-ui/react'
import PropTypes from 'prop-types'

export const SearchForm = ({ value, onChange, onClick }) => {
    return (
        <FormControl>
            <Text textAlign="center" mb="30px">
                駅探API を利用して、駅・バス停を検索してください
            </Text>
            <HStack>
                <Input value={value} onChange={onChange} w="50%" ml="80px" />
                <Button
                    onClick={onClick}
                    disabled={value.length === 0}
                    w="140px"
                >
                    検索
                </Button>
            </HStack>
        </FormControl>
    )
}

SearchForm.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    onClick: PropTypes.func,
}
