export const textStyles = {
    h2: {
        fontSize: 'xl',
        fontWeight: 'bold',
        color: 'primary.500',
    },
    caption: {
        fontSize: '10px',
        color: '#90a4ae',
    },
    searchCategory: {
        fontWeight: 'bold',
        color: '#000000',
        fontFamily: 'fonts',
        fontSize: '18px',
    },
}
