import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
} from '@chakra-ui/react'
import PropTypes from 'prop-types'

import { LinkStationForm } from './LinkStationForm'
import { StationName } from './StationName'
export const LinkStationModal = ({ station, isOpen, onLink, onClose }) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose} size="3xl">
            <ModalOverlay />
            <ModalContent borderRadius="30px">
                <ModalHeader>
                    <StationName station={station} />
                </ModalHeader>
                <ModalCloseButton onClick={onClose} />
                <ModalBody>
                    <LinkStationForm
                        station={station}
                        isActive={isOpen}
                        onLink={onLink}
                        onDeactive={onClose}
                    />
                </ModalBody>
                {/* <ModalFooter>Footer</ModalFooter> */}
            </ModalContent>
        </Modal>
    )
}

LinkStationModal.propTypes = {
    station: PropTypes.object.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onLink: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
}
