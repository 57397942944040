import { combineReducers, configureStore } from '@reduxjs/toolkit'
import {
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import {
    createStateSyncMiddleware,
    initMessageListener,
} from 'redux-state-sync'

import { clearSearchFormMiddleware } from './middleware/clear-search-form'
import { rtkQueryErrorLogger } from './middleware/rtk-query'
import { socketMiddleware } from './middleware/socket'
import { backendApi } from './stores/backendApi'
import { contactApi } from './stores/contactApi'
import { sfApi } from './stores/sfApi'

import {
    jobseekerReducer,
    notificationsReducer,
    proposalReducer,
    searchReducer,
    proposalSlice,
    evaluationsReducer,
    evaluationsSlice,
    revisionReducer,
} from '@/stores'

const backendReducer = backendApi.reducerPath
const sfReducer = sfApi.reducerPath
const contactReducer = contactApi.reducerPath

const persistConfig = {
    key: 'root',
    version: 1,
    storage,
    blacklist: [backendReducer, sfReducer, contactReducer],
}

/**
 * @see {@link https://github.com/aohua/redux-state-sync}
 * @see {@link https://github.com/aohua/redux-state-sync/issues/53}
 */
const syncConfig = {
    predicate: (action) => {
        // console.log({ action })
        // NOTE: `proposal`、`evaluations`の action のみ同期
        if (new RegExp(`^(${proposalSlice.name})/`).test(action?.type)) {
            return true
        }
        if (new RegExp(`^(${evaluationsSlice.name})/`).test(action?.type)) {
            return true
        }
        return false
    },
}

const rootReducer = combineReducers({
    notifications: notificationsReducer,
    proposal: proposalReducer,
    jobseeker: jobseekerReducer,
    search: searchReducer,
    evaluations: evaluationsReducer,
    revision: revisionReducer,
    [backendReducer]: backendApi.reducer,
    [sfReducer]: sfApi.reducer,
    [contactReducer]: contactApi.reducer,
})

const resettableRootReducer = (state, action) => {
    if (action.type === 'store/reset') {
        return rootReducer(undefined, action)
    }
    return rootReducer(state, action)
}

const persistedReducer = persistReducer(persistConfig, resettableRootReducer)

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [
                    FLUSH,
                    REHYDRATE,
                    PAUSE,
                    PERSIST,
                    PURGE,
                    REGISTER,
                ],
            },
        })
            .concat(
                backendApi.middleware,
                sfApi.middleware,
                contactApi.middleware,
                rtkQueryErrorLogger,
                createStateSyncMiddleware(syncConfig)
            )
            .prepend([
                clearSearchFormMiddleware.middleware,
                socketMiddleware.middleware,
            ]),
})

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
// setupListeners(store.dispatch)

initMessageListener(store)
