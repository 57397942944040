import { createSlice } from '@reduxjs/toolkit'

const initState = {
    revisionDialogIsOpen: false,
    revisionCheckError: false,
}

/**
 * 
 * @param {*} interval 秒
 */
export const setNextRevisionCheckTime = (interval) => {
    sessionStorage.setItem('next_revision_check_time', String(Date.now() + 1000 * interval))
}

export const getNextRevisionCheckTime = () => {
    const time = sessionStorage.getItem('next_revision_check_time')
    return parseInt(time)
}

export const revisionSlice = createSlice({
    name: 'revision',
    initialState: initState,
    reducers: {
        clearRevisionCheck: () => initState,
        updateRevisionDialogIsOpen: (state, action) => {
            const { revisionDialogIsOpen } = action.payload
            state.revisionDialogIsOpen = revisionDialogIsOpen
        },
        updateRevisionCheckError: (state, action) => {
            const { revisionCheckError } = action.payload
            state.revisionCheckError = revisionCheckError
        },

    },
})

export const {
    clearRevisionCheck,
    updateRevisionDialogIsOpen,
    updateRevisionCheckError,
} = revisionSlice.actions

export const revisionReducer = revisionSlice.reducer
export const revisionSelector = (state) => state.revision