import { Box, Progress, Stack } from '@chakra-ui/react'
import PropTypes from 'prop-types'

export const FetchingOverlay = ({ isFetching, children }) => {
    return (
        <Stack className="is-fetching">
            {isFetching && <Progress size="xs" isIndeterminate />}
            <Box
                opacity={isFetching ? 0.5 : undefined}
                pointerEvents={isFetching ? 'none' : undefined}
            >
                {children}
            </Box>
        </Stack>
    )
}

FetchingOverlay.propTypes = {
    isFetching: PropTypes.bool.isRequired,
    children: PropTypes.node.isRequired,
}
