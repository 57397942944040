import PropTypes from 'prop-types'
import { createContext } from 'react'
import site from 'shared/utils/site'

import {
    KaigoFacilityItems,
    HoikuFacilityItems,
    KangoFacilityItems,
} from './Items'

export const FacilityContext = createContext(undefined)

//看護とPOS・技師は同じ
const FacilityItem = site.isHoiku()
    ? HoikuFacilityItems
    : site.isKaigo()
    ? KaigoFacilityItems
    : KangoFacilityItems

export const FacilityItems = ({ data }) => {
    if (!data) {
        return null
    }

    return (
        <FacilityContext.Provider value={data}>
            <FacilityItem />
        </FacilityContext.Provider>
    )
}

FacilityItems.propTypes = {
    isUninitialized: PropTypes.bool,
    isLoading: PropTypes.bool,
    isError: PropTypes.bool,
    isFetching: PropTypes.bool,
    data: PropTypes.any,
}
