import { useDisclosure } from '@chakra-ui/react'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation ,useNavigate} from 'react-router-dom'

import { ConfirmSfIdDialog } from '../../../../features/jobseekers/components/ConfirmSfIdDialog'
import { ErrorSfIdDialog } from '../../../../features/jobseekers/components/ErrorSfIdDialog'
import { ResetSfIdModal } from '../../../../features/jobseekers/components/ResetSfIdModal'
import { useGetMergedJobseekerQuery } from '../../../../features/jobseekers/hooks/useGetMergedJobseekerQuery'
import { ConfirmedSfId } from './ConfirmedSfId'
import { InputSfid } from './InputSfid'

import { history } from '@/lib/history'
import {
    confirmJobseeker,
    jobseekerSelector,
    resetJobseeker,
    updateJobseekerDetail,
    updateSfId,
} from '@/stores'

/** 検索画面にいる場合、求職者情報の変更を反映するために再レンダリング */
const rerender = () => {
    let path = window.location.pathname
    if (path.endsWith('/')) {
        path = path.substring(0, path.length - 1)
    }
    if (['/facilities', '/facilities/map'].includes(path)) {
        history.replace(path)
    }
}

export const HeaderSfId = () => {
    const { sfId, detail, isConfirmed } = useSelector(jobseekerSelector)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { isOpen, onOpen, onClose } = useDisclosure({
        defaultIsOpen: false,
    })

    const {
        data: jobseeker,
        isFetching,
        isError,
    } = useGetMergedJobseekerQuery(sfId?.length === 10 ? sfId : skipToken)

    const setSfId = useCallback(
        (sfId) => {
            dispatch(updateSfId({ sfId }))
        },
        [dispatch, updateSfId]
    )

    const reset = useCallback(() => {
        dispatch(resetJobseeker())
        rerender()
        onClose()
    }, [dispatch, resetJobseeker, onClose])

    const resetReturen = useCallback(() => {
        reset()
        navigate('/')
    }, [dispatch, resetJobseeker, onClose, navigate])

    const saveJobseeker = useCallback(
        (jobseeker) => {
            dispatch(updateJobseekerDetail({ detail: jobseeker }))
            dispatch(confirmJobseeker())
            rerender()
        },
        [dispatch, updateJobseekerDetail, confirmJobseeker]
    )

    const location = useLocation()
    const [queryJobseekerSfId, setQueryJobseekerSfId] = useState()
    useEffect(() => {
        const queryJobseekerSfId = new URLSearchParams(location.search).get(
            'jobseeker-sfid'
        )
        if (!queryJobseekerSfId || queryJobseekerSfId === detail?.sfId) {
            return
        }
        // @ts-ignore
        setQueryJobseekerSfId(queryJobseekerSfId)
        setSfId(queryJobseekerSfId)
        onOpen()
    }, [])

    if (isOpen && isError) {
        return (
            <>
                <InputSfid next={onOpen} sfId={sfId} setSfId={setSfId} />
                <ErrorSfIdDialog onOk={reset} onCancel={reset} />
            </>
        )
    }

    if (isFetching) {
        return (
            <InputSfid next={onOpen} sfId={sfId} setSfId={setSfId} disabled />
        )
    }

    return (
        <>
            {!isConfirmed && (
                <>
                    <InputSfid next={onOpen} sfId={sfId} setSfId={setSfId} />
                    {isOpen && (
                        <ConfirmSfIdDialog
                            jobseeker={jobseeker}
                            onOk={() => {
                                saveJobseeker(jobseeker)
                                onClose()
                            }}
                            onCancel={reset}
                            isOpen={isOpen}
                        />
                    )}
                </>
            )}
            {isConfirmed && (
                <>
                    <ConfirmedSfId jobseeker={detail} onClick={onOpen} />
                    {isOpen &&
                        (queryJobseekerSfId ? (
                            <ConfirmSfIdDialog
                                jobseeker={jobseeker}
                                onOk={() => {
                                    saveJobseeker(jobseeker)
                                    onClose()
                                }}
                                onCancel={() => {
                                    setQueryJobseekerSfId(null)
                                    onClose()
                                }}
                                isOpen={isOpen}
                            />
                        ) : (
                            <ResetSfIdModal
                                onReset={resetReturen}
                                onCancel={onClose}
                                isOpen={isOpen}
                            />
                        ))}
                </>
            )}
        </>
    )
}

HeaderSfId.propTypes = {}

HeaderSfId.defaultProps = {}
