import {
    Box,
    VStack,
    UnorderedList,
    List,
    Text,
    Radio,
    RadioGroup,
    HStack,
    Button,
} from '@chakra-ui/react'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useState } from 'react'

import { StationName } from './StationName'
const EmptyStatement = () => (
    <>
        <Text marginTop="30px" textAlign="center">
            該当なし
        </Text>
        <Text textAlign="center">
            駅・バス停を入力して再検索をしてください。
        </Text>
        <Text textAlign="center">ローマ字、ひらがなでも検索可能です</Text>
    </>
)
export const StationList = ({ stations, onSelect, station }) => {
    const [selected, setSelected] = useState()
    const [value, setValue] = useState('')
    let selectedIndex = undefined
    let selectedValue = ''
    if (station.apiCode) {
        selectedValue = '1'
        selectedIndex = 0
    }

    useEffect(() => {
        setValue(selectedValue)
        setSelected(selectedIndex)
        onSelect && onSelect(selectedIndex)
    }, [])

    const onClick = useCallback(
        (index) => {
            setSelected(index)
            onSelect && onSelect(index)
        },
        [setSelected, onSelect]
    )

    const unset = () => {
        setValue('')
        setSelected(undefined)
        onSelect && onSelect(undefined)
    }

    const selectedStyle = {
        colorScheme: 'green',
    }

    return stations.length === 0 ? (
        <Box>
            <EmptyStatement />
        </Box>
    ) : (
        <VStack>
            <HStack spacing="92px">
                <Text>駅・バス停を選択してください</Text>
                <Button onClick={unset} w="140px">
                    選択解除
                </Button>
            </HStack>
            <RadioGroup onChange={setValue} value={value}>
                <UnorderedList>
                    {stations.map((station, index) => (
                        <List
                            key={station.apiCode}
                            onClick={() => onClick(index)}
                            {...(index === selected && selectedStyle)}
                        >
                            <Radio
                                colorScheme="green"
                                value={(1 + index).toString()}
                                _focus={{ boxShadow: 'none' }}
                            >
                                <StationName station={station} />
                            </Radio>
                        </List>
                    ))}
                </UnorderedList>
            </RadioGroup>
        </VStack>
    )
}

StationList.propTypes = {
    stations: PropTypes.array.isRequired,
    onSelect: PropTypes.func,
    station: PropTypes.object.isRequired,
}
