import { HStack, Stack, Text } from '@chakra-ui/react'
import PropTypes from 'prop-types'
import { useCallback } from 'react'

import { FacilityHeading } from '.'

export const TextComponent = ({ label, startStr, endStr, children }) => {
    return (
        children && (
            <Stack>
                {label && (
                    <Text
                        fontSize="md"
                        whiteSpace="nowrap"
                        fontWeight="bold"
                        color="grey"
                    >
                        {label}
                    </Text>
                )}
                <Text>
                    {startStr}
                    {children}
                    {endStr}
                </Text>
            </Stack>
        )
    )
}

TextComponent.propTypes = {
    label: PropTypes.string,
    startStr: PropTypes.string,
    endStr: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export const TextHStackComponent = ({ label, startStr, endStr, children }) => {
    return (
        children !== null &&
        children !== undefined &&
        children !== '' && (
            <HStack>
                {label && (
                    <Text
                        fontSize="md"
                        whiteSpace="nowrap"
                        fontWeight="bold"
                        color="grey"
                    >
                        {label}
                    </Text>
                )}
                <Text>
                    {startStr}
                    {children}
                    {endStr}
                </Text>
            </HStack>
        )
    )
}

TextHStackComponent.propTypes = {
    label: PropTypes.string,
    startStr: PropTypes.string,
    endStr: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export const TextHeadingComponent = ({ label, children, isObject = [] }) => {
    const removeNullAndUndefined = useCallback(
        () =>
            isObject.filter(
                (item) =>
                    item !== null && typeof item !== 'undefined' && item !== ''
            ),
        [isObject]
    )

    return (
        removeNullAndUndefined().length !== 0 && (
            <Stack direction="row" pb="20px">
                <FacilityHeading props={{ size: 'md' }}>
                    {label}
                </FacilityHeading>
                {children}
            </Stack>
        )
    )
}

TextHeadingComponent.propTypes = {
    label: PropTypes.string.isRequired,
    children: PropTypes.node,
    isObject: PropTypes.array,
}

export const NullableTextHeadingComponent = ({
    label,
    children,
    isObject = [],
}) => {
    const removeNullAndUndefined = useCallback(
        () =>
            isObject.filter(
                (item) => typeof item !== 'undefined' && item !== ''
            ),
        [isObject]
    )

    return (
        removeNullAndUndefined().length !== 0 && (
            <Stack direction="row" pb="20px">
                <FacilityHeading props={{ size: 'md' }}>
                    {label}
                </FacilityHeading>
                {children}
            </Stack>
        )
    )
}

NullableTextHeadingComponent.propTypes = {
    label: PropTypes.string.isRequired,
    children: PropTypes.node,
    isObject: PropTypes.array,
}
