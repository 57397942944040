import { Box, useDisclosure } from '@chakra-ui/react'
import PropTypes from 'prop-types'
import { useEffect, useReducer, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

// eslint-disable-next-line no-restricted-imports
import { SwitchSfIdDialog } from './SwitchSfIdDialog'

// eslint-disable-next-line no-restricted-imports
import { useLazyGetJobseekersQuery } from '@/features/jobseekers/api'
// eslint-disable-next-line no-restricted-imports
import { useLazyGetSfAccountDetailQuery } from '@/features/jobseekers/api'
// eslint-disable-next-line no-restricted-imports
import { ConfirmSfIdDialog } from '@/features/jobseekers/components/ConfirmSfIdDialog'
// eslint-disable-next-line no-restricted-imports
import { InputSfIdModal } from '@/features/jobseekers/components/InputSfIdModal'
import {
    confirmJobseeker,
    jobseekerSelector,
    updateJobseekerDetail,
    updateSfId,
} from '@/stores'

const sfIdLength = 10

const initLocalState = {
    sfId: '',
    detail: undefined,
}
/**
 * @param {any} state
 * @param {any} action
 * @returns {any}
 */
const localStateReducer = (state, action) => {
    switch (action.type) {
        case 'UPDATE_SFID':
            return { ...state, sfId: action.payload.sfId }
        case 'CLEAR_SFID':
            return { ...state, sfId: '' }
        case 'UPDATE_DETAIL':
            return { ...state, detail: action.payload.detail }
        case 'RESET':
            return initLocalState
        default:
            return state
    }
}

export const WithJobseekerSwitcherLink = (
    { href, children } = {
        href: undefined,
        children: undefined,
    }
) => {
    const jobseeker = useSelector(jobseekerSelector)

    // TODO: 他コンポーネントローカルデータもuseReducerで管理
    const [localState, localStateDispatch] = useReducer(
        localStateReducer,
        initLocalState
    )

    const navigate = useNavigate()
    const globalDispatch = useDispatch()

    const [stage, setStage] = useState('switch')
    const { isOpen, onOpen, onClose } = useDisclosure({
        defaultIsOpen: false,
    })

    const [isError, setIsError] = useState(false)
    const [getJobseeker, { isFetching: jobseekerIsFetching }] =
        useLazyGetJobseekersQuery()
    const [getSfJobseeker, { isFetching: sfJobseekerIsFetching }] =
        useLazyGetSfAccountDetailQuery()

    console.log('stage', stage)

    useEffect(() => {
        if (localState.sfId.length !== sfIdLength) {
            return setIsError(localState.sfId.length > sfIdLength)
        }

        Promise.allSettled([
            getJobseeker(localState.sfId).unwrap(),
            getSfJobseeker(localState.sfId).unwrap(),
        ]).then((results) => {
            if(results[0].status === 'rejected' && (results[1].status === 'rejected' || results[1].value === undefined)) {
                return setIsError(true)
            }

            const [{ value: js }, { value: sfJs }] = results
            const jobseeker = { ...js, ...sfJs, sfId: localState.sfId }
            localStateDispatch({
                type: 'UPDATE_DETAIL',
                payload: { detail: jobseeker },
            })
            setIsError(false)
        })
    }, [localState.sfId, getJobseeker, getSfJobseeker])
    console.log({ stage })

    return (
        <>
            <Box onClick={onOpen}>{children}</Box>
            {stage === 'switch' && (
                <SwitchSfIdDialog
                    jobseeker={jobseeker.detail}
                    isOpen={isOpen}
                    onOk={() => {
                        onClose()
                        // 求職者を変更せずに遷移する処理
                        href && navigate(href)
                    }}
                    onCancel={onClose}
                    onClose={onClose}
                    onSwitch={() => {
                        setStage('input')
                    }}
                />
            )}
            {stage === 'input' && (
                <InputSfIdModal
                    onOk={() => {
                        setIsError(false)
                        setStage('confirm')
                    }}
                    onCancel={() => {
                        localStateDispatch({
                            type: 'RESET',
                        })
                        setIsError(false)
                        setStage('switch')
                    }}
                    onClose={() => {
                        localStateDispatch({
                            type: 'RESET',
                        })
                        setIsError(false)
                        onClose()
                        setStage('switch')
                    }}
                    isOpen={isOpen}
                    sfId={localState.sfId}
                    setSfId={(sfId) => {
                        localStateDispatch({
                            type: 'UPDATE_SFID',
                            payload: { sfId },
                        })
                    }}
                    isLoading={jobseekerIsFetching || sfJobseekerIsFetching}
                    isError={isError}
                />
            )}
            {stage === 'confirm' && (
                <ConfirmSfIdDialog
                    jobseeker={localState.detail}
                    onOk={() => {
                        onClose()
                        globalDispatch(updateSfId({ sfId: localState.sfId }))
                        globalDispatch(
                            updateJobseekerDetail({ detail: localState.detail })
                        )
                        globalDispatch(confirmJobseeker())
                        localStateDispatch({
                            type: 'RESET',
                        })
                        setStage('switch')
                        href && navigate(href)
                    }}
                    onCancel={() => {
                        setStage('input')
                    }}
                    onClose={() => {
                        localStateDispatch({
                            type: 'RESET',
                        })
                        onClose()
                        setStage('switch')
                    }}
                    isOpen={isOpen}
                />
            )}
        </>
    )
}

WithJobseekerSwitcherLink.propTypes = {
    href: PropTypes.string,
    children: PropTypes.element.isRequired,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func,
}

WithJobseekerSwitcherLink.defaultProps = {
    onConfirm: () => {},
    onCancel: () => {},
}
