import { HStack, Button } from '@chakra-ui/react'
import PropTypes from 'prop-types'
import { useCallback } from 'react'

export const PaginationItem = ({ children, ...otherProps }) => {
    return (
        <Button {...otherProps} size="sm">
            {children}
        </Button>
    )
}
PaginationItem.propTypes = {
    children: PropTypes.node.isRequired,
}

const PaginationEllipsis = () => <PaginationItem isDisabled>...</PaginationItem>

const PaginationLink = ({ index, handlePageChanged, ...otherProps }) => {
    const onClick = useCallback(
        (event) => {
            event.preventDefault()
            handlePageChanged(index)
        },
        [index]
    )
    return (
        <PaginationItem onClick={onClick} {...otherProps}>
            {index}
        </PaginationItem>
    )
}
PaginationLink.propTypes = {
    index: PropTypes.number.isRequired,
    handlePageChanged: PropTypes.func.isRequired,
}

export const Pagination = ({ totalCount, currentPage, handlePageChanged }) => {
    const items = []
    const renderLink = useCallback(
        (index) => {
            return (
                <PaginationItem
                    key={index}
                    isActive={currentPage === index}
                    onClick={() => handlePageChanged(index)}
                >
                    {index}
                </PaginationItem>
            )
        },
        [currentPage]
    )

    items.push(
        <PaginationItem
            key="previous"
            aria-label="Previous"
            isDisabled={currentPage === 1}
            onClick={() => handlePageChanged(currentPage - 1)}
        >
            <span aria-hidden="true">前へ</span>
        </PaginationItem>
    )

    if (totalCount <= 7) {
        items.push(
            ...Array.from(Array(totalCount), (_, k) => {
                const index = k + 1
                return renderLink(index)
            })
        )
    } else if (currentPage < 5) {
        items.push(
            ...Array.from(Array(5), (_, k) => {
                const index = k + 1
                return renderLink(index)
            }),
            <PaginationEllipsis key="ellipsis-end" />,
            renderLink(totalCount)
        )
    } else if (totalCount - currentPage < 4) {
        items.push(
            renderLink(1),
            <PaginationEllipsis key="ellipsis-start" />,
            ...Array.from(Array(5), (_, k) => {
                const index = totalCount - 4 + k
                return renderLink(index)
            })
        )
    } else {
        items.push(
            renderLink(1),
            <PaginationEllipsis key="ellipsis-start" />,
            ...Array.from(Array(3), (_, k) => {
                const index = currentPage - 1 + k
                return renderLink(index)
            }),
            <PaginationEllipsis key="ellipsis-end" />,
            renderLink(totalCount)
        )
    }

    // 次へ
    items.push(
        <PaginationItem
            key="next"
            aria-label="Next"
            isDisabled={currentPage === totalCount || totalCount === 0}
            onClick={() => handlePageChanged(currentPage + 1)}
        >
            <span aria-hidden="true">次へ</span>
        </PaginationItem>
    )

    return (
        <HStack w="full" justifyContent="center" align="center">
            {items}
        </HStack>
    )
}

Pagination.propTypes = {
    totalCount: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
    handlePageChanged: PropTypes.func.isRequired,
}
