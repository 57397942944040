import {
    Box,
    Text,
    HStack,
    Spacer,
    Checkbox,
    Wrap,
    WrapItem,
    Tag,
    PopoverTrigger,
    PopoverContent,
    PopoverHeader,
    PopoverArrow,
    PopoverBody,
    Skeleton,
    usePopoverContext,
    Portal,
} from '@chakra-ui/react'
import parse from 'html-react-parser'
import PropTypes from 'prop-types'
import { useDeferredValue, useEffect, useRef, useState } from 'react'

import { Section } from '../../dashboard/components/elements/Section'
import {
    SectionTitle,
    NoDataText,
    DatetimeText,
} from '../../dashboard/components/elements/Text'

import {
    Table,
    TableContainer,
    Pagination,
    FetchingOverlay,
} from '@/components/Elements'
import { getUserId } from '@/features/auth'
import { NewContactButton } from '@/features/contact'
import { contactApi } from '@/stores'

const MainPost = ({ tid }) => {
    const { forceUpdate } = usePopoverContext()
    const { data } = contactApi.endpoints.getTopic.useQuery(
        { tid }
        // { skip: true }
    )
    useEffect(() => {
        if (data) {
            // 表示位置の再計算
            forceUpdate()
        }
    }, [data])
    return (
        <>
            {data && (
                <PopoverHeader>
                    <Wrap>
                        {data.tags.map((tag) =>
                            /^[A-Za-z]+$/.test(tag.value) ? (
                                ''
                            ) : (
                                <WrapItem key={tag.value}>
                                    <Tag className={`tag-${tag.class}`}>
                                        {tag.value}
                                    </Tag>
                                </WrapItem>
                            )
                        )}
                    </Wrap>
                </PopoverHeader>
            )}
            <PopoverArrow />
            <PopoverBody overflowY="auto">
                <Text>【問い合わせ内容】</Text>
                <Skeleton isLoaded={data} minH="1rem" maxH="50vh">
                    {data && parse(data.titleRaw)}
                </Skeleton>
                <Text>【詳細】</Text>
                <Skeleton isLoaded={data} minH="1rem" maxH="50vh">
                    {data && parse(data.mainPost.content)}
                </Skeleton>
            </PopoverBody>
        </>
    )
}
MainPost.propTypes = {
    tid: PropTypes.number.isRequired,
}

const Title = (props) => {
    const { row, cell } = props
    return (
        <>
            <Box display="inline-flex" flexDirection="column">
                <Text noOfLines={1} wordBreak="break-all">
                    {cell.value}
                </Text>
                <HStack textStyle="caption">
                    <Box>
                        送信日時&nbsp;:&nbsp;
                        <DatetimeText>{row.original.timestamp}</DatetimeText>
                    </Box>
                    <PopoverTrigger>
                        <Box>{row.original.user.username}</Box>
                    </PopoverTrigger>
                </HStack>
            </Box>
            <PopoverContent minW="40vw">
                <MainPost tid={row.original.tid} />
            </PopoverContent>
        </>
    )
}
Title.propTypes = {
    cell: PropTypes.shape({ value: PropTypes.string.isRequired }).isRequired,
    row: PropTypes.shape({ original: PropTypes.object.isRequired }).isRequired,
}

const Status = ({ cell }) => {
    return (
        <Wrap>
            {cell.value.map((tag) =>
                /^[A-Za-z]+$/.test(tag.value) ? (
                    ''
                ) : (
                    <WrapItem key={tag.value}>
                        <Tag className={`tag-${tag.class}`}>{tag.value}</Tag>
                    </WrapItem>
                )
            )}
        </Wrap>
    )
}
Status.propTypes = {
    cell: PropTypes.shape({ value: PropTypes.array.isRequired }).isRequired,
}

const columns = [
    {
        Header: '件名',
        accessor: 'titleRaw',
        Cell: Title,
    },
    {
        Header: 'ステータス',
        accessor: 'tags',
        headerClassName: 'narrow',
        Cell: Status,
    },
]

/** @typedef {typeof contactApi.endpoints} Endpoints */

/**
 * @param {object} param
 * @param {Endpoints[keyof Endpoints]} param.source
 * @param {any} param.containerRef
 * @param {any} param.pagenationRef
 * @returns
 */
const ContactTable = ({ source, containerRef, pagenationRef }) => {
    const [username] = useState(() => getUserId())
    const [currentPage, setCurrentPage] = useState(1)
    const page = useDeferredValue(currentPage)
    const { isLoading, isFetching, data } = source.useQuery({
        page,
        username,
    })

    if (isLoading) {
        return <Text>Loading...</Text>
    } else if (data) {
        if (data?.topics?.length) {
            return (
                <FetchingOverlay isFetching={isFetching}>
                    <TableContainer layerStyle="dataTable">
                        <Table
                            data={data.topics}
                            columns={columns}
                            isPopoverEnabled
                        />
                    </TableContainer>
                    {/* NOTE: CSSで「はみ出し」もできる */}
                    <Portal containerRef={pagenationRef}>
                        <Pagination
                            totalCount={data.pagination.pageCount}
                            currentPage={currentPage}
                            handlePageChanged={(index) => {
                                setCurrentPage(index)
                                containerRef?.current?.scrollToStart()
                            }}
                        />
                    </Portal>
                </FetchingOverlay>
            )
        } else {
            return <NoDataText>お問い合わせはありません</NoDataText>
        }
    }
    return null
}
ContactTable.propTypes = {
    source: PropTypes.any.isRequired,
    containerRef: PropTypes.shape({ current: PropTypes.any }),
    pagenationRef: PropTypes.shape({ current: PropTypes.any }),
}

const AllRecords = (props) => (
    <ContactTable {...props} source={contactApi.endpoints.listContactTopics} />
)

const MyRecords = (props) => (
    <ContactTable {...props} source={contactApi.endpoints.listMyTopics} />
)

export const ContactSection = () => {
    const [isChecked, setIsChecked] = useState(false)
    const ref = useRef(null)
    const pagenationRef = useRef()
    return (
        <>
            <Section ref={ref}>
                <HStack className="title-container" pl="4px" pr="4px">
                    <SectionTitle>お問い合わせ</SectionTitle>
                    <Spacer />
                    <Checkbox
                        isChecked={isChecked}
                        onChange={(e) => {
                            setIsChecked(e.target.checked)
                            ref?.current?.scrollToStart()
                        }}
                    >
                        全体表示にする
                    </Checkbox>
                    <NewContactButton backURL={location.href} close />
                </HStack>
                {isChecked ? (
                    <AllRecords
                        containerRef={ref}
                        pagenationRef={pagenationRef}
                    />
                ) : (
                    <MyRecords
                        containerRef={ref}
                        pagenationRef={pagenationRef}
                    />
                )}
            </Section>
            <Box ref={pagenationRef} mt="16px" />
        </>
    )
}
