import { lazy, Suspense } from 'react'
import { useDispatch } from 'react-redux'
import { useRoutes, Navigate, Outlet } from 'react-router-dom'

import {
    MainLayout,
    ErrorLayout,
    AdminLayout as AdminMainLayout,
} from '@/components/Layout'
// eslint-disable-next-line no-restricted-imports
import { AdminDashboard } from '@/features/admin/routes/AdminDashboard'
// eslint-disable-next-line no-restricted-imports
import { SearchTimes } from '@/features/admin/routes/searchTimes'
import { AuthGuard, AdminGuard, AuthCallback, Logout } from '@/features/auth'
// eslint-disable-next-line no-restricted-imports
import { Contacts } from '@/features/contact/routes/Contacts'
import { clearRevisionCheck } from '@/stores'

const Evaluations = lazy(() =>
    import('@/features/high-performers/routes.feedback')
)
const Dashboard = lazy(() => import('@/features/dashboard/routes.dashboard'))
const Dashboard2 = lazy(() => import('@/features/dashboard/route.dashboard.v2'))
const NewDevelopment = lazy(() =>
    import('@/features/new-development/routes.newdevelopment')
)
const FacilitiesRoutes = lazy(() =>
    import('@/features/facilities/routes.facilities')
)
const ProposalsRoutes = lazy(() =>
    import('@/features/proposals/routes.proposals')
)
const OffersRoutes = lazy(() => import('@/features/offers/routes.offers'))
const SearchContainer = lazy(() =>
    import('@/features/search/route.search.container')
)
const SearchIndex = lazy(() => import('@/features/search/route.search.index'))
// const SearchMaps = lazy(() => import('@/features/search/route.search.maps'))
const SearchDemo = lazy(() => import('@/features/search/route.search.demo'))
const Loading = () => <p>Loading...</p>

const App = () => {
    const dispatch = useDispatch()
    // FIXME: クリアのタイミングを見直す
    dispatch(clearRevisionCheck())
    return (
        <AuthGuard>
            <Outlet />
        </AuthGuard>
    )
}

const UserLayout = () => {
    return (
        <MainLayout>
            <Suspense fallback={<Loading />}>
                <Outlet />
            </Suspense>
        </MainLayout>
    )
}

const AdminApp = () => {
    return (
        <AdminGuard>
            <Outlet />
        </AdminGuard>
    )
}

const AdminLayout = () => {
    return (
        <AdminMainLayout>
            <Suspense fallback={<Loading />}>
                <Outlet />
            </Suspense>
        </AdminMainLayout>
    )
}

export const AppRoutes = () => {
    const routes = [
        // see: https://reacttraining.com/blog/react-router-v6-pre/#object-based-routes
        {
            path: '/',
            element: <App />,
            children: [
                {
                    path: '/contacts',
                    element: <Contacts />,
                },
                {
                    path: '/evaluations',
                    element: <Evaluations />,
                },
                {
                    path: '/admin',
                    element: <AdminApp />,
                    children: [
                        {
                            path: 'search/view',
                            element: <SearchDemo />,
                        },
                        {
                            path: '/admin',
                            element: <AdminLayout />,
                            children: [
                                {
                                    path: 'dashboard',
                                    element: <AdminDashboard />,
                                },
                                {
                                    path: 'searchTimes',
                                    element: <SearchTimes />,
                                },

                                {
                                    path: '',
                                    element: <Navigate to="dashboard" />,
                                },
                            ],
                        },
                    ],
                },
                {
                    path: '/',
                    element: <UserLayout />,
                    children: [
                        {
                            path: '/facilities/*',
                            element: <FacilitiesRoutes />,
                        },
                        {
                            path: '/new-development',
                            element: <NewDevelopment />,
                        },
                        { path: '/proposals/*', element: <ProposalsRoutes /> },
                        { path: '/top', element: <Dashboard2 /> },
                        { path: '/dashboard', element: <Dashboard /> },
                        { path: '/offers/*', element: <OffersRoutes /> },
                        { path: '/searchTimes', element: <SearchTimes /> },
                        { path: '/search/demo', element: <SearchDemo /> },
                        {
                            path: '/search',
                            element: <SearchContainer />,
                            children: [
                                {
                                    index: true,
                                    element: <SearchIndex />,
                                },
                                // {
                                //     path: 'maps',
                                //     element: <SearchMaps />,
                                // },
                            ],
                        },
                        {
                            path: '/',
                            element: <Navigate to="/top" />,
                        },
                    ],
                },
            ],
        },

        { path: '/error', element: <ErrorLayout /> },
        { path: '/oidccallback', element: <AuthCallback /> },
        { path: '/login', element: <Navigate to="/" /> },
        { path: '/logout', element: <Logout /> },
        {
            path: '/*',
            element: (
                <ErrorLayout
                    code={404}
                    message="ページが見つかりませんでした"
                />
            ),
        },
    ]

    const element = useRoutes(routes)

    return <>{element}</>
}
