import { Box, forwardRef } from '@chakra-ui/react'
import { useImperativeHandle, useRef } from 'react'

export const Section = forwardRef((props, ref) => {
    const boxRef = useRef(null)
    useImperativeHandle(ref, () => ({
        scrollToStart() {
            boxRef?.current?.scrollIntoView({})
        },
    }))
    return (
        <Box
            ref={boxRef}
            as="section"
            scrollMarginTop="var(--header-height)"
            bg="primary.50"
            px="25px"
            py="35px"
            sx={{
                '.title-container': {
                    mb: '8px',
                    'label.chakra-checkbox': {
                        borderColor: '#90A4AE',
                    },
                    button: {
                        fontSize: 'md',
                    },
                },
                '.chakra-table__container': {
                    mb: '20px',
                },
                '.pagination-container': {
                    bg: 'white',
                    m: '35px -25px -35px',
                    pt: '20px',
                },
            }}
            {...props}
        />
    )
})
