import {
    Box,
    Checkbox,
    CheckboxGroup,
    Flex,
    HStack,
    Heading,
    MenuItem,
    Radio,
    RadioGroup,
    Skeleton,
    Spacer,
    Stack,
    Text,
} from '@chakra-ui/react'
import { format, parseISO, subDays, isAfter, startOfDay } from 'date-fns'
import PropTypes from 'prop-types'
import { useContext, useMemo, useState } from 'react'
import {
    jobOfferTypeChoices,
    jobOfferTypeDefaultChoices,
    occupationQualificationChoicesTable,
    occupationSortOrders,
    updatedDateChoices,
} from 'shared'

import { FilterComponent } from '../AcutualSalariesComponents/FilterComponent'
import { JobOfferUrl } from './JobOfferUrl'
import { TextWorkingHours } from './TextWorkingHours'

import { RecommendedPoint, JobSelectButton, TextComponent } from './'

import { ToggleViewingModeStateContext } from '@/components/Layout'
import { SITE } from '@/config'
import { getPaymentKeysAndValues } from '@/features/proposals/payment'

const getDefaultChoices = (() => {
    const choices = jobOfferTypeDefaultChoices[SITE] ?? []
    return () => choices
})()

export const JobOffer = ({
    facData,
    isLoading = false,
    isError = false,
    data,
}) => {
    const { isJobseekerViewing } = useContext(ToggleViewingModeStateContext)

    const offerArray =
        data?.slice().filter((item) => item.status === '募集中') ?? []
    const usualOfferArray =
        data?.slice().filter((item) => item.status === '常時募集中') ?? []
    const otherArray =
        data
            ?.slice()
            .filter(
                (item) =>
                    item.status !== '募集中' && item.status !== '常時募集中'
            ) ?? []
    otherArray?.sort(
        (a, b) =>
            new Date(b.GCPUpdatedAt).getTime() -
            new Date(a.GCPUpdatedAt).getTime()
    )
    const resultArray = [...offerArray, ...usualOfferArray, ...otherArray]

    const [compareDate, setCompareDate] = useState(undefined)
    const [occupationArray, setOccupationArray] = useState(getDefaultChoices())

    const getQualificationChoices = () => {
        // チェックボックスで選択された職種に対応する資格をセットする
        return [...occupationArray]
            .sort(
                (a, b) =>
                    (occupationSortOrders[a] ?? occupationSortOrders.default) -
                    (occupationSortOrders[b] ?? occupationSortOrders.default)
            )
            .flatMap(
                (occupation) =>
                    occupationQualificationChoicesTable[occupation] ?? []
            )
    }

    const filterOffers = (offerArray, qualificationArray) =>
        offerArray.filter((item) => {
            // 職種の選択値がある場合のみ絞り込む
            if (
                occupationArray.length !== 0 &&
                !item?.qualification
                    ?.split(';')
                    .some((qualification) =>
                        qualificationArray.includes(qualification)
                    )
            ) {
                return false
            }

            // 更新日が全て以外の時のみ絞り込む
            if (
                compareDate !== null &&
                compareDate !== undefined &&
                !isAfter(new Date(item.GCPUpdatedAt), compareDate)
            ) {
                return false
            }

            return true
        })

    const filteredOffers = useMemo(() => {
        if (resultArray.length === 0) {
            return []
        }
        const qualificationArray = getQualificationChoices()
        const offers = [...resultArray]
        return filterOffers(offers, qualificationArray)
    }, [data, occupationArray, compareDate])

    const occupationChange = (e) => {
        if (e.target.checked) {
            setOccupationArray([...occupationArray, e.target.value])
        } else {
            setOccupationArray(
                occupationArray.filter((v) => v !== e.target.value)
            )
        }
    }

    const changeDate = (e) => {
        const today = new Date()
        if (e.target.value === 'all') {
            setCompareDate(undefined)
            return
        }
        const date = parseInt(e.target.value)
        // 今日の日付から更新日の入力値を差し引く
        // 絞り込み実行時間によって結果が変わらないように時間は0:00:00でセットする
        setCompareDate(startOfDay(subDays(today, date)))
    }

    // 給与の表示
    const paymentView = (payment) => {
        const from = payment?.from
        const to = payment?.to
        if (to === 0) {
            return `${from.toLocaleString()}〜`
        }
        if (from > to) {
            return `${to.toLocaleString()}〜${from.toLocaleString()}`
        }
        return `${from.toLocaleString()}〜${to.toLocaleString()}`
    }

    return (
        <>
            <Skeleton isLoaded={!isLoading} minH={6}>
                {isError ? (
                    <Text>エラーが発生しました</Text>
                ) : data?.length ? (
                    <Box>
                        <HStack pt={2}>
                            <FilterComponent label="職種">
                                <CheckboxGroup value={occupationArray}>
                                    {jobOfferTypeChoices.map(
                                        ({ label, value }) => (
                                            <MenuItem key={label?.toString()}>
                                                <Checkbox
                                                    onChange={occupationChange}
                                                    value={value}
                                                >
                                                    {label}
                                                </Checkbox>
                                            </MenuItem>
                                        )
                                    )}
                                </CheckboxGroup>
                            </FilterComponent>
                            <FilterComponent label="更新日">
                                <RadioGroup defaultValue="all">
                                    {updatedDateChoices.map(
                                        ({ label, value }) => (
                                            <MenuItem key={label?.toString()}>
                                                <Radio
                                                    onChange={changeDate}
                                                    value={value}
                                                >
                                                    {label}
                                                </Radio>
                                            </MenuItem>
                                        )
                                    )}
                                </RadioGroup>
                            </FilterComponent>
                        </HStack>
                        {!filteredOffers.length ? (
                            <Text>絞り込み結果0件</Text>
                        ) : (
                            filteredOffers.map((offer, index) => {
                                const [, paymentValues, unit, price] =
                                    getPaymentKeysAndValues(offer)
                                return (
                                    <Stack key={offer._id} py="10px">
                                        <Flex>
                                            {offer.title && (
                                                <Heading size="1rem">
                                                    {!isJobseekerViewing
                                                        ? offer.title
                                                        : `募集案件${
                                                              index + 1
                                                          }`}
                                                </Heading>
                                            )}
                                            <Spacer />
                                            <JobSelectButton
                                                facility={facData}
                                                offer={offer}
                                            />
                                        </Flex>
                                        <TextComponent label="保有資格">
                                            {offer.qualification}
                                        </TextComponent>
                                        {paymentValues && (
                                            <TextComponent label="給与">
                                                {`${unit}${paymentView(
                                                    paymentValues
                                                )}${price}`}
                                            </TextComponent>
                                        )}

                                        <TextComponent label="勤務形態">
                                            {offer.workShift}
                                        </TextComponent>
                                        {offer?.workingHours.length && (
                                            <TextWorkingHours label="勤務時間">
                                                <HStack>
                                                    {offer?.workingHours.map(
                                                        (hours) => (
                                                            <Text
                                                                key={hours._id}
                                                            >
                                                                {`${hours.from} ~ ${hours.to}`}
                                                            </Text>
                                                        )
                                                    )}
                                                </HStack>
                                            </TextWorkingHours>
                                        )}
                                        <TextComponent label="雇用形態">
                                            {offer.employmentType}
                                        </TextComponent>
                                        <TextComponent label="募集ポジション">
                                            {offer.layer}
                                        </TextComponent>
                                        <TextComponent label="募集状況">
                                            {offer.status}
                                        </TextComponent>
                                        <TextComponent label="更新日">
                                            {format(
                                                parseISO(offer.GCPUpdatedAt),
                                                'yyyy年MM月dd日'
                                            )}
                                        </TextComponent>
                                        {!isJobseekerViewing && (
                                            <JobOfferUrl label="案件SFURL">
                                                {offer.jomUrl}
                                            </JobOfferUrl>
                                        )}
                                        <RecommendedPoint>
                                            {offer.salesTalk}
                                        </RecommendedPoint>
                                    </Stack>
                                )
                            })
                        )}
                    </Box>
                ) : (
                    <Box>
                        <Text>0件</Text>
                    </Box>
                )}
            </Skeleton>
        </>
    )
}
JobOffer.propTypes = {
    facData: PropTypes.any,
    isLoading: PropTypes.bool,
    isError: PropTypes.bool,
    data: PropTypes.any,
}
