import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useAuth, hasAuthParams } from 'react-oidc-context'
import { Navigate, useLocation } from 'react-router-dom'

import { setRedirectPath } from '../api'

import { backendApi } from '@/stores/backendApi'
import { sfApi } from '@/stores/sfApi'

const AuthGuard = ({ children }) => {
    const location = useLocation()
    const auth = useAuth()
    const getUser = sfApi.useGetMeQuery(undefined, {
        skip: !auth.isAuthenticated,
    })
    const getAdmin = backendApi.useGetMyAdminInfoQuery(undefined, {
        skip: !auth.isAuthenticated,
    })

    console.log({ auth, admin: getAdmin, user: getUser })

    useEffect(() => {
        auth.events.addUserLoaded((/* user */) => {
            getAdmin.refetch()
            getUser.refetch()
        })
    }, [auth.events, getAdmin.refetch, getUser.refetch])

    // automatically sign-in
    useEffect(() => {
        if (
            !hasAuthParams() &&
            !auth.isAuthenticated &&
            !auth.activeNavigator &&
            !auth.isLoading
        ) {
            setRedirectPath(location.pathname + location.search)
            auth.signinRedirect()
        }
    }, [
        auth.isAuthenticated,
        auth.activeNavigator,
        auth.isLoading,
        auth.signinRedirect,
    ])

    if (getUser.data && getAdmin.isSuccess) {
        return <>{children}</>
    }

    if (auth.error) {
        return (
            <Navigate
                to="/error"
                state={{
                    code: 401,
                    message: 'Unable to log in',
                    error: auth.error,
                }}
            />
        )
    }

    return <p>Loading...</p>
}

AuthGuard.propTypes = {
    children: PropTypes.node.isRequired,
}

export { AuthGuard }
