import { Center, Divider } from '@chakra-ui/react'
import PropTypes from 'prop-types'

export const DividingLine = ({ height = '80px', ...OtherProps }) => {
    return (
        <Center h={height}>
            <Divider
                borderWidth="2px"
                borderRadius={5}
                borderColor="green.300"
                variant="dashed"
                {...OtherProps}
            />
        </Center>
    )
}

DividingLine.propTypes = {
    height: PropTypes.string,
}
