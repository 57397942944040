import { AppProvider } from './providers/app'
import { AppRoutes } from './routes'

function App() {
    return (
        <AppProvider>
            <AppRoutes />
        </AppProvider>
    )
}

// eslint-disable-next-line import/no-default-export
export default App
