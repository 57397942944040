export const travelModeOptions = [
    {
        label: '車（時速30km）',
        value: '3',
        speed: 30,
        type: 'driving',
    },
    {
        label: '自転車（時速15km）',
        value: '2',
        speed: 15,
        type: 'bicycling',
    },
    {
        label: '徒歩（時速4km）',
        value: '1',
        speed: 4,
        type: 'walking',
    },
    {
        label: '公共交通機関',
        value: '4',
        // speed: 4,
        type: 'transit',
    },
]

export const validTravelModes = travelModeOptions.map(({ value }) => value)

export const pickTravelMode = (value) => {
    return travelModeOptions.find((option) => option.value === value)
}

export const calcMinutes = (distance, speed) => {
    return (distance / speed / 1000) * 60
}

export const calcDistance = (speed, minutes) => {
    return (speed * 1000 * minutes) / 60
}
