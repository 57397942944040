import {
    Text,
    Button,
    VStack,
    AlertDialog,
    AlertDialogBody,
    AlertDialogHeader,
    AlertDialogOverlay,
    AlertDialogContent,
    AlertDialogCloseButton,
    AlertDialogFooter,
    Input,
} from '@chakra-ui/react'
import PropTypes from 'prop-types'
import { useCallback, useEffect, useRef, useState } from 'react'

const sfIdLength = 10 // SF[0-9]{8}

export const InputSfIdModal = ({
    sfId,
    setSfId,
    isLoading,
    isError,
    leastDestructiveRef,
    onOk,
    onCancel,
    onClose,
    isOpen,
}) => {
    const placeholder = 'SF12345678'
    const errorColor = 'red.300'

    const onChange = useCallback(
        (e) => {
            setSfId(
                e.target.value
                    .trim()
                    .replace(
                        /[Ａ-Ｚａ-ｚ０-９]/g,
                        (c) => String.fromCharCode(c.charCodeAt(0) - 0xfee0) // 全角半角変換
                    )
                    .toUpperCase()
            )
        },
        [setSfId]
    )
    const buttonRef = useRef(null)
    const inputRef = useRef(null)
    const [loaded, setLoaded] = useState(false)
    useEffect(() => {
        if (isLoading) {
            return
        }
        setLoaded(true)
    }, [isLoading])

    if (loaded) {
        if (buttonRef.current !== null) {
            buttonRef.current.focus()
        }
    }

    return (
        <AlertDialog
            size="4xl"
            isOpen={isOpen}
            onClose={onClose ?? onCancel}
            leastDestructiveRef={leastDestructiveRef}
        >
            <AlertDialogOverlay />
            <AlertDialogContent>
                <AlertDialogHeader fontFamily="fonts">
                    提案書を作成する求職者のSFIDを入力してください
                    <AlertDialogCloseButton onClick={onClose ?? onCancel} />
                </AlertDialogHeader>
                <AlertDialogBody>
                    <VStack>
                        {isError || sfId.length > 10 ? (
                            <>
                                <Text color={errorColor}>
                                    求職者が存在しないSFIDです
                                </Text>
                                <Input
                                    placeholder={placeholder}
                                    value={sfId}
                                    backgroundColor={errorColor}
                                    onChange={onChange}
                                />
                            </>
                        ) : (
                            <Input
                                placeholder={placeholder}
                                value={sfId}
                                onChange={onChange}
                                autoFocus
                                ref={inputRef}
                                textTransform="uppercase"
                            />
                        )}
                    </VStack>
                </AlertDialogBody>
                <AlertDialogFooter>
                    <Button onClick={onCancel} width="100px">
                        戻る
                    </Button>
                    <Button
                        onClick={onOk}
                        disabled={
                            isError || sfId.length !== sfIdLength || isLoading
                        }
                        width="100px"
                        marginLeft="8px"
                        backgroundColor="#3cb37a"
                        color="white"
                        _hover={{
                            opacity: '0.6',
                            filter: 'brightness(110%)',
                        }}
                        ref={buttonRef}
                    >
                        決定
                    </Button>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    )
}

InputSfIdModal.propTypes = {
    sfId: PropTypes.string.isRequired,
    setSfId: PropTypes.func.isRequired,
    isError: PropTypes.bool,
    isLoading: PropTypes.bool,
    leastDestructiveRef: PropTypes.shape({
        current: PropTypes.any,
    }),
    onOk: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onClose: PropTypes.func,
    isOpen: PropTypes.bool,
}
