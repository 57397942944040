import { ErrorLayout } from "@/components/Layout"
import { useLocation } from "react-router-dom"

export const ErrorDetection = ({children})=> {
    const location = useLocation()
    if (location.state?.error?.payload?.status === 401) {
        return <ErrorLayout code={401} message="Unauthorized" />
    }else if(location.state?.error?.payload?.status === 400){
        return <ErrorLayout code={400} message="Bad Request" />
    }
    return (<>{children}</>)
}