import { Stack } from '@chakra-ui/react'
import { useContext } from 'react'

import { FacilityContext } from '..'

import {
    KaigoEmployeesItem,
    KaigoHoursItem,
    KaigoOfficeItem,
    KaigoUsersItem,
    FacilityCommonItems,
    FacilityModifiedDateItem,
    FacilityRecommendedPointItems,
} from '.'

import { Table, TableContainer } from '@/components/Elements'
import { ToggleViewingModeStateContext } from '@/components/Layout'

const columns = [
    {
        accessor: 'type',
        Header: '資格',
    },
    {
        accessor: 'staffCount',
        Header: '従業員人数',
    },
    {
        accessor: 'totalCount',
        Header: '合計人数',
    },
    {
        accessor: 'fte',
        Header: '常勤換算値',
    },
]

export const KaigoFacilityItems = () => {
    const data = useContext(FacilityContext)
    const { isJobseekerViewing } = useContext(ToggleViewingModeStateContext)

    return (
        <Stack>
            {/* Corporation */}
            <FacilityCommonItems />
            <KaigoOfficeItem />
            <KaigoHoursItem />
            <KaigoEmployeesItem />
            <KaigoUsersItem />
            <FacilityModifiedDateItem />
            {data.workerStatistics.length && (
                <TableContainer
                    sx={{
                        Thead: {
                            bg: '#3CB37A',
                        },
                        Th: {
                            color: '#FFFFFF',
                            fontSize: '14px',
                            borderColor: 'primary.50',
                            borderRightWidth: '4px',
                            pt: '4px',
                            pb: '4px',
                        },
                        Tbody: {
                            bg: 'white',
                        },
                        Td: {
                            borderColor: 'primary.50',
                            borderRightWidth: '2px',
                            borderTopWidth: '2px',
                            borderBottomWidth: '2px',
                            pt: '2px',
                            pb: '2px',
                            textAlign: 'center',
                        },
                    }}
                >
                    <Table columns={columns} data={data.workerStatistics} />
                </TableContainer>
            )}
            {!isJobseekerViewing && <FacilityRecommendedPointItems />}

            {/* <TextComponent label="統一サービスタイプ名称">
                        {data.commonServiceType}
                    </TextComponent> */}
        </Stack>
    )
}
