import { axios } from '@/lib/axios'
import { backendApi } from '@/stores/backendApi'
/**
 * @typedef {import('@/types/api').operations} ApiOperations
 *
 * @typedef {ApiOperations['get-v1-bus-stations-nearby']['responses']['200']['content']['application/json']} GetBusStationsNearBy
 *
 * @typedef {ApiOperations['get-v1-railway-stations-nearby']['responses']['200']['content']['application/json']} GetRailwayStationsNearBy
 *
 * @typedef {ApiOperations['get-v1-bus-stations-:id']['responses']['200']['content']['application/json']} GetBusStation
 *
 * @typedef {ApiOperations['get-v1-railway-stations-:id']['responses']['200']['content']['application/json']} GetRailwayStation
 */
export const stationApi = backendApi.injectEndpoints({
    endpoints: (builder) => {
        return {
            searchStationsNearBy: builder.query({
                queryFn: async (args, queryApi) => {
                    try {
                        const location = {}
                        if (typeof args === 'string') {
                            const geocodeQuery = await queryApi.dispatch(
                                backendApi.endpoints.getGeocode.initiate(args)
                            )
                            if (geocodeQuery.error) {
                                return {
                                    error: geocodeQuery.error,
                                }
                            }
                            Object.assign(location, {
                                lat: geocodeQuery.data.lat,
                                lon: geocodeQuery.data.lon,
                            })
                        } else {
                            Object.assign(location, {
                                lat: args.lat,
                                lon: args.lng,
                            })
                        }

                        const params = {
                            location,
                            distance: {
                                max: 3000,
                            },
                            limit: 5,
                        }
                        const listRailways = async () => {
                            /** @type {any} */
                            const nearbyRes = await queryApi.dispatch(
                                stationApi.endpoints.listRailwayStationsNearby.initiate(
                                    params
                                )
                            )
                            const listRes = await queryApi.dispatch(
                                stationApi.endpoints.listRailwayStations.initiate(
                                    {
                                        id: nearbyRes.data.items.map(
                                            (item) => item._id
                                        ),
                                    }
                                )
                            )
                            return {
                                paging: nearbyRes.data.paging,
                                items: nearbyRes.data.items.map((dst) => {
                                    const entity = listRes.data.items.find(
                                        (src) => src._id === dst._id
                                    )
                                    return {
                                        ...entity,
                                        distance: dst.distance,
                                        type: '1',
                                        id: entity._id,
                                    }
                                }),
                            }
                        }
                        const listBusses = async () => {
                            /** @type {any} */
                            const nearbyRes = await queryApi.dispatch(
                                stationApi.endpoints.listBusStationsNearby.initiate(
                                    params
                                )
                            )
                            const listRes = await queryApi.dispatch(
                                stationApi.endpoints.listBusStations.initiate({
                                    id: nearbyRes.data.items.map(
                                        (item) => item._id
                                    ),
                                })
                            )
                            return {
                                paging: nearbyRes.data.paging,
                                items: nearbyRes.data.items.map((dst) => {
                                    const entity = listRes.data.items.find(
                                        (src) => src._id === dst._id
                                    )
                                    return {
                                        ...entity,
                                        distance: dst.distance,
                                        type: '2',
                                        id: entity._id,
                                    }
                                }),
                            }
                        }
                        const data = await Promise.all([
                            listRailways(),
                            listBusses(),
                        ])
                        return { data }
                    } catch (error) {
                        // Catch any errors and return them as an object with an `error` field
                        return { error }
                    }
                },
            }),
            searchStations: builder.query({
                queryFn: async (args, queryApi) => {
                    // TODO: エラーハンドリング
                    const params = args
                    const listRailways = async () => {
                        const res = await queryApi.dispatch(
                            stationApi.endpoints.listRailwayStations.initiate(
                                params
                            )
                        )
                        return res.data
                    }
                    const listBusses = async () => {
                        const res = await queryApi.dispatch(
                            stationApi.endpoints.listBusStations.initiate(
                                params
                            )
                        )
                        return res.data
                    }
                    const data = await Promise.all([
                        listRailways(),
                        listBusses(),
                    ])
                    return { data }
                },
            }),
            listRailwayStations: builder.query({
                query: (params) => ({
                    url: '/v1/railway-stations',
                    params,
                }),
                providesTags: () => ['listRailwayStations'],
            }),
            listBusStations: builder.query({
                query: (params) => ({
                    url: '/v1/bus-stations',
                    params,
                }),
                providesTags: () => ['listBusStations'],
            }),
            listRailwayStationsNearby: builder.query({
                query: (params) => ({
                    url: '/v1/railway-stations/nearby',
                    params,
                }),
            }),
            listBusStationsNearby: builder.query({
                query: (params) => ({
                    url: '/v1/bus-stations/nearby',
                    params,
                }),
            }),
            searchEkitanStations: builder.query({
                query: (params) => ({
                    url: '/v1/routes/search',
                    params,
                }),
            }),
            updateStationApiCode: builder.mutation({
                queryFn: async (args, queryApi) => {
                    // TODO: エラーハンドリング
                    const params = args
                    const type = params.gmId === undefined ? 1 : 2 // TODO
                    const endpoint =
                        type === 1
                            ? stationApi.endpoints.updateRailwayStationApiCode
                            : stationApi.endpoints.updateBusStationApiCode
                    const res = await queryApi.dispatch(
                        endpoint.initiate(params)
                    )
                    return res.data
                },
            }),
            updateRailwayStationApiCode: builder.mutation({
                query: (body) => ({
                    url: `/v1/railway-stations/${body._id}`,
                    method: 'PUT',
                    body,
                }),
                invalidatesTags: ['listRailwayStations'],
            }),
            updateBusStationApiCode: builder.mutation({
                query: (body) => ({
                    url: `/v1/bus-stations/${body._id}`,
                    method: 'PATCH',
                    body,
                }),
                invalidatesTags: ['listBusStations'],
            }),
            unsetRailwayStationApiCode: builder.mutation({
                query: (body) => ({
                    url: `/v1/railway-stations/${body._id}/link`,
                    method: 'DELETE',
                    body,
                }),
            }),
            listRailwayRoutes: builder.query({
                query: (params) => ({
                    url: '/v1/railway-stations/routes',
                    params,
                }),
            }),
            listBusRoutes: builder.query({
                query: (params) => ({
                    url: '/v1/bus-stations/routes',
                    params,
                }),
            }),
            createRouteCache: builder.mutation({
                query: (params) => ({
                    url: '/v1/routes/nmin',
                    method: 'POST',
                    params,
                }),
            }),
            getReverseGeocode: builder.query({
                query: (params) => ({
                    url: `/v1/reverse-geocode`,
                    params,
                }),
            }),
            getRailwayStation: builder.query({
                query: (id) => ({
                    url: `/v1/railway-stations/${id}`,
                }),
            }),
            getBusStation: builder.query({
                query: (id) => ({
                    url: `/v1/bus-stations/${id}`,
                }),
            }),
        }
    },
    overrideExisting: false,
})
export const {
    useLazySearchStationsNearByQuery,
    useLazySearchStationsQuery,
    useSearchEkitanStationsQuery,
    useUpdateStationApiCodeMutation,
    useUnsetRailwayStationApiCodeMutation,
    useListRailwayStationsQuery,
    useListBusStationsQuery,
    useGetReverseGeocodeQuery,
} = stationApi
/**
 * @deprecated
 * @param {any} lon
 * @param {any} lat
 * @returns
 */
export const getBusStationsNearBy = async (lon, lat) => {
    /** @type {GetBusStationsNearBy} */
    const nearby = await axios.get('/v1/bus-stations/nearby', {
        params: {
            'location[lat]': lat,
            'location[lon]': lon,
            'distance[max]': 3000,
            limit: 100,
        },
    })
    const requests = nearby.items.map((item) => getBusStation(item._id))
    const stations = Promise.all(requests)
    return stations
}
/**
 * @deprecated
 * @param {any} lon
 * @param {any} lat
 * @returns
 */
export const getRailwayStationsNearBy = async (lon, lat) => {
    /** @type {GetRailwayStationsNearBy} */
    const nearby = await axios.get('/v1/railway-stations/nearby', {
        params: {
            'location[lat]': lat,
            'location[lon]': lon,
            'distance[max]': 3000,
            limit: 100,
        },
    })
    const requests = nearby.items.map((item) => getRailwayStation(item._id))
    const stations = Promise.all(requests)
    return stations
}
/**
 * @deprecated
 * @param {string} id
 * @returns
 */
export const getBusStation = async (id) => {
    /** @type {GetBusStation} */
    const res = await axios.get(`/v1/bus-stations/${id}`)
    return res
}
/**
 * @deprecated
 * @param {any} id
 * @returns
 */
export const getRailwayStation = async (id) => {
    /** @type {GetRailwayStation} */
    const res = await axios.get(`/v1/railway-stations/${id}`)
    return res
}
/**
 * @deprecated
 * @returns
 */
export const getGeocode = async (params) => {
    const res = await axios.get('/v1/geocode', {
        params,
    })
    return res
}
