import { backendApi } from '@/stores/backendApi'

const notificationsApi = backendApi.injectEndpoints({
    endpoints: (builder) => {
        return {
            listNotifications: builder.query({
                query: (params) => ({
                    url: '/v1/notifications',
                    params: {
                        ...params,
                        sort: 'duration.begin, period.begin',
                        direction: 'asc',
                    },
                }),
                providesTags: () => [
                    'Notification',
                    'Announcement',
                    'Maintenance',
                ],
            }),
        }
    },
    overrideExisting: false,
})

export const { useListNotificationsQuery } = notificationsApi

const maintenancesApi = backendApi.injectEndpoints({
    endpoints: (builder) => {
        return {
            createMaintenance: builder.mutation({
                query: (maintenance) => ({
                    url: '/v1/admins/maintenances',
                    method: 'POST',
                    body: maintenance,
                }),
                invalidatesTags: ['Notification', 'Maintenance'],
            }),
            deleteMaintenance: builder.mutation({
                query: (id) => ({
                    url: `/v1/admins/maintenances/${id}`,
                    method: 'DELETE',
                }),
                invalidatesTags: ['Notification', 'Maintenance'],
            }),
            editMaintenance: builder.mutation({
                query: ({ id, maintenance }) => ({
                    url: `/v1/admins/maintenances/${id}`,
                    method: 'PUT',
                    body: maintenance,
                }),
                invalidatesTags: ['Notification', 'Maintenance'],
            }),
        }
    },
    overrideExisting: false,
})

export const {
    useCreateMaintenanceMutation,
    useDeleteMaintenanceMutation,
    useEditMaintenanceMutation,
} = maintenancesApi

const announcementsApi = backendApi.injectEndpoints({
    endpoints: (builder) => {
        return {
            createAnnouncement: builder.mutation({
                query: (announcement) => ({
                    url: '/v1/admins/announcements',
                    method: 'POST',
                    body: announcement,
                }),
                invalidatesTags: ['Notification', 'Announcement'],
            }),
            deleteAnnouncement: builder.mutation({
                query: (id) => ({
                    url: `/v1/admins/announcements/${id}`,
                    method: 'DELETE',
                }),
                invalidatesTags: ['Notification', 'Announcement'],
            }),
            editAnnouncement: builder.mutation({
                query: ({ id, announcement }) => ({
                    url: `/v1/admins/announcements/${id}`,
                    method: 'PUT',
                    body: announcement,
                }),
                invalidatesTags: ['Notification', 'Announcement'],
            }),
        }
    },
    overrideExisting: false,
})

export const {
    useCreateAnnouncementMutation,
    useDeleteAnnouncementMutation,
    useEditAnnouncementMutation,
} = announcementsApi
