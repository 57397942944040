import { Stack, HStack } from '@chakra-ui/react'
import { useContext } from 'react'

import { FacilityContext } from '..'
import {
    TextHeadingComponent,
    TextHStackComponent,
} from '../../FacilityItemComponents'

export const KaigoUsersItem = () => {
    const data = useContext(FacilityContext)
    return (
        <>
            <TextHeadingComponent
                label="利用者情報"
                isObject={[data.officeCapacity, data.userCountPerStaff]}
            >
                <Stack>
                    <TextHStackComponent label="事業所利用者定員">
                        {data.officeCapacity}
                    </TextHStackComponent>
                    <TextHStackComponent label="スタッフあたり利用者数">
                        {data.userCountPerStaff}
                    </TextHStackComponent>
                </Stack>
            </TextHeadingComponent>

            <TextHeadingComponent
                label="介護度別利用者数"
                isObject={[
                    data.numberOfResidents.nursingLevelNursing1,
                    data.numberOfResidents.nursingLevelNursing2,
                    data.numberOfResidents.nursingLevelNursing3,
                    data.numberOfResidents.nursingLevelNursing4,
                    data.numberOfResidents.nursingLevelNursing5,
                    data.numberOfResidents.nursingLevelSupport1,
                    data.numberOfResidents.nursingLevelSupport2,
                ]}
            >
                <Stack whiteSpace="nowrap">
                    <HStack>
                        <TextHStackComponent label="介護度別利用者数_介護1">
                            {data.numberOfResidents.nursingLevelNursing1}
                        </TextHStackComponent>
                        <TextHStackComponent label="介護度別利用者数_介護2">
                            {data.numberOfResidents.nursingLevelNursing2}
                        </TextHStackComponent>
                        <TextHStackComponent label="介護度別利用者数_介護3">
                            {data.numberOfResidents.nursingLevelNursing3}
                        </TextHStackComponent>
                    </HStack>
                    <HStack>
                        <TextHStackComponent label="介護度別利用者数_介護4">
                            {data.numberOfResidents.nursingLevelNursing4}
                        </TextHStackComponent>
                        <TextHStackComponent label="介護度別利用者数_介護5">
                            {data.numberOfResidents.nursingLevelNursing5}
                        </TextHStackComponent>
                    </HStack>
                    <HStack>
                        <TextHStackComponent label="介護度別利用者数_支援1">
                            {data.numberOfResidents.nursingLevelSupport1}
                        </TextHStackComponent>
                        <TextHStackComponent label="介護度別利用者数_支援2">
                            {data.numberOfResidents.nursingLevelSupport2}
                        </TextHStackComponent>
                    </HStack>
                </Stack>
            </TextHeadingComponent>
        </>
    )
}
export const HoikuUsersItem = () => {
    const data = useContext(FacilityContext)
    return (
        <>
            <TextHeadingComponent
                label="利用者情報"
                isObject={[data.officeCapacity]}
            >
                <Stack>
                    <TextHStackComponent label="事業所利用者定員">
                        {data.officeCapacity}
                    </TextHStackComponent>
                </Stack>
            </TextHeadingComponent>

            <TextHeadingComponent
                label="年齢別利用者数"
                isObject={[
                    data.age0ChildrenUsing,
                    data.age1ChildrenUsing,
                    data.age2ChildrenUsing,
                    data.age3ChildrenUsing,
                    data.age4ChildrenUsing,
                    data.age5ChildrenUsing,
                    data.totalChildrenUsing,
                ]}
            >
                <Stack whiteSpace="nowrap">
                    <TextHStackComponent label="合計">
                        {`${data.totalChildrenUsing}人`}
                    </TextHStackComponent>
                    <HStack>
                        <TextHStackComponent label="0歳">
                            {data.age0ChildrenUsing}
                        </TextHStackComponent>
                        <TextHStackComponent label="1歳">
                            {data.age1ChildrenUsing}
                        </TextHStackComponent>
                        <TextHStackComponent label="2歳">
                            {data.age2ChildrenUsing}
                        </TextHStackComponent>

                        <TextHStackComponent label="3歳">
                            {data.age3ChildrenUsing}
                        </TextHStackComponent>
                        <TextHStackComponent label="4歳">
                            {data.age4ChildrenUsing}
                        </TextHStackComponent>
                        <TextHStackComponent label="5歳">
                            {data.age5ChildrenUsing}
                        </TextHStackComponent>
                    </HStack>
                </Stack>
            </TextHeadingComponent>
        </>
    )
}
