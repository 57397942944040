import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'

const evaluationsAdapter = createEntityAdapter({
    selectId: (evaluations) => evaluations.facId,
})

export const evaluationsSlice = createSlice({
    name: 'evaluations',
    initialState: evaluationsAdapter.getInitialState(),
    reducers: {
        setEvaluation: (state, action) => {
            const { facId, evaluation } = action.payload
            if (evaluation === 'good' || evaluation === 'bad') {
                evaluationsAdapter.setOne(state, action.payload)
            } else {
                evaluationsAdapter.removeOne(state, facId)
            }
        },
    },
})

export const { setEvaluation } = evaluationsSlice.actions

export const evaluationsReducer = evaluationsSlice.reducer

export const evaluationsSelectors = evaluationsAdapter.getSelectors(
    (state) => state.evaluations
)
