import PropTypes from 'prop-types'

export const stationBusTableCellPropType = {
    railwayAndBusStationData: PropTypes.arrayOf(
        PropTypes.shape({
            _id: PropTypes.string,
            facilities: PropTypes.arrayOf(
                PropTypes.shape({
                    name: PropTypes.string.isRequired,
                    modifiedDate: PropTypes.string,
                })
            ),
        })
    ),
    row: PropTypes.shape({
        index: PropTypes.number.isRequired,
    }),
}
