import { Stack, HStack } from '@chakra-ui/react'
import { useContext } from 'react'

import { FacilityContext } from '..'
import {
    TextHeadingComponent,
    TextHStackComponent,
} from '../../FacilityItemComponents'

export const KaigoHoursItem = () => {
    const data = useContext(FacilityContext)
    return (
        <TextHeadingComponent
            label="事業所営業時間"
            isObject={[
                data.businessHours.weekday,
                data.businessHours.saturday,
                data.businessHours.sunday,
                data.businessHours.holiday,
                data.regularHoliday,
            ]}
        >
            <Stack>
                <HStack>
                    <TextHStackComponent label="(平日)">
                        {data.businessHours.weekday}
                    </TextHStackComponent>
                    <TextHStackComponent label="(土曜)">
                        {data.businessHours.saturday}
                    </TextHStackComponent>
                </HStack>
                <HStack>
                    <TextHStackComponent label="(日曜)">
                        {data.businessHours.sunday}
                    </TextHStackComponent>
                    <TextHStackComponent label="(祝日)">
                        {data.businessHours.holiday}
                    </TextHStackComponent>
                </HStack>
                <TextHStackComponent label="事業所定休日">
                    {data.regularHoliday}
                </TextHStackComponent>
            </Stack>
        </TextHeadingComponent>
    )
}

export const HoikuHoursItem = () => {
    const data = useContext(FacilityContext)
    return (
        <TextHeadingComponent
            label="事業所営業時間"
            isObject={[
                data.businessHours.weekday,
                data.businessHours.saturday,
                data.businessHours.sunday,
                data.regularHoliday,
            ]}
        >
            <Stack>
                <HStack>
                    <TextHStackComponent label="(平日)">
                        {data.businessHours.weekday}
                    </TextHStackComponent>
                    <TextHStackComponent label="(土曜)">
                        {data.businessHours.saturday}
                    </TextHStackComponent>
                </HStack>
                <HStack>
                    {/* sundeyでもholidayでもデータの中身が同じなので一つだけ表示 */}
                    <TextHStackComponent label="(日曜・祝日)">
                        {data.businessHours.sunday}
                    </TextHStackComponent>
                </HStack>
                <TextHStackComponent label="事業所定休日">
                    {data.regularHoliday}
                </TextHStackComponent>
            </Stack>
        </TextHeadingComponent>
    )
}
