import {
    Button,
    AlertDialog,
    AlertDialogBody,
    AlertDialogHeader,
    AlertDialogOverlay,
    AlertDialogContent,
    AlertDialogCloseButton,
    AlertDialogFooter,
    Text,
} from '@chakra-ui/react'
import PropTypes from 'prop-types'

import { JobseekerInfo } from '@/components/Elements'

export const ConfirmSfIdDialog = ({
    jobseeker,
    isChangeSfId,
    leastDestructiveRef,
    onOk,
    onCancel,
    onClose,
    isOpen,
    isProposalLink,
}) => {
    return (
        <AlertDialog
            size="4xl"
            isOpen={isOpen}
            onClose={onClose ?? onCancel}
            leastDestructiveRef={leastDestructiveRef}
        >
            <AlertDialogOverlay />
            <AlertDialogContent>
                <AlertDialogHeader textAlign="center" fontFamily="fonts">
                    {jobseeker && isProposalLink
                        ? `${jobseeker.sfId}様の提案書を閲覧いたしますか？`
                        : jobseeker
                        ? `${jobseeker.sfId}様の提案書を作成いたしますか？`
                        : ''}
                </AlertDialogHeader>
                <AlertDialogCloseButton onClick={onClose ?? onCancel} />
                <AlertDialogBody textAlign="center">
                    <JobseekerInfo jobseeker={jobseeker} />
                </AlertDialogBody>
                {isChangeSfId && (
                    <Text
                        mt="20px"
                        ml="22px"
                        color="red"
                        fontWeight="bold"
                        textAlign="center"
                    >
                        *現在設定中のSFIDが切り替わります
                    </Text>
                )}
                <AlertDialogFooter justifyContent="center">
                    <Button autoFocus onClick={onOk} width="200px">
                        OK
                    </Button>
                    <Button onClick={onCancel} width="200px" marginLeft="8px">
                        キャンセル
                    </Button>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    )
}

ConfirmSfIdDialog.propTypes = {
    jobseeker: PropTypes.shape({
        sfId: PropTypes.string,
        prefecture: PropTypes.string,
        city: PropTypes.string,
        sex: PropTypes.string,
        age: PropTypes.number,
    }).isRequired,
    isChangeSfId: PropTypes.bool,
    leastDestructiveRef: PropTypes.shape({
        current: PropTypes.any,
    }),
    onOk: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onClose: PropTypes.func,
    isOpen: PropTypes.bool.isRequired,
    isProposalLink: PropTypes.bool,
}
