import PropTypes from 'prop-types'

export const Page500 = ({ error }) => {
    return (
        <div>
            <h1>Page 500</h1>
            <pre>{error?.stack}</pre>
        </div>
    )
}
Page500.propTypes = {
    error: PropTypes.any,
    resetErrorBoundary: PropTypes.func,
}
