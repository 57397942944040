import { Stack, Text, Wrap, WrapItem } from '@chakra-ui/react'
import { useContext } from 'react'

import { FacilityContext } from '..'
import {
    FacilityHeading,
    TextHeadingComponent,
    TextHStackComponent,
} from '../../FacilityItemComponents'

export const KangoItems = () => {
    const data = useContext(FacilityContext)
    return (
        <>
            {data?.clinicalDepartment?.length > 0 && (
                <TextHeadingComponent
                    label="診療科目"
                    isObject={[data.clinicalDepartment]}
                >
                    <Wrap
                        sx={{
                            'li:not(:last-child)::after': {
                                content: '","',
                            },
                        }}
                    >
                        {data.clinicalDepartment?.map((dep) => (
                            <WrapItem key={dep}>
                                <Text noOfLines={1}>{dep}</Text>
                            </WrapItem>
                        ))}
                    </Wrap>
                </TextHeadingComponent>
            )}

            <Stack direction="row" pb="20px">
                <FacilityHeading props={{ size: 'md' }}>
                    救急指定
                </FacilityHeading>
                <Stack>
                    {/*3つとも1でなかった場合はなしと表示 */}
                    {!data.emergencyNotificationHospital &&
                        !data.secondaryEmergencyMedicalInstitution &&
                        !data.tertiaryEmergencyMedicalInstitution && (
                            <TextHStackComponent label="">
                                なし
                            </TextHStackComponent>
                        )}
                    {/* 救急告示病院が１になった時の処理 */}
                    {data.emergencyNotificationHospital && (
                        <TextHStackComponent label="">
                            救急告示病院
                        </TextHStackComponent>
                    )}
                    {/* 第二次救急機関が１になった時の処理 */}
                    {data.secondaryEmergencyMedicalInstitution && (
                        <TextHStackComponent label="">
                            第二次救急医療機関
                        </TextHStackComponent>
                    )}
                    {/* 第三次救急期間が１になった時の処理 */}
                    {data.tertiaryEmergencyMedicalInstitution && (
                        <TextHStackComponent label="">
                            第三次救急医療機関
                        </TextHStackComponent>
                    )}
                </Stack>
            </Stack>

            <TextHeadingComponent
                label="病床数"
                isObject={[
                    data.totalNumberOfBeds,
                    data.numberOfGeneralBeds,
                    data.numberOfConvalescentBeds,
                    data.numberOfPsychiatricBeds,
                ]}
            >
                <Stack>
                    <TextHStackComponent label="総病床数">
                        {data.totalNumberOfBeds}
                    </TextHStackComponent>
                    <TextHStackComponent label="一般病床数">
                        {data.numberOfGeneralBeds}
                    </TextHStackComponent>
                    <TextHStackComponent label="療養型病床数">
                        {data.numberOfConvalescentBeds}
                    </TextHStackComponent>
                    <TextHStackComponent label="精神病床数">
                        {data.numberOfPsychiatricBeds}
                    </TextHStackComponent>
                </Stack>
            </TextHeadingComponent>
            <TextHeadingComponent label="休業日" isObject={[data.closedDay]}>
                <TextHStackComponent label="">
                    {data.closedDay}
                </TextHStackComponent>
            </TextHeadingComponent>

            <TextHeadingComponent
                label="特記事項"
                isObject={[data.specialRemarks?.trim()]}
            >
                <TextHStackComponent label="">
                    {data.specialRemarks}
                </TextHStackComponent>
            </TextHeadingComponent>
        </>
    )
}
