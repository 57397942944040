import { ArrorDownIcon } from '@/assets/icon'
import { Box, Menu, MenuButton, MenuList, Tag } from '@chakra-ui/react'
import PropTypes from 'prop-types'

export const FilterComponent = ({ label, arrayLength, children }) =>
    children && (
        <Box>
            <Menu closeOnSelect={false} eventListeners={false}>
                <MenuButton>
                    <Tag
                        bg={arrayLength ? '#EAF8F1' : 'white'}
                        color="primary.500"
                        border="1px"
                        borderColor="primary.500"
                        fontSize="sm"
                        fontWeight="bold"
                    >
                        {label}
                        <ArrorDownIcon boxSize="15px" />
                    </Tag>
                </MenuButton>
                <MenuList>{children}</MenuList>
            </Menu>
        </Box>
    )

FilterComponent.propTypes = {
    label: PropTypes.string,
    arrayLength: PropTypes.number,
    children: PropTypes.any.isRequired,
}
