import { createListenerMiddleware } from '@reduxjs/toolkit'
import { io } from 'socket.io-client'

import { NODE_BB_API_TOKEN, NODE_BB_BASE_URL } from '@/config'
import { contactApi } from '@/stores'

export const socketMiddleware = createListenerMiddleware()

const apiURL = `${NODE_BB_BASE_URL}/api`

// トピックの作成を検知
socketMiddleware.startListening({
    predicate: (action) => {
        return (
            action.type === 'contactApi/executeQuery/fulfilled' &&
            ['listMyTopics', 'listContactTopics'].includes(
                action.meta.arg.endpointName
            )
        )
    },
    effect: streamingUpdates((action, listenerApi, socket) => {
        socket.on('event:new_topic', (topic) => {
            listenerApi.dispatch({
                type: 'socket/topicAdded',
                payload: topic,
            })
        })
    }),
})
socketMiddleware.startListening({
    type: 'socket/topicAdded',
    effect: (action, listenerApi) => {
        listenerApi.dispatch(contactApi.util.invalidateTags(['Topics']))
    },
})

/**
 * フォーラムからデータ更新を受信する
 *
 * - @param {( action: import('@reduxjs/toolkit').AnyAction, listenerApi:
 *   import('@reduxjs/toolkit').ListenerEffectAPI, socket:
 *   import('socket.io-client').Socket ) => void} addHandlers
 *
 * @see {@link https://redux-toolkit.js.org/rtk-query/usage/streaming-updates}
 */
function streamingUpdates(addHandlers) {
    return async (action, listenerApi) => {
        const socket = await handshake()
        const unsubscribe = new Promise((resolve) => {
            listenerApi.signal.addEventListener('abort', () => {
                socket.disconnect()
                resolve()
            })
        })
        addHandlers(action, listenerApi, socket)
        socket.on('connect', () => {
            listenerApi.cancelActiveListeners()
        })
        await listenerApi.pause(unsubscribe)
    }
}

const socketUrl = new URL('/', apiURL)
socketUrl.protocol = socketUrl.protocol === 'https:' ? 'wss:' : 'ws:'

/**
 * ソケット通信を開始
 *
 * @see {@link https://socket.io/docs/v4/handling-cors/}
 * @see {@link https://github.com/NodeBB/NodeBB/blob/v3.1.4/public/src/sockets.js}
 */
async function handshake() {
    await fetch(new URL('/api/login', apiURL), {
        headers: {
            Authorization: `Bearer ${NODE_BB_API_TOKEN}`,
        },
        credentials: 'include',
    })
    const config = await fetch(new URL('/api/config', apiURL), {
        credentials: 'include',
    })
    const { csrf_token } = await config.json()
    const socket = io(socketUrl.toString(), {
        withCredentials: true,
        query: {
            _csrf: csrf_token,
        },
    })
    socket.on('connect', () => {
        console.log('socket.io', 'connect', socket)
    })
    socket.on('disconnect', (reason) => {
        console.log('socket.io', 'disconnect', reason)
    })
    socket.on('error', (error) => {
        console.warn('socket.io', 'error', error)
    })
    // socket.onAny((event, ...args) => {
    //     console.log('socket.io', `got ${event}`, args)
    // })
    return socket
}
