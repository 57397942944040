import { Flex, Text } from '@chakra-ui/react'
import PropTypes from 'prop-types'

import { RecruitmentItem } from './'

export const TextComponent = ({ label, children }) => {
    return (
        children && (
            <Flex>
                <RecruitmentItem>{label}</RecruitmentItem>
                <Text w="610px">{children}</Text>
            </Flex>
        )
    )
}

TextComponent.propTypes = {
    label: PropTypes.string.isRequired,
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}
