import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { Paging } from 'shared/utils/paging'
import qs from 'shared/utils/qs'

import { NODE_BB_API_TOKEN, NODE_BB_BASE_URL, PARENT_CATEGORY } from '@/config'

const baseQuery = fetchBaseQuery({
    baseUrl: `${NODE_BB_BASE_URL}/api`,
    prepareHeaders: (headers) => {
        headers.set('authorization', `Bearer ${NODE_BB_API_TOKEN}`)
        return headers
    },
    paramsSerializer: (params) => {
        return qs.stringify(params)
    },
})

// NOTE: キャッシュコントロールの実装 → socketMiddleware
export const contactApi = createApi({
    reducerPath: 'contactApi',
    baseQuery: baseQuery,
    tagTypes: ['Topics'],
    endpoints: (builder) => ({
        getUser: builder.query({
            query: ({ username }) => ({
                url: `/user/username/${username}`,
            }),
            providesTags: [
                // NOTE: お問い合わせ作成中に登録されるケースがあるから
                'Topics',
            ],
        }),
        getCategory: builder.query({
            query: ({ categoryId }) => ({
                url: `/category/${categoryId}`,
            }),
            // FIXME: 今はこれしか使わないので暫定
            transformResponse: (response) => {
                const { cid, name, children } = response
                return {
                    cid,
                    name,
                    cids: [cid].concat(children.map((c) => c.cid)),
                }
            },
        }),
        // NOTE: NodeBB の作り的にこっちはカテゴリ横断の方が良さそう
        listMyTopics: builder.query({
            queryFn: async (
                { username, page },
                api,
                extraOptions,
                baseQuery
            ) => {
                // see: https://redux-toolkit.js.org/rtk-query/usage/customizing-queries#performing-multiple-requests-with-a-single-query
                const userQuery = await api.dispatch(
                    contactApi.endpoints.getUser.initiate({ username })
                )
                if (userQuery.error) {
                    if (userQuery.error.status === 404) {
                        // not registered
                        return {
                            data: {
                                topics: [],
                            },
                        }
                    } else {
                        return {
                            error: userQuery.error,
                        }
                    }
                }
                const user = userQuery.data
                const topicsQuery = await baseQuery({
                    url: `/user/${user.userslug}/topics`,
                    params: {
                        page,
                    },
                })
                if (topicsQuery.data) {
                    /** @type {any} */
                    const data = topicsQuery.data
                    // NOTE: フォーラムで使用しないから正しいページネーションがついていない
                    const paging = new Paging({ limit: 5, page })
                    paging.setCount(data.topiccount)
                    return {
                        data: {
                            ...data,
                            pagination: {
                                currentPage: paging.page,
                                pageCount: paging.pageCount,
                            },
                        },
                    }
                }
                return {
                    error: topicsQuery.error,
                }
            },
            providesTags: ['Topics'],
        }),
        listContactTopics: builder.query({
            queryFn: async ({ page }, api, extraOptions, baseQuery) => {
                const categoryQuery = await api.dispatch(
                    contactApi.endpoints.getCategory.initiate({
                        categoryId: PARENT_CATEGORY,
                    })
                )
                if (categoryQuery.error) {
                    return {
                        error: categoryQuery.error,
                    }
                }
                const topicsQuery = await baseQuery({
                    url: '/recent',
                    params: {
                        page,
                        cid: categoryQuery.data.cids,
                    },
                })
                return topicsQuery.data
                    ? {
                          data: topicsQuery.data,
                      }
                    : {
                          error: topicsQuery.error,
                      }
            },
            providesTags: ['Topics'],
        }),
        getTopic: builder.query({
            query: ({ tid }) => ({
                url: `/topic/${tid}`,
            }),
            transformResponse: (response) => {
                return {
                    ...response,
                    mainPost: response.posts.find(
                        (post) => post.pid === response.mainPid
                    ),
                }
            },
        }),
    }),
})
