import { Text } from '@chakra-ui/react'
import { useEffect } from 'react'
import { useAuth } from 'react-oidc-context'

export const Logout = () => {
    const { removeUser, signoutRedirect } = useAuth()
    useEffect(() => {
        const logout = async () => {
            // TODO: 別タブにも送信しないとそっちはログアウトできない
            await removeUser()
            signoutRedirect()
        }
        logout()
    }, [])
    return <Text>ログアウトしています...</Text>
}
Logout.propTypes = {}
