export const occupationChoices = [
    { label: 'PT・OT・ST', value: 'PT・OT・ST' },
    { label: '技師', value: '技師' },
    { label: '薬剤師', value: '薬剤師' },
    { label: '栄養士', value: '栄養士' },
    { label: '保育', value: '保育' },
    { label: '看護＆助産師', value: '看護＆助産師' },
    { label: '介護', value: '介護' },
    { label: 'デンタル', value: 'デンタル' },
    { label: '医師', value: '医師' },
]
