import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

// eslint-disable-next-line no-restricted-imports
import { WithJobseekerSelectorLink } from '@/features/jobseekers/components/WithJobseekerSelectorLink'
// eslint-disable-next-line no-restricted-imports
import { WithJobseekerSwitcherLink } from '@/features/jobseekers/components/WithJobseekerSwitcherLink'
import { jobseekerSelector } from '@/stores'

export const HeaderSearchButton = ({ children }) => {
    const { isConfirmed } = useSelector(jobseekerSelector)

    const href = '/facilities'

    return isConfirmed ? (
        <WithJobseekerSwitcherLink href={href}>
            {children}
        </WithJobseekerSwitcherLink>
    ) : (
        <WithJobseekerSelectorLink href={href}>
            {children}
        </WithJobseekerSelectorLink>
    )
}

HeaderSearchButton.propTypes = {
    children: PropTypes.node,
}
