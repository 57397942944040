import { IconButton } from '@chakra-ui/react'
import PropTypes from 'prop-types'

import { GoodIcon, BadIcon } from '@/assets/icon'

export const GoodButton = ({ onClick, isActive }) => {
    return (
        <IconButton
            className="icon-button"
            variant="unstyled"
            aria-label="good"
            margin="2px"
            h="fit-content"
            icon={
                <GoodIcon
                    sx={{
                        '.icon-button[data-active] &': {
                            color: 'primary.500',
                        },
                    }}
                />
            }
            onClick={onClick}
            isActive={isActive}
        />
    )
}
GoodButton.propTypes = {
    onClick: PropTypes.func,
    isActive: PropTypes.bool,
}

export const BadButton = ({ onClick, isActive }) => {
    return (
        <IconButton
            className="icon-button"
            variant="unstyled"
            aria-label="bad"
            margin="2px"
            h="fit-content"
            icon={
                <BadIcon
                    sx={{
                        '.icon-button[data-active] &': {
                            color: 'red',
                        },
                    }}
                />
            }
            onClick={onClick}
            isActive={isActive}
        />
    )
}
BadButton.propTypes = {
    onClick: PropTypes.func,
    isActive: PropTypes.bool,
}
