export const colors = {
    primary: {
        900: '#1A455E',
        800: '#226774',
        700: '#2A8984',
        600: '#339E82',
        500: '#3CB37A',
        400: '#59C178',
        300: '#77CF7E',
        200: '#A0DB96',
        100: '#C8E7B5',
        50: '#EAF8F1',
        gradation: 'linear-gradient(123deg, #8bc34a, #3cb37a)',
    },
    secondary: '#616161',
    dark: '#212121',
    subBackground: '#f6f5f1',
    lightGray: {
        300: '#f7f7f7',
        500: '#d9d9d9',
        700: '#6c6c6c',
    },
}
