// see: https://redux-toolkit.js.org/api/createEntityAdapter#examples

import { createSlice, createEntityAdapter } from '@reduxjs/toolkit'
import cloneDeep from 'lodash.clonedeep'

const facilitiesAdapter = createEntityAdapter({
    selectId: (facility) => facility._id,
})

const offersAdapter = createEntityAdapter({
    selectId: (offer) => offer._id,
})

const initialState = {
    jobSeeker: null,
}

export const proposalSlice = createSlice({
    name: 'proposal',
    initialState: facilitiesAdapter.getInitialState(initialState),
    reducers: {
        resetProposal: () => facilitiesAdapter.getInitialState(initialState),
        // 施設と案件のセットで置き換え
        replaceFacility: {
            reducer: (state, action) => {
                const { offers, ...facility } = action.payload
                facility.offers = offersAdapter.setAll(
                    offersAdapter.getInitialState(),
                    offers
                )
                // console.log(facility.offers, offers)
                facilitiesAdapter.setOne(state, facility)
            },
            prepare: (facility, offers = []) => {
                return {
                    payload: { ...facility, offers },
                    meta: undefined,
                    error: undefined,
                }
            },
        },
        // 施設を削除
        // see: https://github.com/aohua/redux-state-sync/issues/124#issuecomment-1145377083
        removeFacility: (state, action) =>
            facilitiesAdapter.removeOne(state, action.payload),
        // 案件の選択を追加
        addOffer: {
            reducer: (state, action) => {
                const {
                    payload: offer,
                    meta: { facId },
                } = action
                const facEntry = state.entities[facId]
                if (facEntry) {
                    facilitiesAdapter.updateOne(state, {
                        id: facId,
                        changes: {
                            offers: offersAdapter.setOne(
                                facEntry.offers,
                                offer
                            ),
                        },
                    })
                }
            },
            prepare: (offer, facId) => {
                return { payload: offer, meta: { facId }, error: undefined }
            },
        },
        // 案件の選択を削除
        removeOffer: {
            reducer: (state, action) => {
                const {
                    payload: offerId,
                    meta: { facId },
                } = action
                const facEntry = state.entities[facId]
                if (facEntry) {
                    facilitiesAdapter.updateOne(state, {
                        id: facId,
                        changes: {
                            offers: offersAdapter.removeOne(
                                facEntry.offers,
                                offerId
                            ),
                        },
                    })
                }
            },
            prepare: (offerId, facId) => {
                return { payload: offerId, meta: { facId }, error: undefined }
            },
        },
        // 施設のソート
        sortFacilities: (state, action) => {
            const { facilities, sort } = action.payload
            const newFacilitiesMain = []
            const newFacilitiesSub = []
            switch (sort) {
                case 'income':
                    facilities.map((item) => {
                        const newItem = {
                            ...item,
                            monthlyIncomeMax: -1,
                        }

                        const offers = Object.entries(item.offers.entities).map(
                            ([, value]) => value
                        )
                        if (!offers.length) {
                            newFacilitiesMain.push(newItem)
                        } else {
                            let incomeMax = -1
                            offers.map(
                                ({
                                    monthlyIncomeMax,
                                    annualIncomeToMonthly,
                                }) => {
                                    try {
                                        const monthly = Number(
                                            monthlyIncomeMax ?? 0
                                        )

                                        const annual = Number(
                                            annualIncomeToMonthly ?? 0
                                        )
                                        incomeMax = Math.max(monthly, annual)
                                    } catch (error) {
                                        console.log(error)
                                        incomeMax =
                                            incomeMax !== -1 ? incomeMax : -1
                                    }
                                    newItem.monthlyIncomeMax =
                                        newItem.monthlyIncomeMax <= incomeMax
                                            ? incomeMax
                                            : newItem.monthlyIncomeMax
                                }
                            )

                            newFacilitiesMain.push(newItem)
                        }
                    })

                    newFacilitiesMain.sort(
                        (a, b) => b.monthlyIncomeMax - a.monthlyIncomeMax
                    )
                    break
                case 'time':
                    facilities.map((item) => {
                        const newItem = { ...item }
                        if (!item.time) {
                            newFacilitiesSub.push(newItem)
                        } else {
                            newFacilitiesMain.push(newItem)
                        }
                    })
                    newFacilitiesMain.sort((a, b) => a.time - b.time)
                    newFacilitiesMain.push(...newFacilitiesSub)

                    break

                default:
                    newFacilitiesMain.push(...facilities)
                    break
            }
            const sortFacilities = cloneDeep(newFacilitiesMain)
            facilitiesAdapter.setAll(state, sortFacilities)
        },
    },
})

export const {
    resetProposal,
    replaceFacility,
    removeFacility,
    addOffer,
    removeOffer,
    sortFacilities,
} = proposalSlice.actions

export const proposalReducer = proposalSlice.reducer

export const facilitiesSelectors = facilitiesAdapter.getSelectors(
    (state) => state.proposal
)

// export const jobSeekerSelector = createSelector(
//     (state) => state.proposal.jobSeeker
// )

export const offersSelectors = offersAdapter.getSelectors(
    (facState) => facState.offers
)
