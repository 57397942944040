import { Button, Stack } from '@chakra-ui/react'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import {
    addOffer,
    facilitiesSelectors,
    offersSelectors,
    removeOffer,
    replaceFacility,
} from '@/stores'
import { backendApi } from '@/stores/backendApi'

/**
 * @typedef {import('@/types/api').components} ApiComponents
 *
 * @typedef {ApiComponents['schemas']['Facility']} Facility
 *
 * @typedef {ApiComponents['schemas']['Offer']} Offer
 * @param {Object} props
 * @param {Facility} props.facility
 * @param {Offer} props.offer
 */
export const JobSelectButton = ({ facility, offer }) => {
    const [writeSearchLog] = backendApi.endpoints.writeSearchLog.useMutation()
    const data = useMemo(
        () => ({
            facId: facility.code,
            facName: facility.name,
            offerId: offer.code,
            offerName: offer.title,
        }),
        [facility, offer]
    )
    const dispatch = useDispatch()
    const selectedFacility = useSelector((state) =>
        facilitiesSelectors.selectById(state, facility._id)
    )
    const selectedOffer = selectedFacility
        ? offersSelectors.selectById(selectedFacility, offer._id)
        : null
    if (selectedOffer) {
        return (
            <Stack w="160px" alignContent="center" align="center">
                <Button
                    isActive
                    onClick={() => {
                        writeSearchLog({
                            data,
                            msg: '案件を選択解除しました。',
                            function: 'offer-unselect',
                        })
                        dispatch(removeOffer(offer._id, facility._id))
                    }}
                >
                    選択中
                </Button>
            </Stack>
        )
    }
    return (
        <Stack w="160px" alignContent="center" align="center">
            <Button
                onClick={() => {
                    writeSearchLog({
                        data,
                        msg: '案件を選択しました。',
                        function: 'offer-select',
                    })
                    if (selectedFacility) {
                        dispatch(addOffer(offer, facility._id))
                    } else {
                        dispatch(replaceFacility(facility, [offer]))
                    }
                }}
            >
                この案件を選択する
            </Button>
        </Stack>
    )
}
JobSelectButton.propTypes = {
    facility: PropTypes.any.isRequired,
    offer: PropTypes.any.isRequired,
}
