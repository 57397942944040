import { Box, Flex, HStack, Spacer, CloseButton } from '@chakra-ui/react'
import PropTypes from 'prop-types'

import { LogoMenu } from '../LogoMenu'
import { Footer } from '../MainLayout/Footer'

export const SubWindowLayout = ({ children }) => {
    return (
        <Flex direction="column" minHeight="100vh">
            <HStack
                as="header"
                p="16px 10px 16px 10px"
                alignItems="flex-start"
                position="sticky"
                top="0"
                bg="white"
                zIndex="banner"
            >
                <LogoMenu home="#" isDisplay={false} />
                <Spacer />
                <CloseButton onClick={() => window.close()} />
            </HStack>
            <Box as="main" flex={1}>
                {children}
            </Box>
            <Box as="footer" width="100%" mt="1em">
                <Footer />
            </Box>
        </Flex>
    )
}
SubWindowLayout.propTypes = {
    children: PropTypes.node.isRequired,
}
