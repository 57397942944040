import { ContactSection } from '../components/ContactSection'

import { ContentLayout } from '@/components/Layout'
import { SubWindowLayout } from '@/components/Layout/SubWindowLayout/SubWindowLayout'

/** @type {React.FC} */
export const Contacts = () => (
    <SubWindowLayout>
        <ContentLayout title="お問い合わせ" fluid>
            <ContactSection />
        </ContentLayout>
    </SubWindowLayout>
)
