import {
    Button,
    AlertDialog,
    AlertDialogBody,
    AlertDialogHeader,
    AlertDialogOverlay,
    AlertDialogContent,
    useDisclosure,
    AlertDialogCloseButton,
    Text,
    AlertDialogFooter,
} from '@chakra-ui/react'
import PropTypes from 'prop-types'
import { useCallback } from 'react'

export const ErrorSfIdDialog = ({
    leastDestructiveRef,
    onOk: propOnOk,
    onCancel: propOnCancel,
}) => {
    const { isOpen, onClose } = useDisclosure({
        defaultIsOpen: true,
    })

    const onOk = useCallback(() => {
        propOnOk()
        onClose()
    }, [propOnOk, onClose])

    const onCancel = useCallback(() => {
        propOnCancel()
        onClose()
    }, [propOnOk, onClose])

    return (
        <AlertDialog
            size="4xl"
            isOpen={isOpen}
            onClose={onCancel}
            leastDestructiveRef={leastDestructiveRef}
        >
            <AlertDialogOverlay />
            <AlertDialogContent>
                <AlertDialogHeader>エラー</AlertDialogHeader>
                <AlertDialogCloseButton onClick={onCancel} />
                <AlertDialogBody>
                    <Text>正しいSFIDを入力してください</Text>
                </AlertDialogBody>
                <AlertDialogFooter>
                    <Button onClick={onOk}>OK</Button>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    )
}

ErrorSfIdDialog.propTypes = {
    leastDestructiveRef: PropTypes.shape({
        current: PropTypes.any,
    }),
    onOk: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
}
