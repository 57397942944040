import { createIcon } from '@chakra-ui/icons'
import { Icon as ChakraIcon } from '@chakra-ui/react'
import PropTypes from 'prop-types'
import { BsChatLeftDots } from 'react-icons/bs'
import { HiOutlineLink } from 'react-icons/hi'

import { proposalSlice } from '@/stores'

// see: https://chakra-ui.com/docs/components/icon/usage#creating-your-custom-icons
const Icon = ({ children, boxSize = 6, ...props }) => {
    return (
        <ChakraIcon {...props} boxSize={boxSize}>
            {children}
        </ChakraIcon>
    )
}
Icon.propTypes = {
    children: PropTypes.node,
    boxSize: PropTypes.any,
}

export const GoodIcon = (props) => (
    <Icon {...props}>
        <path
            fill="currentColor"
            d="M17.951,6.7h-4L13.888,6.7c-.139-.027-.222-.149-.195-.338l.5-3.19.03-.161a2.707,2.707,0,0,0-4.895-1.87L6.349,5.566C6.027,4.519,5.171,4,3.749,4h-1C.9,4,0,4.869,0,6.652v9.8C0,18.234.9,19.1,2.751,19.1h1a2.4,2.4,0,0,0,2.525-1.368l2.216,1.715.115.1a3.9,3.9,0,0,0,2.244.653h3.8l.188,0a3.75,3.75,0,0,0,3.34-2.664l2.44-7.417A2.612,2.612,0,0,0,17.951,6.7M5,16.452c0,.95-.222,1.15-1.25,1.15h-1c-.981,0-1.227-.2-1.249-1.016V6.652c0-.912.205-1.127,1.1-1.15H3.751C4.779,5.5,5,5.7,5,6.652ZM19.144,9.8l-2.4,7.32-.044.145a2.3,2.3,0,0,1-2.044,1.44H10.706a2.351,2.351,0,0,1-1.225-.376L6.5,16.011V8.031l4.073-6.06.067-.086a1.279,1.279,0,0,1,1.247-.331,1.234,1.234,0,0,1,.831,1.335L12.21,6.136l-.016.147a1.786,1.786,0,0,0-.008.179A1.753,1.753,0,0,0,13.95,8.2h4.139A1.113,1.113,0,0,1,19.144,9.8"
        />
    </Icon>
)

export const BadIcon = (props) => (
    <Icon {...props}>
        <path
            fill="currentColor"
            d="M17.951,13.5h-4l-.063.006c-.139.027-.222.149-.195.338l.5,3.19.03.161a2.707,2.707,0,0,1-4.895,1.87l-2.979-4.43c-.322,1.047-1.178,1.564-2.6,1.564h-1C.9,16.2,0,15.334,0,13.551v-9.8C0,1.969.9,1.1,2.751,1.1h1A2.4,2.4,0,0,1,6.276,2.469L8.492.754l.115-.1A3.9,3.9,0,0,1,10.851,0h3.8l.188,0a3.75,3.75,0,0,1,3.34,2.664l2.44,7.417A2.612,2.612,0,0,1,17.951,13.5M5,3.751C5,2.8,4.777,2.6,3.749,2.6h-1c-.981,0-1.227.2-1.249,1.016v9.934c0,.912.205,1.127,1.1,1.15H3.751C4.779,14.7,5,14.5,5,13.551Zm14.145,6.655-2.4-7.32-.044-.145A2.3,2.3,0,0,0,14.651,1.5H10.706a2.351,2.351,0,0,0-1.225.376L6.5,4.192v7.98l4.073,6.06.067.086a1.279,1.279,0,0,0,1.247.331,1.234,1.234,0,0,0,.831-1.335l-.508-3.247-.016-.147a1.786,1.786,0,0,1-.008-.179A1.753,1.753,0,0,1,13.95,12h4.139a1.113,1.113,0,0,0,1.055-1.593"
        />
    </Icon>
)

export const SearchIcon = (props) => (
    <Icon {...props}>
        <g id="search-normal-2" data-name="search-normal" fill="currentColor">
            <path
                id="Vector"
                d="M24,0V24H0V0Z"
                fill="#currentColor"
                opacity="0"
            />
            <path
                id="Vector-2"
                data-name="Vector"
                d="M10.25,20.5A10.252,10.252,0,0,1,3,3,10.252,10.252,0,0,1,17.5,17.5,10.187,10.187,0,0,1,10.25,20.5Zm0-19A8.749,8.749,0,1,0,19,10.25,8.759,8.759,0,0,0,10.25,1.5Z"
                transform="translate(1.25 1.25)"
                // fill="#fff"
            />
            <path
                id="Vector-3"
                data-name="Vector"
                d="M.22.22A.75.75,0,0,1,1.2.147L1.28.22l2,2A.75.75,0,0,1,2.3,3.353L2.22,3.28l-2-2A.75.75,0,0,1,.22.22Z"
                transform="translate(19.25 19.25)"
                // fill="#fff"
            />
        </g>
    </Icon>
)

export const FreewordFilterIcon = (props) => (
    <Icon {...props}>
        <svg viewBox="0 0 48 48">
            <g>
                <g
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2px"
                >
                    <polyline points="6.33 7 20.01 24 20.01 42 28.01 37 28.01 24 27.99 24 41.99 7 15.01 7" />
                </g>
            </g>
        </svg>
    </Icon>
)

export const DocumentIcon = (props) => (
    <Icon {...props}>
        <g id="document-text-2" data-name="document-text" fill="currentColor">
            <path
                id="Vector"
                d="M24,0V24H0V0Z"
                // fill="#fff"
                opacity="0"
            />
            <path
                id="合体_9"
                data-name="合体 9"
                d="M8658.751-11978a5.759,5.759,0,0,1-4.282-1.526,5.8,5.8,0,0,1-1.47-4.223v-10a5.8,5.8,0,0,1,1.47-4.222,5.755,5.755,0,0,1,4.282-1.527h8a5.76,5.76,0,0,1,4.282,1.527,5.8,5.8,0,0,1,1.47,4.222v10a5.8,5.8,0,0,1-1.47,4.223,5.763,5.763,0,0,1-4.282,1.526Zm-3.21-18.924a4.368,4.368,0,0,0-1.042,3.171v10a4.368,4.368,0,0,0,1.042,3.171,4.34,4.34,0,0,0,3.21,1.078h8a4.342,4.342,0,0,0,3.21-1.078,4.368,4.368,0,0,0,1.042-3.171v-10a4.368,4.368,0,0,0-1.042-3.171,4.342,4.342,0,0,0-3.21-1.078h-8A4.34,4.34,0,0,0,8655.541-11996.923Zm3.21,13.922a.748.748,0,0,1-.75-.75.75.75,0,0,1,.647-.743l.1-.007h8a.751.751,0,0,1,.75.75.751.751,0,0,1-.647.744l-.1.006Zm0-4a.748.748,0,0,1-.75-.75.751.751,0,0,1,.647-.744l.1-.006h4a.751.751,0,0,1,.75.75.754.754,0,0,1-.647.744l-.1.006Zm8.5-4.5a2.756,2.756,0,0,1-2.744-2.584l-.006-.166v-2a.751.751,0,0,1,.75-.75.754.754,0,0,1,.744.65l.006.1v2a1.256,1.256,0,0,0,1.123,1.241l.127.009h2a.748.748,0,0,1,.75.75.752.752,0,0,1-.647.744l-.1.006Z"
                transform="translate(-8650.75 12000.75)"
                // fill="#fff"
                stroke="rgba(0,0,0,0)"
                strokeMiterlimit="10"
                strokeWidth="1"
            />
        </g>
    </Icon>
)

export const UserIcon = (props) => (
    <Icon {...props}>
        <g id="user-2" data-name="user" fill="currentColor">
            <path
                id="Vector"
                d="M24,0V24H0V0Z"
                // fill="#616161"
                opacity="0"
            />
            <path
                id="Vector-2"
                data-name="Vector"
                d="M5.751,11.5A5.75,5.75,0,1,1,11.5,5.75,5.756,5.756,0,0,1,5.751,11.5Zm0-10A4.249,4.249,0,1,0,10,5.75,4.255,4.255,0,0,0,5.751,1.5Z"
                transform="translate(6.25 1.25)"
                // fill="#616161"
            />
            <path
                id="Vector-3"
                data-name="Vector"
                d="M9.34,0c5.127,0,9.34,3.43,9.34,7.75a.75.75,0,0,1-1.5,0c0-3.415-3.481-6.25-7.84-6.25S1.5,4.335,1.5,7.75a.75.75,0,0,1-1.5,0C0,3.43,4.213,0,9.34,0Z"
                transform="translate(2.66 14.25)"
                // fill="#616161"
            />
        </g>
    </Icon>
)

export const ArrorDownIcon = (props) => (
    <Icon {...props}>
        <svg viewBox="0 0 16 16">
            <g id="arror-down" data-name="arror-down" fill="currentColor">
                <path id="Vector" d="M0,0H16V16H0V0Z" fill="rgba(41,45,50,0)" />
                <path
                    fill="currentColor"
                    id="Vector-2"
                    d="M13.6,5.6c.2,.2,.2,.5,0,.7v.1l-4.3,4.3c-.7,.7-1.8,.7-2.5,.1l-.1-.1L2.4,6.4c-.2-.2-.2-.5,0-.7s.4-.2,.6,0h.1l4.3,4.3c.3,.3,.8,.3,1.1,.1l.1-.1,4.3-4.3c.2-.3,.5-.3,.7-.1h0Z"
                />
            </g>
        </svg>
    </Icon>
)

export const BurgerIcon = (props) => (
    <Icon {...props}>
        <svg viewBox="0 0 24 24">
            <g id="burger" data-name="burger" fill="currentColor">
                <path
                    id="Vector"
                    fill="rgba(41,45,50,0)"
                    d="M24,0V24H0V0H24Z"
                />
                <path
                    id="Vector-2"
                    d="M21,5.3c.4,0,.7,.3,.7,.8,0,.4-.3,.7-.6,.7H3c-.4,0-.7-.3-.7-.8,0-.4,.3-.7,.6-.7H21Z"
                />
                <path
                    id="Vector-3"
                    d="M21,11.3c.4,0,.7,.3,.7,.8,0,.4-.3,.7-.6,.7H3c-.4,0-.7-.3-.7-.8,0-.4,.3-.7,.6-.7H21Z"
                />
                <path
                    id="Vector-4"
                    d="M21,17.3c.4,0,.7,.3,.7,.8,0,.4-.3,.7-.6,.7H3c-.4,0-.7-.3-.7-.8,0-.4,.3-.7,.6-.7H21Z"
                />
            </g>
        </svg>
    </Icon>
)

export const AddIcon = (props) => (
    <Icon {...props}>
        <svg viewBox="0 0 16 16">
            <g id="add" data-name="add" fill="currentColor">
                <path
                    id="Vector"
                    d="M12,7.5c.3,0,.5,.2,.5,.5s-.2,.5-.5,.5H3.9c-.3,0-.5-.2-.5-.5s.2-.5,.5-.5H12Z"
                />
                <path
                    id="Vector-2"
                    d="M8,3.5c.3,0,.5,.2,.5,.4V12c0,.3-.2,.5-.5,.5s-.5-.2-.5-.5h0V3.9c0-.1,.2-.4,.5-.4Z"
                />
                <path
                    id="Vector-3"
                    fill="rgba(41,45,50,0)"
                    d="M16,0V16H0V0H16Z"
                />
            </g>
        </svg>
    </Icon>
)

export const CloseIcon = (props) => (
    <Icon {...props}>
        <svg viewBox="0 0 24 24">
            <g id="close" data-name="close" fill="currentColor">
                <circle id="Oval-3" fill="none" cx="12" cy="12" r="12" />
                <circle id="Oval-4" cx="12" cy="12" r="12" />
                <circle id="Oval-5" fill="none" cx="12" cy="12" r="12" />
            </g>
            <g id="close-circle">
                <path id="Vector" fill="none" d="M24,0V24H0V0S24,0,24,0Z" />
                <path
                    id="Vector-2"
                    strokeDasharray="0 0 0 0"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5px"
                    stroke="#fff"
                    d="M9.1,14.9l5.7-5.7"
                />
                <path
                    id="Vector-3"
                    strokeDasharray="0 0 0 0"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5px"
                    stroke="#fff"
                    d="M14.8,14.9l-5.7-5.7"
                />
            </g>
        </svg>
    </Icon>
)

export const CloseLighterIcon = (props) => (
    <Icon {...props}>
        <svg viewBox="0 0 30.78 30.5">
            <g id="close-circle" data-name="close-lighter" fill="currentColor">
                <path
                    id="Vector"
                    fill="none"
                    stroke="rgba(189,189,189,0)"
                    d="M28.2,2V30H.5V2H28.2Z"
                />
            </g>
            <g id="close-circle2">
                <path
                    id="Vector-2"
                    fill="none"
                    d="M30.78,0V30H.78V0S30.78,0,30.78,0Z"
                />
                <path
                    id="Vector-3"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="1.5px"
                    strokeDasharray="0 0 0 0"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12.18,18.6l7.1-7.1"
                />
                <path
                    id="Vector-4"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="1.5px"
                    strokeDasharray="0 0 0 0"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19.28,18.6l-7.1-7.1"
                />
            </g>
            <g>
                <circle
                    id="Oval"
                    fill="none"
                    strokeMiterlimit="10"
                    stroke="currentColor"
                    strokeWidth="1.5px"
                    cx="15.6"
                    cy="15"
                    r="12.5"
                />
            </g>
        </svg>
    </Icon>
)

export const TagIcon = (props) => (
    <Icon {...props}>
        <svg viewBox="0 0 25 25">
            <g id="tag" data-name="tag" fill="currentColor">
                <path
                    id="Vector"
                    fill="none"
                    strokeLinejoin="round"
                    stroke="currentColor"
                    strokeDasharray="0 0 0 0"
                    strokeLinecap="round"
                    strokeWidth="1.5px"
                    d="M9.2,20.4c1.9,1.9,4.9,1.9,6.8,0l4.4-4.4c1.9-1.9,1.9-4.9,0-6.7l-4.5-4.5c-.9-1-2.3-1.5-3.6-1.4l-5,.2c-2,.1-3.6,1.7-3.7,3.7l-.2,5c-.1,1.3,.4,2.7,1.4,3.6l4.4,4.5Z"
                />
                <path
                    id="Vector-2"
                    fill="none"
                    stroke="currentColor"
                    strokeDasharray="0 0 0 0"
                    strokeLinecap="round"
                    strokeWidth="1.5px"
                    d="M10,12.5c-1.4,0-2.5-1.1-2.5-2.5s1.1-2.5,2.5-2.5,2.5,1.1,2.5,2.5-1.1,2.5-2.5,2.5Z"
                />
                <path id="Vector-3" fill="none" d="M24.5,.5V24.5H.5V.5H24.5Z" />
            </g>
        </svg>
    </Icon>
)

export const MapPinIcon = (props) => (
    <Icon {...props}>
        <svg viewBox="0 0 16.4 23.04">
            <g id="map-pin" data-name="map-pin" fill="currentColor">
                <path
                    id="Combined_Shape-2"
                    d="M8.1,22.9q.1,.1,.2,.1c-.1,0-.2-.1-.3-.1-.4-.4-8-8.9-8-14.7C0,3.7,3.6,0,8.2,0s8.2,3.6,8.2,8.2c0,5.9-7.6,14.4-7.9,14.7-.1,.1-.2,.1-.3,.1-.1,.1-.1,0-.1-.1"
                />
                <path
                    id="Path"
                    fill="#fff"
                    d="M8.2,12.5c-2.4,0-4.3-1.9-4.3-4.3s1.9-4.3,4.3-4.3,4.3,1.9,4.3,4.3-1.9,4.3-4.3,4.3h0Z"
                />
            </g>
        </svg>
    </Icon>
)

export const LocationIcon = (props) => (
    <Icon {...props}>
        <svg viewBox="0 0 16 16">
            <g id="location" data-name="location" fill="currentColor">
                <path
                    id="Vector"
                    fill="rgba(41,45,50,0)"
                    d="M0,16V0H16V16H0Z"
                />
                <path
                    id="Vector-2"
                    d="M8,9.5c-1.4,0-2.6-1.2-2.6-2.6s1.2-2.6,2.6-2.6,2.6,1.2,2.6,2.6h0c0,1.4-1.2,2.6-2.6,2.6Zm0-4.2c-.9,0-1.6,.7-1.6,1.6s.7,1.6,1.6,1.6,1.6-.7,1.6-1.6-.7-1.6-1.6-1.6Z"
                />
                <path
                    id="Vector-3"
                    d="M8,15.2c-1,0-2-.4-2.8-1.1-1.2-1.1-2.2-2.5-2.9-4-.6-1.4-.8-3-.4-4.5,.3-1.4,1.1-2.7,2.3-3.5,1.1-.8,2.4-1.2,3.8-1.2s2.7,.4,3.8,1.2c1.2,.9,2,2.1,2.3,3.5,.3,1.5,.2,3.1-.4,4.5-.7,1.5-1.7,2.9-2.9,4-.8,.7-1.8,1.1-2.8,1.1Zm0-13.3c-1.1,0-2.2,.3-3.2,1s-1.7,1.8-1.9,3c-.3,1.3-.2,2.7,.4,3.9,.6,1.4,1.5,2.6,2.6,3.6,1.1,1.1,3,1.1,4.1,0,1.1-1,2-2.3,2.6-3.6,.5-1.2,.7-2.6,.4-3.9v-.2c-.3-1.1-1-2.2-1.9-2.8-.9-.7-2-1-3.1-1Z"
                />
            </g>
        </svg>
    </Icon>
)

export const FlagIcon = (props) => (
    <Icon {...props}>
        <svg viewBox="0 0 24 24">
            <g id="flag" data-name="flag" fill="currentColor">
                <path
                    id="Vector"
                    fill="rgba(41,45,50,0)"
                    d="M24,0V24H0V0H24Z"
                />
                <path
                    id="Vector-2"
                    d="M5.2,1.3c.4,0,.7,.3,.7,.6V22c0,.4-.3,.7-.8,.7-.4,0-.7-.3-.7-.6V2c0-.4,.4-.7,.8-.7Z"
                />
                <path
                    id="Vector-3"
                    d="M16.4,3.3c3.3,0,4.3,2.2,2.1,4.5l-.1,.1-1.2,1.2c-.5,.4-.5,1.2-.1,1.6l1.3,1.3c2.3,2.3,1.4,4.6-1.7,4.7H5.3c-.4,0-.7-.3-.7-.8,0-.4,.3-.7,.6-.7h11.3c1.9,0,2.3-.7,1-2l-1.3-1.3c-1.1-1-1.1-2.7-.2-3.8l.1-.1,1.2-1.2c1.4-1.4,1.2-2.1-.7-2.1H5.2c-.4,0-.7-.3-.7-.8,0-.4,.3-.7,.6-.7h11.3v.1Z"
                />
            </g>
        </svg>
    </Icon>
)

export const YenSquareIcon = (props) => (
    <Icon {...props}>
        <svg viewBox="0 0 16 16">
            <g id="yen-square" data-name="yen-square" fill="currentColor">
                <path id="Vector" d="M16,0V16H0V0Z" opacity="0" />
                <path
                    id="Vector-2"
                    data-name="Vector"
                    d="M9.168,14.332h-4A5.349,5.349,0,0,1,1.23,13.1,5.344,5.344,0,0,1,0,9.167v-4A5.341,5.341,0,0,1,1.23,1.23,5.341,5.341,0,0,1,5.168,0h4A5.336,5.336,0,0,1,13.1,1.23a5.345,5.345,0,0,1,1.229,3.938v4A5.341,5.341,0,0,1,13.1,13.1,5.343,5.343,0,0,1,9.168,14.332ZM9.168,1,4.989,1a4.151,4.151,0,0,0-3.09.974A4.459,4.459,0,0,0,1,5.168L1,9.344a4.158,4.158,0,0,0,.975,3.091,4.462,4.462,0,0,0,3.191.9h4A4.393,4.393,0,0,0,12.4,12.4a4.393,4.393,0,0,0,.936-3.23v-4A4.395,4.395,0,0,0,12.4,1.936,4.385,4.385,0,0,0,9.168,1Z"
                    transform="translate(0.833 0.833)"
                />
                <path
                    id="_"
                    data-name="¥"
                    d="M3.25,7.615V6.25H4.823V5.536H3.25V4.714H4.823V4H3.573L5.578,0H4.583l-1.8,3.849L1,0H0L1.974,4H.755v.714H2.323v.823H.755V6.25H2.323V7.615Z"
                    transform="translate(5.206 4.385)"
                />
            </g>
        </svg>
    </Icon>
)

export const HospitalIcon = (props) => (
    <Icon {...props}>
        <svg id="hospital" viewBox="0 0 24 24">
            <g id="hospital" data-name="hospital" fill="currentColor">
                <path id="Vector" d="M24,0V24H0V0Z" opacity="0" />
                <path
                    id="hospital-2"
                    data-name="hospital-2"
                    d="M8672.749-12722h-19a.75.75,0,0,1-.748-.752.75.75,0,0,1,.649-.741l.1-.007H8654v-15.252a4.825,4.825,0,0,1,1.187-3.467,4.734,4.734,0,0,1,3.563-1.282h10a4.737,4.737,0,0,1,3.563,1.282,4.825,4.825,0,0,1,1.187,3.467v15.252h.249a.753.753,0,0,1,.752.748.756.756,0,0,1-.649.746l-.1.006Zm-.748-1.5v-15.252c0-2.143-.963-3.173-3.026-3.246l-.224,0h-10c-2.219,0-3.249,1.032-3.249,3.249v15.252h4.49v-5.312a1.691,1.691,0,0,1,1.688-1.688h4.133a1.682,1.682,0,0,1,1.2.5,1.638,1.638,0,0,1,.477,1.187v5.313Zm-6.01,0v-5.321a.172.172,0,0,0-.121-.17l-.058-.01h-4.133a.2.2,0,0,0-.188.188l0,5.313Zm-2.984-10.147-.006-.1v-1.749h-1.75a.751.751,0,0,1-.751-.752.751.751,0,0,1,.649-.742l.1-.006H8663v-1.75a.749.749,0,0,1,.748-.751.751.751,0,0,1,.745.648l.007.1v1.75h1.749a.749.749,0,0,1,.749.748.752.752,0,0,1-.646.745l-.1.007H8664.5v1.749a.754.754,0,0,1-.752.752A.751.751,0,0,1,8663.007-12733.646Z"
                    transform="translate(-8651.75 12744.749)"
                    fill="currentColor"
                    stroke="rgba(0,0,0,0)"
                    strokeMiterlimit="10"
                    strokeWidth="1"
                />
            </g>
        </svg>
    </Icon>
)

export const MapIcon = (props) => (
    <Icon {...props}>
        <svg id="map" viewBox="0 0 24 24">
            <g id="map" data-name="map" fill="currentColor">
                <path
                    id="Vector"
                    d="M24,0V24H0V0Z"
                    transform="translate(24 24) rotate(180)"
                    opacity="0"
                />
                <path
                    id="Vector-2"
                    data-name="Vector"
                    d="M14.121,18.834a2.837,2.837,0,0,1-1.226-.261L7.641,15.942a1.271,1.271,0,0,0-.545-.105,1.369,1.369,0,0,0-.636.137L4.113,17.314a3.46,3.46,0,0,1-1.692.5,2.252,2.252,0,0,1-1.73-.771A3.179,3.179,0,0,1,0,14.922V5.191A2.952,2.952,0,0,1,1.39,2.813L5.72.33A2.814,2.814,0,0,1,7.091,0,2.83,2.83,0,0,1,8.316.262l5.255,2.624a1.242,1.242,0,0,0,.536.1,1.381,1.381,0,0,0,.644-.139L17.1,1.513a3.463,3.463,0,0,1,1.693-.5c1.447,0,2.42,1.16,2.42,2.886v9.729a2.982,2.982,0,0,1-1.39,2.394L15.491,18.5A2.8,2.8,0,0,1,14.121,18.834Zm-7.016-4.5a2.735,2.735,0,0,1,1.211.264l5.244,2.628a1.29,1.29,0,0,0,.555.106,1.328,1.328,0,0,0,.631-.134l4.327-2.476a1.507,1.507,0,0,0,.639-1.094V3.9c0-.9-.331-1.392-.933-1.392a1.955,1.955,0,0,0-.937.3L15.491,4.154a2.833,2.833,0,0,1-1.381.335A2.726,2.726,0,0,1,12.9,4.223L7.651,1.606A1.34,1.34,0,0,0,7.093,1.5a1.338,1.338,0,0,0-.628.134L2.135,4.112A1.5,1.5,0,0,0,1.5,5.191v9.73c0,.9.33,1.394.93,1.394a1.951,1.951,0,0,0,.939-.3L5.72,14.67A2.865,2.865,0,0,1,7.105,14.337Z"
                    transform="translate(1.539 2.587)"
                />
                <path
                    id="Vector-3"
                    data-name="Vector"
                    d="M.75,0a.75.75,0,0,1,.743.648L1.5.75v13a.75.75,0,0,1-1.493.1L0,13.75V.75A.75.75,0,0,1,.75,0Z"
                    transform="translate(7.81 3.25)"
                />
                <path
                    id="Vector-4"
                    data-name="Vector"
                    d="M.75,0a.75.75,0,0,1,.743.648L1.5.75V14.13a.75.75,0,0,1-1.493.1L0,14.13V.75A.75.75,0,0,1,.75,0Z"
                    transform="translate(14.98 5.87)"
                />
            </g>
        </svg>
    </Icon>
)

export const ArrowRightIcon = (props) => (
    <Icon {...props}>
        <svg id="arrow-right" viewBox="0 0 24 24">
            <g id="arrow-right-2" data-name="arrow-right" fill="currentColor">
                <path
                    id="Vector"
                    d="M24,0V24H0V0Z"
                    transform="translate(24 24) rotate(180)"
                    opacity="0"
                />
                <path
                    id="Vector-2"
                    data-name="Vector"
                    d="M.22.22A.75.75,0,0,1,1.2.147L1.28.22,7.8,6.74a2.736,2.736,0,0,1,.127,3.724L7.8,10.6,1.28,17.12a.75.75,0,0,1-1.133-.977L.22,16.06,6.74,9.54A1.237,1.237,0,0,0,6.829,7.9L6.74,7.8.22,1.28A.75.75,0,0,1,.22.22Z"
                    transform="translate(8.16 3.33)"
                />
            </g>
        </svg>
    </Icon>
)

export const EditIcon = (props) => (
    <Icon {...props}>
        <svg id="edit" viewBox="0 0 24 24">
            <g id="edit-2" data-name="edit-2" fill="currentColor">
                <path id="Vector" d="M24,0V24H0V0Z" opacity="0" />
                <path
                    id="Vector-2"
                    data-name="Vector"
                    d="M2.282,18.111a2.244,2.244,0,0,1-1.705-.732,2.407,2.407,0,0,1-.559-1.905l.372-3.255a3.763,3.763,0,0,1,.866-1.842L9.468,1.683A4.551,4.551,0,0,1,12.694,0a4.668,4.668,0,0,1,3.124,1.494A4.559,4.559,0,0,1,17.5,4.709,4.661,4.661,0,0,1,16,7.855l-8.2,8.68a3.629,3.629,0,0,1-1.816.984l-3.221.55A2.7,2.7,0,0,1,2.282,18.111ZM12.69,1.5a3.08,3.08,0,0,0-2.133,1.208l-8.21,8.692a2.336,2.336,0,0,0-.47,1l-.369,3.238a.918.918,0,0,0,.183.733.767.767,0,0,0,.591.236,1.457,1.457,0,0,0,.224-.022l3.217-.55a2.129,2.129,0,0,0,.976-.527l8.21-8.688A3.176,3.176,0,0,0,16,4.717a3.1,3.1,0,0,0-1.214-2.131A3.181,3.181,0,0,0,12.69,1.5Z"
                    transform="translate(3.247 1.401)"
                />
                <path
                    id="Vector-3"
                    data-name="Vector"
                    d="M.635.009a.75.75,0,0,1,.857.626A5.376,5.376,0,0,0,6.275,5.154a.75.75,0,0,1-.15,1.492A6.876,6.876,0,0,1,.009.866.75.75,0,0,1,.635.009Z"
                    transform="translate(11.14 4.3)"
                />
                <path
                    id="Vector-4"
                    data-name="Vector"
                    d="M18.75,0a.75.75,0,0,1,.1,1.493l-.1.007H.75A.75.75,0,0,1,.648.007L.75,0Z"
                    transform="translate(2.25 21.25)"
                />
            </g>
        </svg>
    </Icon>
)

export const WindowLinkIcon = (props) => (
    <Icon {...props}>
        <svg id="window_link" viewBox="0 0 19.4 19.5">
            <g id="window_link1" data-name="window_link1" fill="currentColor">
                <path
                    id="window_link2"
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="3px"
                    d="M11.2,1.5h6.7v6.7"
                />
                <path
                    id="window_link3"
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="3px"
                    d="M14.9,12v6H1.5V4.5H7.5"
                />
                <line
                    id="window_link4"
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="3px"
                    x1="7.8"
                    y1="11.6"
                    x2="17.8"
                    y2="1.6"
                />
            </g>
        </svg>
    </Icon>
)

export const RectangleIcon = (props) => (
    <Icon {...props}>
        <svg id="rectangle" viewBox="0 0 42 42">
            <filter
                id="Rectangle"
                x="0"
                y="0"
                width="42"
                height="42"
                filterUnits="userSpaceOnUse"
            >
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="2" result="blur" />
                <feFlood floodColor="#90a4ae" floodOpacity="0.196" />
                <feComposite operator="in" in2="blur" />
            </filter>
            <filter
                id="Rectangle-2"
                x="3"
                y="1"
                width="36"
                height="36"
                filterUnits="userSpaceOnUse"
            >
                <feOffset />
                <feGaussianBlur stdDeviation="1" result="blur-2" />
                <feFlood floodColor="#90a4ae" floodOpacity="0.196" />
                <feComposite operator="in" in2="blur-2" />
                <feComposite in="SourceGraphic" />
            </filter>
            <g
                id="lv2_btn_circle"
                data-name="lv2/btn/circle"
                transform="translate(6 4)"
            >
                <g id="Rectangle-3" data-name="Rectangle" fill="currentColor">
                    <g
                        transform="matrix(1, 0, 0, 1, -6, -4)"
                        filter="url(#Rectangle)"
                    >
                        <rect
                            id="Rectangle-4"
                            data-name="Rectangle"
                            width="30"
                            height="30"
                            rx="15"
                            transform="translate(6 4)"
                            fill="#fff"
                        />
                    </g>
                    <g
                        transform="matrix(1, 0, 0, 1, -6, -4)"
                        filter="url(#Rectangle-2)"
                    >
                        <rect
                            id="Rectangle-5"
                            data-name="Rectangle"
                            width="30"
                            height="30"
                            rx="15"
                            transform="translate(6 4)"
                            fill="#fff"
                        />
                    </g>
                    <rect
                        id="Rectangle-6"
                        data-name="Rectangle"
                        width="30"
                        height="30"
                        rx="15"
                        fill="none"
                    />
                </g>
                <g
                    id="lv1_icon_arrow-right"
                    data-name="lv1/icon/arrow-right"
                    transform="translate(7 7)"
                >
                    <g id="setting-4">
                        <path
                            id="Vector"
                            d="M16,0V16H0V0Z"
                            fill="currentColor"
                            opacity="0"
                        />
                        <path
                            id="Vector-2"
                            data-name="Vector"
                            d="M4.5,0a.5.5,0,0,1,.068,1L4.5,1H.5A.5.5,0,0,1,.432,0L.5,0Z"
                            transform="translate(10.167 3.833)"
                            fill="currentColor"
                        />
                        <path
                            id="Vector-3"
                            data-name="Vector"
                            d="M3.167,0a.5.5,0,0,1,.068,1L3.167,1H.5A.5.5,0,0,1,.432,0L.5,0Z"
                            transform="translate(0.833 3.833)"
                            fill="currentColor"
                        />
                        <path
                            id="Vector-4"
                            data-name="Vector"
                            d="M2.835,5.666A2.833,2.833,0,1,1,5.669,2.835,2.836,2.836,0,0,1,2.835,5.666ZM2.835,1A1.833,1.833,0,1,0,4.669,2.835,1.837,1.837,0,0,0,2.835,1Z"
                            transform="translate(3.832 1.499)"
                            fill="currentColor"
                        />
                        <path
                            id="Vector-5"
                            data-name="Vector"
                            d="M3.167,0a.5.5,0,0,1,.068,1L3.167,1H.5A.5.5,0,0,1,.432,0L.5,0Z"
                            transform="translate(11.5 11.167)"
                            fill="currentColor"
                        />
                        <path
                            id="Vector-6"
                            data-name="Vector"
                            d="M4.5,0a.5.5,0,0,1,.068,1L4.5,1H.5A.5.5,0,0,1,.432,0L.5,0Z"
                            transform="translate(0.833 11.167)"
                            fill="currentColor"
                        />
                        <path
                            id="Vector-7"
                            data-name="Vector"
                            d="M2.831,5.667A2.834,2.834,0,1,1,5.665,2.833,2.836,2.836,0,0,1,2.831,5.667ZM2.831,1A1.833,1.833,0,1,0,4.667,2.833,1.835,1.835,0,0,0,2.831,1Z"
                            transform="translate(6.501 8.833)"
                            fill="currentColor"
                        />
                    </g>
                </g>
            </g>
        </svg>
    </Icon>
)

export const DashboardIcon = (props) => (
    <Icon {...props}>
        <svg id="monitor_dashboard_icon" viewBox="0 0 24 24">
            <g
                id="monitor_dashboard_icon_1"
                data-name="monitor_dashboard_icon_1"
                fill="currentColor"
            >
                <path
                    id="monitor_dashboard_icon_2"
                    data-name="monitor_dashboard_icon_2"
                    d="M24,17.2h0V2a2.006,2.006,0,0,0-2-2H2A2.006,2.006,0,0,0,0,2V18.2a2.006,2.006,0,0,0,2,2H8.6v2.4H5.8a.7.7,0,1,0,0,1.4H17.9a.7.7,0,0,0,0-1.4H15.4V20.2H22a2.006,2.006,0,0,0,2-2v-1ZM2,1.4H22a.645.645,0,0,1,.6.6V16.5H1.4V2A.645.645,0,0,1,2,1.4ZM14,22.6H10V20.2h4v2.4Zm8-3.8H2a.645.645,0,0,1-.6-.6v-.3H22.6v.3A.645.645,0,0,1,22,18.8Z"
                    fill="currentColor"
                />
                <path
                    id="monitor_dashboard_icon_3"
                    data-name="monitor_dashboard_icon_3"
                    d="M12.6,14.7h8.2a.5.5,0,0,0,0-1H12.6a.5.5,0,1,0,0,1Z"
                    fill="currentColor"
                />
            </g>
            <path
                id="monitor_dashboard_icon_4"
                data-name="monitor_dashboard_icon_4"
                d="M16.7,4A4.7,4.7,0,0,0,12,8.7a4.65,4.65,0,0,0,9.3,0A4.673,4.673,0,0,0,16.7,4ZM16,5.1l.2,2.7L13.9,6.5A3.447,3.447,0,0,1,16,5.1Zm.7,7.2A3.671,3.671,0,0,1,13,8.7a3.194,3.194,0,0,1,.3-1.4l3.1,1.8c.1,0,.2.1.3.1a.367.367,0,0,0,.3-.1c.2-.1.2-.3.2-.5L17,5.1a3.614,3.614,0,0,1,3.4,3.6A3.738,3.738,0,0,1,16.7,12.3Z"
                transform="translate(0 -0.5)"
                fill="currentColor"
            />
            <g
                id="monitor_dashboard_icon_5"
                data-name="monitor_dashboard_icon_5"
            >
                <g
                    id="monitor_dashboard_icon_6"
                    data-name="monitor_dashboard_icon_6"
                >
                    <path
                        id="monitor_dashboard_icon_7"
                        data-name="monitor_dashboard_icon_7"
                        d="M4.2,10.5v3.2H3.5V10.5h.7m.4-1H3.1a.645.645,0,0,0-.6.6v4a.645.645,0,0,0,.6.6H4.5a.645.645,0,0,0,.6-.6v-4a.5.5,0,0,0-.5-.6Z"
                        fill="currentColor"
                    />
                </g>
                <path
                    id="monitor_dashboard_icon_8"
                    data-name="monitor_dashboard_icon_8"
                    d="M4.1,13.9H3.6a.215.215,0,0,1-.2-.2V10.6a.215.215,0,0,1,.2-.2h.5a.215.215,0,0,1,.2.2v3.1A.215.215,0,0,1,4.1,13.9Z"
                    fill="currentColor"
                />
            </g>
            <g
                id="monitor_dashboard_icon_9"
                data-name="monitor_dashboard_icon_9"
            >
                <g
                    id="monitor_dashboard_icon_10"
                    data-name="monitor_dashboard_icon_10"
                >
                    <path
                        id="monitor_dashboard_icon_11"
                        data-name="monitor_dashboard_icon_11"
                        d="M7.2,7.3v6.4H6.5V7.3h.7m.3-1H6.1a.669.669,0,0,0-.6.7v7.1a.645.645,0,0,0,.6.6H7.5a.645.645,0,0,0,.6-.6V7a.563.563,0,0,0-.6-.7Z"
                        fill="currentColor"
                    />
                </g>
                <path
                    id="monitor_dashboard_icon_12"
                    data-name="monitor_dashboard_icon_12"
                    d="M7.1,13.9H6.6a.215.215,0,0,1-.2-.2V7.4a.215.215,0,0,1,.2-.2h.5a.215.215,0,0,1,.2.2v6.3A.215.215,0,0,1,7.1,13.9Z"
                    fill="currentColor"
                />
            </g>
            <g
                id="monitor_dashboard_icon_13"
                data-name="monitor_dashboard_icon_13"
            >
                <g
                    id="monitor_dashboard_icon_14"
                    data-name="monitor_dashboard_icon_14"
                >
                    <path
                        id="monitor_dashboard_icon_15"
                        data-name="monitor_dashboard_icon_15"
                        d="M10.2,4.5v9.2H9.5V4.5h.7m.3-1H9.1a.645.645,0,0,0-.6.6v10a.645.645,0,0,0,.6.6h1.4a.645.645,0,0,0,.6-.6V4.1c.1-.3-.2-.6-.6-.6Z"
                        fill="currentColor"
                    />
                </g>
                <path
                    id="monitor_dashboard_icon_16"
                    data-name="monitor_dashboard_icon_16"
                    d="M10.1,13.9H9.6a.215.215,0,0,1-.2-.2V4.5a.215.215,0,0,1,.2-.2h.5a.215.215,0,0,1,.2.2v9.1C10.3,13.8,10.2,13.9,10.1,13.9Z"
                    fill="currentColor"
                />
            </g>
        </svg>
    </Icon>
)

export const HouseIcon = (props) => (
    <Icon {...props}>
        <svg id="house" viewBox="0 0 24 24">
            <g id="house-2" data-name="house" fill="currentColor">
                <path
                    id="Vector"
                    d="M24,0V24H0V0Z"
                    fill="currentColor"
                    opacity="0"
                />
                <path
                    id="house-3"
                    data-name="house-4"
                    d="M8666.252-12846.529h-12.5a.751.751,0,0,1-.753-.749.755.755,0,0,1,.649-.746l.1-.007h.2l.045-11.28a2.761,2.761,0,0,1,.911-2.034l.148-.126,7-5.451a2.761,2.761,0,0,1,3.22-.116l.161.116,1.147.892h4.133a.749.749,0,0,1,.739.643l.01.1.029,3c0,.024,0,.048,0,.071l.942.733a2.742,2.742,0,0,1,1.053,1.979l.007.193v11.28h.252a.751.751,0,0,1,.749.753.751.751,0,0,1-.648.742l-.1.007Zm5.749-1.5v-11.28a1.246,1.246,0,0,0-.372-.892l-.109-.093-5.572-4.333a.764.764,0,0,1-.166-.129l-1.257-.977a1.262,1.262,0,0,0-1.427-.084l-.116.08-7,5.445a1.274,1.274,0,0,0-.478.846l-.01.139-.046,11.277h5.045v-2.751a2.252,2.252,0,0,1,2.251-2.248h2a2.249,2.249,0,0,1,2.247,2.248v2.751Zm-6.5,0v-2.751a.746.746,0,0,0-.649-.743l-.1-.007h-2a.755.755,0,0,0-.75.75v2.751Zm4.489-15.358-.01-1.141h-1.458Zm-3.74,8.609a1.749,1.749,0,0,1-1.747-1.751v-1.5a1.752,1.752,0,0,1,1.747-1.754h2a1.758,1.758,0,0,1,1.754,1.754v1.5a1.755,1.755,0,0,1-1.754,1.751Zm-.248-3.249v1.5a.251.251,0,0,0,.248.249h2a.255.255,0,0,0,.255-.249v-1.5a.255.255,0,0,0-.255-.249h-2A.251.251,0,0,0,8666-12858.029Zm-6.753,3.249a1.751,1.751,0,0,1-1.747-1.751v-1.5a1.754,1.754,0,0,1,1.747-1.754h2a1.754,1.754,0,0,1,1.75,1.754v1.5a1.752,1.752,0,0,1-1.75,1.751Zm-.249-3.249v1.5a.253.253,0,0,0,.249.249h2a.254.254,0,0,0,.252-.249v-1.5a.254.254,0,0,0-.252-.249h-2A.253.253,0,0,0,8659-12858.029Z"
                    transform="translate(-8651.75 12869.28)"
                    fill="currentColor"
                    stroke="rgba(0,0,0,0)"
                    strokeMiterlimit="10"
                    strokeWidth="1"
                />
            </g>
        </svg>
    </Icon>
)

export const RefreshIcon = (props) => (
    <Icon {...props}>
        <svg id="refresh" width="24" height="24" viewBox="0 0 24 24">
            <g id="refresh-2" data-name="refresh">
                <path
                    id="Vector"
                    d="M24,0V24H0V0Z"
                    transform="translate(24 24) rotate(180)"
                    fill="currentColor"
                    opacity="0"
                />
                <path
                    id="Vector-2"
                    data-name="Vector"
                    d="M10.75,0a10.75,10.75,0,0,1,2.741,21.145.75.75,0,0,1-.382-1.451A9.25,9.25,0,0,0,10.75,1.5,10.109,10.109,0,0,0,2.156,5.559H5.19a.75.75,0,0,1,.1,1.493l-.1.007H.795A.773.773,0,0,1,.66,7.055l.09.005-.065,0L.577,7.04.489,7.013l-.049-.02a.725.725,0,0,1-.434-.578L0,6.31V1.75a.75.75,0,0,1,1.493-.1l.007.1V3.987L1.575,3.9a11.553,11.553,0,0,1,8.8-3.9Z"
                    transform="translate(1.25 1.25)"
                    fill="currentColor"
                />
                <path
                    id="Vector-3"
                    data-name="Vector"
                    d="M10.121,11.482a10.72,10.72,0,0,1-3.155-.668.75.75,0,1,1,.528-1.4,9.184,9.184,0,0,0,2.713.574.75.75,0,0,1-.043,1.5ZM4.157,9.239A10.815,10.815,0,0,1,1.936,6.9a.75.75,0,0,1,1.229-.86A9.308,9.308,0,0,0,5.078,8.056a.75.75,0,1,1-.921,1.183ZM.481,3.939A10.75,10.75,0,0,1,0,.75a.75.75,0,0,1,1.5,0,9.231,9.231,0,0,0,.414,2.744.75.75,0,0,1-1.433.445Z"
                    transform="translate(1.25 11.25)"
                    fill="currentColor"
                />
            </g>
        </svg>
    </Icon>
)

export const LinkCopyIcon = (props) => (
    <Icon {...props} viewBox="0 0 30 30">
        <svg width="30" height="30" viewBox="0 0 30 30">
            <g
                id="グループ_1337"
                data-name="グループ 1337"
                transform="translate(3 3)"
                fill="currentColor"
            >
                <g
                    id="グループ_1335"
                    data-name="グループ 1335"
                    clipPath="url(#clip-path)"
                >
                    <path
                        id="パス_1435"
                        data-name="パス 1435"
                        d="M47.18,5.859s2.452.093,2.688.4l3.463-3.485a3.73,3.73,0,0,1,4.772.257,3.5,3.5,0,0,1,1.029,3.929s-.257,1.216-3.532,4l-1.871,1.965a3.593,3.593,0,0,1-3.861.538l-1.235-.65a1.616,1.616,0,0,0-2.317.1s-.274.3,1.076,1.372c0,0,1.335,1.752,4.993,1.49a1.216,1.216,0,0,0,.335-.074,10.2,10.2,0,0,0,3.4-2.193l4.305-4.427a6.279,6.279,0,0,0,.406-5.687A5.858,5.858,0,0,0,56.1,0s-3.209-.1-4.915,1.889Z"
                        transform="translate(-37.336 0)"
                        fill="#3bb37a"
                    />
                    <path
                        id="パス_1436"
                        data-name="パス 1436"
                        d="M11.625,51.33a4.941,4.941,0,0,0,2.618.325l-4.322,4.4a6.226,6.226,0,0,1-5.763,1.379,5.743,5.743,0,0,1-2.843-9.177l5.317-5.342a5.934,5.934,0,0,1,5.747-.742,9.116,9.116,0,0,1,2.571,2.015.624.624,0,0,1-.19.892,1.884,1.884,0,0,1-2.158-.366s-2.375-1.826-4.59-.2L3,49.4a2.615,2.615,0,0,0-.585.85,3.358,3.358,0,0,0,.848,4.148,3.614,3.614,0,0,0,4.294.765,2.016,2.016,0,0,0,.567-.395Z"
                        transform="translate(0 -33.665)"
                        fill="#3bb37a"
                    />
                </g>
            </g>
        </svg>
    </Icon>
)

export const CommuteTimeIcon = (props) => (
    // <Icon {...props}>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="80"
        height="18"
        viewBox="0 0 80 18"
    >
        <g id="グループ_988" data-name="グループ 988" transform="translate(2)">
            <g id="fill" transform="translate(-2)">
                <g
                    id="グループ_977"
                    data-name="グループ 977"
                    transform="translate(1)"
                >
                    <rect
                        id="Rectangle"
                        width="80"
                        height="18"
                        rx="4"
                        transform="translate(-1)"
                        fill="#90a4ae"
                    />
                    <g
                        id="常勤_日勤のみ_2"
                        data-name="常勤（日勤のみ） 2"
                        transform="translate(20 4)"
                    >
                        <text
                            id="常勤_日勤のみ_"
                            data-name="常勤（日勤のみ）"
                            transform="translate(-1 9)"
                            fill="#fff"
                            fontSize="10"
                            fontFamily="HiraginoSans-W6, Hiragino Sans"
                        >
                            <tspan x="0" y="0">
                                移動時間
                            </tspan>
                        </text>
                    </g>
                </g>
            </g>
        </g>
    </svg>

    // </Icon>
)

export const ServiceTypeIcon = (props) => (
    // <Icon {...props}>
    <svg
        id="グループ_1510"
        data-name="グループ 1510"
        xmlns="http://www.w3.org/2000/svg"
        width="80"
        height="18"
        viewBox="0 0 80 18"
    >
        <g id="fill">
            <g id="グループ_977" data-name="グループ 977">
                <rect
                    id="Rectangle"
                    width="80"
                    height="18"
                    rx="4"
                    fill="#90a4ae"
                />
                <g
                    id="常勤_日勤のみ_2"
                    data-name="常勤（日勤のみ） 2"
                    transform="translate(20 4)"
                >
                    <text
                        id="常勤_日勤のみ_"
                        data-name="常勤（日勤のみ）"
                        transform="translate(0 9)"
                        fill="#fff"
                        fontSize="10"
                        fontFamily="HiraginoSans-W6, Hiragino Sans"
                    >
                        <tspan x="0" y="0">
                            施設形態
                        </tspan>
                    </text>
                </g>
            </g>
        </g>
    </svg>

    // </Icon>
)

export const ClinicalDepartmentIcon = (props) => (
    // <Icon {...props}>
    <svg
        id="グループ_1510"
        data-name="グループ 1510"
        xmlns="http://www.w3.org/2000/svg"
        width="80"
        height="18"
        viewBox="0 0 80 18"
    >
        <g id="fill">
            <g id="グループ_977" data-name="グループ 977">
                <rect
                    id="Rectangle"
                    width="80"
                    height="18"
                    rx="4"
                    fill="#90a4ae"
                />
                <g
                    id="常勤_日勤のみ_2"
                    data-name="常勤（日勤のみ） 2"
                    transform="translate(20 4)"
                >
                    <text
                        id="常勤_日勤のみ_"
                        data-name="常勤（日勤のみ）"
                        transform="translate(0 9)"
                        fill="#fff"
                        fontSize="10"
                        fontFamily="HiraginoSans-W6, Hiragino Sans"
                    >
                        <tspan x="0" y="0">
                            診療科目
                        </tspan>
                    </text>
                </g>
            </g>
        </g>
    </svg>

    // </Icon>
)

export const TotalNumberOfBedsIcon = (props) => (
    // <Icon {...props}>
    <svg
        id="グループ_1510"
        data-name="グループ 1510"
        xmlns="http://www.w3.org/2000/svg"
        width="80"
        height="18"
        viewBox="0 0 80 18"
    >
        <g id="fill">
            <g id="グループ_977" data-name="グループ 977">
                <rect
                    id="Rectangle"
                    width="80"
                    height="18"
                    rx="4"
                    fill="#90a4ae"
                />
                <g
                    id="常勤_日勤のみ_2"
                    data-name="常勤（日勤のみ） 2"
                    transform="translate(20 4)"
                >
                    <text
                        id="常勤_日勤のみ_"
                        data-name="常勤（日勤のみ）"
                        transform="translate(0 9)"
                        fill="#fff"
                        fontSize="10"
                        fontFamily="HiraginoSans-W6, Hiragino Sans"
                    >
                        <tspan x="0" y="0">
                            総病床数
                        </tspan>
                    </text>
                </g>
            </g>
        </g>
    </svg>

    // </Icon>
)

export const OfficeCapacityIcon = (props) => (
    // <Icon {...props}>

    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="80"
        height="19"
        viewBox="0 0 80 19"
    >
        <g
            id="グループ_1511"
            data-name="グループ 1511"
            transform="translate(-5)"
        >
            <g id="fill" transform="translate(5)">
                <g
                    id="グループ_977"
                    data-name="グループ 977"
                    transform="translate(-4.5)"
                >
                    <rect
                        id="Rectangle"
                        width="80"
                        height="19"
                        rx="4"
                        transform="translate(4.5)"
                        fill="#90a4ae"
                    />
                    <g
                        id="常勤_日勤のみ_2"
                        data-name="常勤（日勤のみ） 2"
                        transform="translate(10 4)"
                    >
                        <text
                            id="常勤_日勤のみ_"
                            data-name="常勤（日勤のみ）"
                            transform="translate(0.5 9)"
                            fill="#fff"
                            fontSize="10"
                            fontFamily="HiraginoSans-W6, Hiragino Sans"
                        >
                            <tspan x="0" y="0">
                                定員/利用者数
                            </tspan>
                        </text>
                    </g>
                </g>
            </g>
        </g>
    </svg>

    // </Icon>
)

export const OfficeEmployeeCountIcon = (props) => (
    // <Icon {...props}>

    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="80"
        height="18"
        viewBox="0 0 80 18"
    >
        <g
            id="グループ_1512"
            data-name="グループ 1512"
            transform="translate(-5)"
        >
            <g id="fill" transform="translate(5)">
                <g
                    id="グループ_977"
                    data-name="グループ 977"
                    transform="translate(-4.5)"
                >
                    <rect
                        id="Rectangle"
                        width="80"
                        height="18"
                        rx="4"
                        transform="translate(4.5)"
                        fill="#90a4ae"
                    />
                    <g
                        id="常勤_日勤のみ_2"
                        data-name="常勤（日勤のみ） 2"
                        transform="translate(24 4)"
                    >
                        <text
                            id="常勤_日勤のみ_"
                            data-name="常勤（日勤のみ）"
                            transform="translate(0.5 9)"
                            fill="#fff"
                            fontSize="10"
                            fontFamily="HiraginoSans-W6, Hiragino Sans"
                        >
                            <tspan x="0" y="0">
                                従業員数
                            </tspan>
                        </text>
                    </g>
                </g>
            </g>
        </g>
    </svg>

    // </Icon>
)

export const BusinessHoursIcon = (props) => (
    // <Icon {...props}>

    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="80"
        height="18"
        viewBox="0 0 80 18"
    >
        <g
            id="グループ_1513"
            data-name="グループ 1513"
            transform="translate(-18)"
        >
            <g id="fill" transform="translate(18)">
                <g
                    id="グループ_977"
                    data-name="グループ 977"
                    transform="translate(-17.5)"
                >
                    <rect
                        id="Rectangle"
                        width="80"
                        height="18"
                        rx="4"
                        transform="translate(17.5)"
                        fill="#90a4ae"
                    />
                    <g
                        id="常勤_日勤のみ_2"
                        data-name="常勤（日勤のみ） 2"
                        transform="translate(25 4)"
                    >
                        <text
                            id="常勤_日勤のみ_"
                            data-name="常勤（日勤のみ）"
                            transform="translate(12.5 9)"
                            fill="#fff"
                            fontSize="10"
                            fontFamily="HiraginoSans-W6, Hiragino Sans"
                        >
                            <tspan x="0" y="0">
                                営業時間
                            </tspan>
                        </text>
                    </g>
                </g>
            </g>
        </g>
    </svg>

    // </Icon>
)

export const OffersIcon = (props) => (
    // <Icon {...props}>

    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="80"
        height="18"
        viewBox="0 0 80 18"
    >
        <g
            id="グループ_1514"
            data-name="グループ 1514"
            transform="translate(-5 2)"
        >
            <g id="fill" transform="translate(5 -2)">
                <g
                    id="グループ_977"
                    data-name="グループ 977"
                    transform="translate(-4.5 2)"
                >
                    <rect
                        id="Rectangle"
                        width="80"
                        height="18"
                        rx="4"
                        transform="translate(4.5 -2)"
                        fill="#90a4ae"
                    />
                    <g
                        id="常勤_日勤のみ_2"
                        data-name="常勤（日勤のみ） 2"
                        transform="translate(24 2)"
                    >
                        <text
                            id="常勤_日勤のみ_"
                            data-name="常勤（日勤のみ）"
                            transform="translate(0.5 9)"
                            fill="#fff"
                            fontSize="10"
                            fontFamily="HiraginoSans-W6, Hiragino Sans"
                        >
                            <tspan x="0" y="0">
                                募集案件
                            </tspan>
                        </text>
                    </g>
                </g>
            </g>
        </g>
    </svg>
    // </Icon>
)

export const AddNearestStationIcon = (props) => (
    // <Icon {...props}>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="118"
        height="36"
        viewBox="0 0 118 36"
    >
        <g id="active" transform="translate(3 3)">
            <g transform="matrix(1, 0, 0, 1, -3, -3)" filter="url(#Rectangle)">
                <g
                    id="Rectangle-2"
                    data-name="Rectangle"
                    transform="translate(3 3)"
                    fill="#f5faf8"
                    stroke="#3cb37a"
                    strokeMiterlimit="10"
                    strokeWidth="1"
                >
                    <rect width="112" height="30" rx="15" stroke="none" />
                    <rect
                        x="0.5"
                        y="0.5"
                        width="111"
                        height="29"
                        rx="14.5"
                        fill="none"
                    />
                </g>
            </g>
            <text
                id="職種"
                transform="translate(77 20)"
                fill="#3cb37a"
                fontSize="12"
                fontFamily="HiraginoSans-W6, Hiragino Sans"
            >
                <tspan x="-60" y="0">
                    最寄駅追加
                </tspan>
            </text>
            <g id="arrow-down" transform="translate(92 9)">
                <g id="arrow-right" transform="translate(12) rotate(90)">
                    <path
                        id="Vector"
                        d="M12,0V12H0V0Z"
                        transform="translate(12 12) rotate(180)"
                        fill="#3cb37a"
                        opacity="0"
                    />
                    <path
                        id="Vector-2"
                        data-name="Vector"
                        d="M.11.11A.375.375,0,0,1,.6.074L.64.11,3.9,3.37a1.368,1.368,0,0,1,.064,1.862L3.9,5.3.64,8.56a.375.375,0,0,1-.567-.488L.11,8.03,3.37,4.77a.618.618,0,0,0,.045-.82L3.37,3.9.11.64A.375.375,0,0,1,.11.11Z"
                        transform="translate(4.08 1.665)"
                        fill="#3cb37a"
                    />
                </g>
            </g>
        </g>
    </svg>
    // </Icon>
)

export const InputFromPostalCodeIcon = (props) => (
    // <Icon {...props}>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="130"
        height="36"
        viewBox="0 0 130 36"
    >
        <g id="active" transform="translate(3 3)">
            <g transform="matrix(1, 0, 0, 1, -3, -3)" filter="url(#Rectangle)">
                <g
                    id="Rectangle-2"
                    data-name="Rectangle"
                    transform="translate(3 3)"
                    fill="#f5faf8"
                    stroke="#3cb37a"
                    strokeMiterlimit="10"
                    strokeWidth="1"
                >
                    <rect width="124" height="30" rx="15" stroke="none" />
                    <rect
                        x="0.5"
                        y="0.5"
                        width="123"
                        height="29"
                        rx="14.5"
                        fill="none"
                    />
                </g>
            </g>
            <text
                id="職種"
                transform="translate(89 20)"
                fill="#3cb37a"
                fontSize="12"
                fontFamily="HiraginoSans-W6, Hiragino Sans"
            >
                <tspan x="-72" y="0">
                    住所自動入力
                </tspan>
            </text>
            <g id="arrow-down" transform="translate(104 9)">
                <g id="arrow-right" transform="translate(12) rotate(90)">
                    <path
                        id="Vector"
                        d="M12,0V12H0V0Z"
                        transform="translate(12 12) rotate(180)"
                        fill="#3cb37a"
                        opacity="0"
                    />
                    <path
                        id="Vector-2"
                        data-name="Vector"
                        d="M.11.11A.375.375,0,0,1,.6.074L.64.11,3.9,3.37a1.368,1.368,0,0,1,.064,1.862L3.9,5.3.64,8.56a.375.375,0,0,1-.567-.488L.11,8.03,3.37,4.77a.618.618,0,0,0,.045-.82L3.37,3.9.11.64A.375.375,0,0,1,.11.11Z"
                        transform="translate(4.08 1.665)"
                        fill="#3cb37a"
                    />
                </g>
            </g>
        </g>
    </svg>

    // </Icon>
)

export const AddCityIcon = (props) => (
    // <Icon {...props}>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="151.073"
        height="42"
        viewBox="0 0 151.073 42"
    >
        <g id="outline" transform="translate(6 4)">
            <g id="Rectangle-2" data-name="Rectangle">
                <rect
                    id="Rectangle-3"
                    data-name="Rectangle"
                    width="139.073"
                    height="30"
                    rx="15"
                    fill="none"
                />
                <g
                    transform="matrix(1, 0, 0, 1, -6, -4)"
                    filter="url(#Rectangle)"
                >
                    <rect
                        id="Rectangle-4"
                        data-name="Rectangle"
                        width="139.073"
                        height="30"
                        rx="15"
                        transform="translate(6 4)"
                        fill="#fff"
                    />
                </g>
                <rect
                    id="Rectangle-5"
                    data-name="Rectangle"
                    width="139.073"
                    height="30"
                    rx="15"
                    fill="#fff"
                />
                <rect
                    id="Rectangle-6"
                    data-name="Rectangle"
                    width="139.073"
                    height="30"
                    rx="15"
                    fill="none"
                    stroke="#3cb37a"
                    strokeMiterlimit="10"
                    strokeWidth="1"
                />
            </g>
            <g id="icon_text" data-name="icon+text" transform="translate(14 7)">
                <g id="Group">
                    <g id="add">
                        <g id="add-2" data-name="add">
                            <path
                                id="Vector"
                                d="M8.5,0a.5.5,0,0,1,.068,1L8.5,1H.5A.5.5,0,0,1,.432,0L.5,0Z"
                                transform="translate(3.5 7.5)"
                                fill="#3cb37a"
                            />
                            <path
                                id="Vector-2"
                                data-name="Vector"
                                d="M.5,0A.5.5,0,0,1,1,.432L1,.5v8a.5.5,0,0,1-1,.068L0,8.5V.5A.5.5,0,0,1,.5,0Z"
                                transform="translate(7.5 3.5)"
                                fill="#3cb37a"
                            />
                            <path
                                id="Vector-3"
                                data-name="Vector"
                                d="M16,0V16H0V0Z"
                                fill="#3cb37a"
                                opacity="0"
                            />
                        </g>
                    </g>
                    <text
                        id="リンク"
                        transform="translate(18 12.595)"
                        fill="#3cb37a"
                        fontSize="12"
                        fontFamily="HiraginoSans-W6, Hiragino Sans"
                    >
                        <tspan x="0" y="0">
                            市区町村を追加
                        </tspan>
                    </text>
                </g>
            </g>
        </g>
    </svg>

    // </Icon>
)

export const AddStationAndBusIcon = (props) => (
    // <Icon {...props}>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="157.771"
        height="42"
        viewBox="0 0 157.771 42"
    >
        <g id="outline" transform="translate(6 4)">
            <g id="Rectangle-2" data-name="Rectangle">
                <rect
                    id="Rectangle-3"
                    data-name="Rectangle"
                    width="145.771"
                    height="30"
                    rx="15"
                    fill="none"
                />
                <g
                    transform="matrix(1, 0, 0, 1, -6, -4)"
                    filter="url(#Rectangle)"
                >
                    <rect
                        id="Rectangle-4"
                        data-name="Rectangle"
                        width="145.771"
                        height="30"
                        rx="15"
                        transform="translate(6 4)"
                        fill="#fff"
                    />
                </g>
                <rect
                    id="Rectangle-5"
                    data-name="Rectangle"
                    width="145.771"
                    height="30"
                    rx="15"
                    fill="#fff"
                />
                <rect
                    id="Rectangle-6"
                    data-name="Rectangle"
                    width="145.771"
                    height="30"
                    rx="15"
                    fill="none"
                    stroke="#3cb37a"
                    strokeMiterlimit="10"
                    strokeWidth="1"
                />
            </g>
            <g id="icon_text" data-name="icon+text" transform="translate(14 7)">
                <g id="Group">
                    <g id="add">
                        <g id="add-2" data-name="add">
                            <path
                                id="Vector"
                                d="M8.5,0a.5.5,0,0,1,.068,1L8.5,1H.5A.5.5,0,0,1,.432,0L.5,0Z"
                                transform="translate(3.5 7.5)"
                                fill="#3cb37a"
                            />
                            <path
                                id="Vector-2"
                                data-name="Vector"
                                d="M.5,0A.5.5,0,0,1,1,.432L1,.5v8a.5.5,0,0,1-1,.068L0,8.5V.5A.5.5,0,0,1,.5,0Z"
                                transform="translate(7.5 3.5)"
                                fill="#3cb37a"
                            />
                            <path
                                id="Vector-3"
                                data-name="Vector"
                                d="M16,0V16H0V0Z"
                                fill="#3cb37a"
                                opacity="0"
                            />
                        </g>
                    </g>
                    <text
                        id="リンク"
                        transform="translate(18 12.596)"
                        fill="#3cb37a"
                        fontSize="12"
                        fontFamily="HiraginoSans-W6, Hiragino Sans"
                    >
                        <tspan x="0" y="0">
                            駅・バス停を追加
                        </tspan>
                    </text>
                </g>
            </g>
        </g>
    </svg>

    // </Icon>
)

export const FacilitySFURLIcon = (props) => (
    <Icon {...props}>
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="28"
            height="28"
            viewBox="0 0 28 28"
        >
            <g
                id="グループ_1553"
                data-name="グループ 1553"
                transform="translate(-331 -3511)"
                fill="currentColor"
            >
                <g id="salesforce1" transform="translate(335 3517)">
                    <path
                        id="salesforce1-2"
                        data-name="salesforce1"
                        d="M7.013,9.205a2.991,2.991,0,0,1,4.764.62A3.712,3.712,0,0,1,13.245,9.5a3.655,3.655,0,0,1,0,7.309,4.887,4.887,0,0,1-.718-.065,2.689,2.689,0,0,1-2.349,1.37A2.815,2.815,0,0,1,9,17.852a3.067,3.067,0,0,1-5.678-.163,3.6,3.6,0,0,1-.587.065A2.841,2.841,0,0,1-.1,14.915a2.811,2.811,0,0,1,1.4-2.447,3.449,3.449,0,0,1-.261-1.305A3.28,3.28,0,0,1,4.338,7.9,3.386,3.386,0,0,1,7.013,9.205"
                        transform="translate(0.1 -7.9)"
                        // fill="#009ddb"
                    />
                </g>
            </g>
        </svg>
    </Icon>
)

export const FacilityURLIcon = (props) => (
    <Icon {...props} viewBox="0 0 20 20">
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
        >
            <g
                id="グループ_1604"
                data-name="グループ 1604"
                transform="translate(1.5 1)"
                fill="currentColor"
            >
                <path
                    id="パス_1443"
                    data-name="パス 1443"
                    d="M12.039,16.042a7.861,7.861,0,0,1-.983.368,9.265,9.265,0,0,0,.91-.945L11.792,14.1a8.225,8.225,0,0,1-2.5,2.446V12.8a7.862,7.862,0,0,1,2.4.529l-.128-1.014a8.824,8.824,0,0,0-2.276-.427v-2.6h4.045A9.811,9.811,0,0,1,13,11.442l.8.66a10.716,10.716,0,0,0,.5-2.814h2.4a7.855,7.855,0,0,1-1.4,4.049l.7.579a8.791,8.791,0,0,0-.667-11l.011-.011-.324-.322A8.748,8.748,0,0,0,9.751.073L9.707.068C9.585.056,9.463.047,9.341.04L9.259.034c-.051,0-.1,0-.154,0C8.908.014,8.7,0,8.5,0a.464.464,0,0,0-.181.037l-.032,0c-.122.007-.244.016-.366.028L7.872.073A8.748,8.748,0,0,0,2.6,2.585l-.324.322.011.011a8.8,8.8,0,0,0,0,11.832l-.011.011.324.323a8.748,8.748,0,0,0,5.272,2.512l.044.005c.121.012.243.021.366.028l.032,0a.477.477,0,0,0,.175.037H8.5c.209,0,.413-.014.61-.029.051,0,.1,0,.154,0l.082-.006c.122-.007.244-.016.366-.028l.044-.005a8.747,8.747,0,0,0,2.408-.608ZM16.7,8.38H14.3A10.6,10.6,0,0,0,13.4,4.5a8.851,8.851,0,0,0,1.283-.954A7.87,7.87,0,0,1,16.7,8.38M14.034,2.911a7.932,7.932,0,0,1-1.043.777,9.478,9.478,0,0,0-1.934-2.429,7.9,7.9,0,0,1,2.978,1.652M9.291,1.127a8.322,8.322,0,0,1,2.857,3.02,7.85,7.85,0,0,1-2.857.721Zm0,4.652a8.755,8.755,0,0,0,3.262-.816,9.646,9.646,0,0,1,.783,3.417H9.291ZM6.564,1.259A9.459,9.459,0,0,0,4.63,3.687a7.928,7.928,0,0,1-1.041-.776A7.893,7.893,0,0,1,6.564,1.259M2.941,3.55A8.828,8.828,0,0,0,4.222,4.5,10.6,10.6,0,0,0,3.327,8.38H.923A7.871,7.871,0,0,1,2.941,3.55M.923,9.289h2.4a10.6,10.6,0,0,0,.895,3.876,8.837,8.837,0,0,0-1.281.953A7.871,7.871,0,0,1,.923,9.289m2.666,5.469a7.926,7.926,0,0,1,1.041-.776,9.462,9.462,0,0,0,1.934,2.427,7.9,7.9,0,0,1-2.975-1.651m4.743,1.785a8.312,8.312,0,0,1-2.859-3.019A7.856,7.856,0,0,1,8.332,12.8Zm0-4.652a8.761,8.761,0,0,0-3.264.817,9.651,9.651,0,0,1-.781-3.418H8.332Zm0-3.51H4.287a9.649,9.649,0,0,1,.781-3.418,8.755,8.755,0,0,0,3.264.817Zm0-3.512a7.856,7.856,0,0,1-2.859-.722A8.311,8.311,0,0,1,8.332,1.126Z"
                    // fill="#3cb37a"
                />
                <path
                    id="パス_1444"
                    data-name="パス 1444"
                    d="M118.728,112.194l-4.484-3.691.731,5.762,1.321-.729,1.233,2.235,1.112-.614-1.233-2.234Z"
                    transform="translate(-102.175 -97.041)"
                    // fill="#3cb37a"
                />
            </g>
        </svg>
    </Icon>
)

export const NgIcon = (props) => (
    <Icon {...props} viewBox="0 0 15.15 15.15">
        <svg
            id="_レイヤー_1"
            data-name="レイヤー 1"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                x="6.58"
                y="-2.14"
                width="2"
                height="19.43"
                transform="translate(-3.14 7.58) rotate(-45)"
                fill="#fff"
            />
            <rect
                x="-2.14"
                y="6.58"
                width="19.43"
                height="2"
                transform="translate(-3.14 7.58) rotate(-45)"
                fill="#fff"
            />
        </svg>
    </Icon>
)

export const OkIcon = (props) => (
    <Icon {...props} viewBox="0 0 16.71 16.71">
        <svg
            id="_レイヤー_1"
            data-name="レイヤー 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 16.71 16.71"
        >
            <path
                fill="#fff"
                d="m8.35,16.71C3.75,16.71,0,12.96,0,8.35S3.75,0,8.35,0s8.35,3.75,8.35,8.35-3.75,8.35-8.35,8.35Zm0-14.71c-3.5,0-6.35,2.85-6.35,6.35s2.85,6.35,6.35,6.35,6.35-2.85,6.35-6.35-2.85-6.35-6.35-6.35Z"
            />
        </svg>
    </Icon>
)

export const QuestionIcon = (props) => (
    <Icon {...props} viewBox="-3 0 12.84 15.75">
        <svg
            id="_レイヤー_1"
            data-name="レイヤー 1"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fill="#fff"
                d="m3.35,9.9c-.01-.49.05-.93.19-1.32.14-.39.37-.75.68-1.07s.71-.63,1.2-.92c.43-.26.77-.52,1.03-.76.26-.24.44-.5.55-.78s.17-.6.17-.96v-.02c0-.41-.1-.77-.3-1.09-.2-.32-.48-.57-.84-.75-.36-.18-.78-.27-1.26-.27s-.92.09-1.29.28c-.37.19-.65.45-.86.78-.21.33-.33.71-.37,1.15v.1H0v-.12c.05-.77.26-1.48.64-2.11s.92-1.13,1.63-1.5,1.58-.56,2.61-.56c.95,0,1.79.17,2.5.52.71.35,1.27.82,1.67,1.41s.6,1.28.6,2.05v.02c0,.55-.09,1.05-.27,1.5-.18.45-.44.85-.77,1.2-.34.36-.74.68-1.22.96-.46.27-.82.53-1.07.77-.26.24-.44.49-.54.76-.1.27-.16.59-.16.95v.6h-2.23v-.85Zm.13,5.44c-.27-.27-.41-.6-.41-.99s.13-.73.41-.99c.27-.27.61-.4,1.01-.4s.74.13,1.01.4c.27.27.41.6.41.99s-.14.73-.41.99c-.27.27-.61.4-1.01.4s-.74-.13-1.01-.4Z"
            />
        </svg>
    </Icon>
)

export const ChatIcon = createIcon({
    displayName: 'ChatIcon',
    viewBox: '0 0 90 80',
    path: (
        <>
            <path
                fill="currentcolor"
                d="m74.34,5.01H16.72c-4.69,0-8.5,3.81-8.5,8.5v63.23c0,1.04.64,1.97,1.62,2.34.29.11.59.16.88.16.7,0,1.39-.3,1.88-.85l13.35-15.15h48.4c4.69,0,8.5-3.81,8.5-8.5V13.51c0-4.69-3.81-8.5-8.5-8.5Zm3.5,49.73c0,1.93-1.57,3.5-3.5,3.5H24.82c-.72,0-1.4.31-1.88.85l-9.72,11.03V13.51c0-1.93,1.57-3.5,3.5-3.5h57.62c1.93,0,3.5,1.57,3.5,3.5v41.23Z"
            />
            <circle
                fill="currentcolor"
                cx="26.64"
                cy="34.16"
                r="4.63"
                transform="translate(-16.35 28.84) rotate(-45)"
            />
            <circle
                fill="currentcolor"
                cx="45.53"
                cy="34.16"
                r="4.63"
                transform="translate(-6.63 11.37) rotate(-13.28)"
            />
            <circle
                fill="currentcolor"
                cx="64.42"
                cy="34.16"
                r="4.63"
                transform="translate(-6.12 15.71) rotate(-13.28)"
            />
        </>
    ),
    defaultProps: {
        color: '#231815',
        className: 'home-icon',
    },
})

export const HomeIcon = createIcon({
    displayName: 'HomeIcon',
    viewBox: '0 0 90 80',
    path: (
        <>
            <path
                fill="currentcolor"
                d="m86.62,36.1L46.6,2.72c-.93-.77-2.28-.77-3.2,0L3.38,36.1c-.81.67-1.11,1.78-.75,2.77s1.3,1.65,2.35,1.65h9.24v33.25c0,1.38,1.12,2.5,2.5,2.5h18.21c1.38,0,2.5-1.12,2.5-2.5v-12.53c0-1.26.35-7.53,7.33-7.53,7.73,0,7.82,7.23,7.82,7.53v12.53c0,1.38,1.12,2.5,2.5,2.5h18.21c1.38,0,2.5-1.12,2.5-2.5v-33.25h9.24c1.05,0,1.99-.66,2.35-1.65s.06-2.1-.75-2.77Zm-13.34-.58c-1.38,0-2.5,1.12-2.5,2.5v33.25h-13.21v-10.03c0-5.04-3.41-12.53-12.82-12.53-6.14,0-12.33,3.88-12.33,12.53v10.03h-13.21v-33.25c0-1.38-1.12-2.5-2.5-2.5h-4.84L45,7.89l33.12,27.62h-4.84Z"
            />
            <path
                fill="currentcolor"
                d="m34.39,35.03c0,5.71,4.65,10.36,10.36,10.36s10.36-4.65,10.36-10.36-4.65-10.36-10.36-10.36-10.36,4.65-10.36,10.36Zm15.73,0c0,2.96-2.41,5.36-5.36,5.36s-5.36-2.41-5.36-5.36,2.41-5.36,5.36-5.36,5.36,2.41,5.36,5.36Z"
            />
        </>
    ),
    defaultProps: {
        color: '#231815',
        className: 'home-icon',
    },
})

export const LearnIcon = createIcon({
    displayName: 'LearnIcon',
    viewBox: '0 0 90 80',
    path: (
        <>
            <path
                fill="currentcolor"
                d="m71.2,36.18c.61-.92.55-2.14-.16-2.99l-5.94-7.13c-.45-.53-1.09-.86-1.79-.9-.7-.04-1.37.22-1.87.7-7.86,7.63-15.8,11.98-22.64,14.45,1.22-2.08,1.93-4.49,1.93-7.07,0-7.73-6.29-14.02-14.02-14.02s-14.02,6.29-14.02,14.02c0,4.4,2.04,8.33,5.23,10.91-6.11,2.09-10.36,8.52-10.36,12.98v16.63c0,1.38,1.12,2.5,2.5,2.5h32.83c1.38,0,2.5-1.12,2.5-2.5v-15.02c.59-.57,2.34-1.63,3.78-2.49,5.41-3.26,14.46-8.7,22.04-20.07Zm-44.5-11.96c4.98,0,9.02,4.05,9.02,9.02s-4.05,9.02-9.02,9.02-9.02-4.05-9.02-9.02,4.05-9.02,9.02-9.02Zm19.88,27.74c-3.73,2.25-6.2,3.73-6.2,6.35v12.95H12.55v-14.13c0-2.59,3.96-8.67,9.38-8.67,6.78,0,23.97-1.72,41.02-17.16l3.03,3.63c-6.71,9.39-14.29,13.96-19.4,17.03Z"
            />
            <path
                fill="currentcolor"
                d="m80.53,6.16h-22.93v-3.68h-19.01v3.68H15.66c-1.38,0-2.5,1.12-2.5,2.5v7.29h5v-4.79h20.43v3.2h19.01v-3.2h20.43v40.86h-15.4v5h17.9c1.38,0,2.5-1.12,2.5-2.5V8.66c0-1.38-1.12-2.5-2.5-2.5Z"
            />
        </>
    ),
    defaultProps: {
        color: '#231815',
        className: 'learn-icon',
    },
})

export const AdminIcon = createIcon({
    displayName: 'AdminIcon',
    viewBox: '0 0 90 80',
    path: (
        <>
            <path
                fill="currentcolor"
                d="m31.34,39.15c-8.68,0-15.74-7.06-15.74-15.74s7.06-15.74,15.74-15.74,15.74,7.06,15.74,15.74-7.06,15.74-15.74,15.74Zm0-26.47c-5.92,0-10.74,4.82-10.74,10.74s4.82,10.74,10.74,10.74,10.74-4.82,10.74-10.74-4.82-10.74-10.74-10.74Z"
            />
            <path
                fill="currentcolor"
                d="m30.66,72.45H12.22c-1.38,0-2.5-1.12-2.5-2.5v-19.1c0-6.03,6.93-15.33,16.14-15.33h10.42c4.08,0,8.08,1.83,11.28,5.15.96.99.93,2.58-.07,3.53-1,.96-2.58.93-3.54-.07-1.3-1.35-4.03-3.62-7.68-3.62h-10.42c-6.7,0-11.14,7.35-11.14,10.33v16.6h15.94c1.38,0,2.5,1.12,2.5,2.5s-1.12,2.5-2.5,2.5Z"
            />
            <g>
                <path
                    fill="currentcolor"
                    d="m48.29,72.43c-2.97,0-5.76-1.16-7.86-3.26-4.34-4.34-4.34-11.39,0-15.73,2.1-2.1,4.89-3.26,7.86-3.26s5.76,1.16,7.86,3.26c4.34,4.34,4.34,11.39,0,15.73-2.1,2.1-4.89,3.26-7.86,3.26Zm0-17.24c-1.63,0-3.17.64-4.33,1.79-2.39,2.39-2.39,6.27,0,8.66,1.16,1.16,2.69,1.79,4.33,1.79s3.17-.64,4.33-1.79c2.39-2.39,2.39-6.27,0-8.66-1.16-1.16-2.69-1.79-4.33-1.79Z"
                />
                <path
                    fill="currentcolor"
                    d="m54.38,57.72c-.64,0-1.28-.24-1.77-.73-.98-.98-.98-2.56,0-3.54l18.63-18.63c.98-.98,2.56-.98,3.54,0l9.71,9.71c.98.98.98,2.56,0,3.54-.98.98-2.56.98-3.54,0l-7.95-7.94-16.86,16.86c-.49.49-1.13.73-1.77.73Z"
                />
                <path
                    fill="currentcolor"
                    d="m72.37,54.66c-.64,0-1.28-.24-1.77-.73l-7.46-7.46c-.98-.98-.98-2.56,0-3.54.98-.98,2.56-.98,3.54,0l7.46,7.46c.98.98.98,2.56,0,3.54-.49.49-1.13.73-1.77.73Z"
                />
            </g>
        </>
    ),
    defaultProps: {
        color: '#231815',
        className: 'admin-icon',
    },
})
export const ExtraMenuIcon = createIcon({
    displayName: 'AdminIcon',
    viewBox: '0 0 24.376 24.376',
    path: (
        <>
            <g id="グループ_4360" data-name="グループ 4360">
                <g
                    id="グループ_4359"
                    data-name="グループ 4359"
                    clipPath="url(#clip-path)"
                >
                    <path
                        id="長方形_1729"
                        data-name="長方形 1729"
                        d="M0-.763H5.168A.763.763,0,0,1,5.931,0V5.168a.763.763,0,0,1-.763.763H0a.763.763,0,0,1-.763-.763V0A.763.763,0,0,1,0-.763ZM4.405.763H.763V4.4H4.405Z"
                        transform="translate(0.763 0.764)"
                        fill="#3bb47b"
                    />
                    <path
                        id="長方形_1730"
                        data-name="長方形 1730"
                        d="M0-.763H5.168A.763.763,0,0,1,5.931,0V5.168a.763.763,0,0,1-.763.763H0a.763.763,0,0,1-.763-.763V0A.763.763,0,0,1,0-.763ZM4.405.763H.763V4.4H4.405Z"
                        transform="translate(9.604 0.764)"
                        fill="#3bb47b"
                    />
                    <path
                        id="長方形_1731"
                        data-name="長方形 1731"
                        d="M0-.763H5.168A.763.763,0,0,1,5.931,0V5.168a.763.763,0,0,1-.763.763H0a.763.763,0,0,1-.763-.763V0A.763.763,0,0,1,0-.763ZM4.405.763H.763V4.4H4.405Z"
                        transform="translate(18.445 0.764)"
                        fill="#3bb47b"
                    />
                    <path
                        id="長方形_1732"
                        data-name="長方形 1732"
                        d="M0-.763H5.168A.763.763,0,0,1,5.931,0V5.168a.763.763,0,0,1-.763.763H0a.763.763,0,0,1-.763-.763V0A.763.763,0,0,1,0-.763ZM4.405.763H.763V4.405H4.405Z"
                        transform="translate(18.445 18.445)"
                        fill="#3bb47b"
                    />
                    <path
                        id="長方形_1733"
                        data-name="長方形 1733"
                        d="M0-.763H5.168A.763.763,0,0,1,5.931,0V5.168a.763.763,0,0,1-.763.763H0a.763.763,0,0,1-.763-.763V0A.763.763,0,0,1,0-.763ZM4.405.763H.763V4.405H4.405Z"
                        transform="translate(18.445 9.604)"
                        fill="#3bb47b"
                    />
                    <path
                        id="長方形_1734"
                        data-name="長方形 1734"
                        d="M0-.763H5.168A.763.763,0,0,1,5.931,0V5.168a.763.763,0,0,1-.763.763H0a.763.763,0,0,1-.763-.763V0A.763.763,0,0,1,0-.763ZM4.405.763H.763V4.405H4.405Z"
                        transform="translate(9.604 18.445)"
                        fill="#3bb47b"
                    />
                </g>
            </g>
            <path
                id="長方形_1736"
                data-name="長方形 1736"
                d="M0-.763H5.168A.763.763,0,0,1,5.931,0V5.168a.763.763,0,0,1-.763.763H0a.763.763,0,0,1-.763-.763V0A.763.763,0,0,1,0-.763ZM4.405.763H.763V4.405H4.405Z"
                transform="translate(9.604 9.604)"
                fill="#3bb47b"
            />
            <g id="グループ_4362" data-name="グループ 4362">
                <g
                    id="グループ_4361"
                    data-name="グループ 4361"
                    clipPath="url(#clip-path)"
                >
                    <path
                        id="長方形_1737"
                        data-name="長方形 1737"
                        d="M0-.763H5.168A.763.763,0,0,1,5.931,0V5.168a.763.763,0,0,1-.763.763H0a.763.763,0,0,1-.763-.763V0A.763.763,0,0,1,0-.763ZM4.405.763H.763V4.405H4.405Z"
                        transform="translate(0.763 18.445)"
                        fill="#3bb47b"
                    />
                    <path
                        id="長方形_1738"
                        data-name="長方形 1738"
                        d="M0-.763H5.168A.763.763,0,0,1,5.931,0V5.168a.763.763,0,0,1-.763.763H0a.763.763,0,0,1-.763-.763V0A.763.763,0,0,1,0-.763ZM4.405.763H.763V4.405H4.405Z"
                        transform="translate(0.763 9.604)"
                        fill="#3bb47b"
                    />
                </g>
            </g>
        </>
    ),
    defaultProps: {
        color: 'primary.500',
        className: 'extra-menu-icon',
    },
})
export const NewFacilityIcon = createIcon({
    displayName: 'AdminIcon',
    viewBox: '0 0 99 38',
    path: (
        <>
            <g
                id="グループ_4331"
                data-name="グループ 4331"
                transform="translate(-244.428 -31.917)"
            >
                <text
                    id="新規開拓_支援"
                    data-name="新規開拓
支援"
                    transform="translate(287.428 47.917)"
                    fill="#3bb47b"
                    fontSize="12"
                    fontFamily="NotoSansJP-Medium, Noto Sans JP"
                    fontWeight="500"
                >
                    <tspan x="0" y="0">
                        新規開拓
                    </tspan>
                    <tspan x="0" y="15">
                        支援
                    </tspan>
                </text>
                <path
                    id="長方形_1682"
                    data-name="長方形 1682"
                    d="M6,1A5.006,5.006,0,0,0,1,6V32a5.006,5.006,0,0,0,5,5H93a5.006,5.006,0,0,0,5-5V6a5.006,5.006,0,0,0-5-5H6M6,0H93a6,6,0,0,1,6,6V32a6,6,0,0,1-6,6H6a6,6,0,0,1-6-6V6A6,6,0,0,1,6,0Z"
                    transform="translate(244.428 31.917)"
                    fill="#3bb47b"
                />
                <g
                    id="グループ_4332"
                    data-name="グループ 4332"
                    transform="translate(251.428 37.741)"
                >
                    <g
                        id="グループ_4333"
                        data-name="グループ 4333"
                        transform="translate(0 0)"
                    >
                        <g
                            id="グループ_4332-2"
                            data-name="グループ 4332"
                            clipPath="url(#clip-path)"
                        >
                            <path
                                id="長方形_1706"
                                data-name="長方形 1706"
                                d="M0-.5H18.221a.5.5,0,0,1,.5.5V6.406a.5.5,0,0,1-.5.5H0a.5.5,0,0,1-.5-.5V0A.5.5,0,0,1,0-.5ZM17.721.5H.5V5.906H17.721Z"
                                transform="translate(2.892 19.345)"
                                fill="#36b176"
                            />
                        </g>
                    </g>
                    <path
                        id="長方形_1708"
                        data-name="長方形 1708"
                        d="M0-.5H18.221a.5.5,0,0,1,.5.5V5.9a.5.5,0,0,1-.5.5H0a.5.5,0,0,1-.5-.5V0A.5.5,0,0,1,0-.5ZM17.721.5H.5V5.4H17.721Z"
                        transform="translate(2.892 10.505)"
                        fill="#36b176"
                    />
                    <path
                        id="パス_1118"
                        data-name="パス 1118"
                        d="M24.266,19.71a.5.5,0,0,1-.5-.5V16.765H4.185V19.21a.5.5,0,0,1-1,0V16.265a.5.5,0,0,1,.5-.5H24.266a.5.5,0,0,1,.5.5V19.21A.5.5,0,0,1,24.266,19.71Z"
                        transform="translate(-1.972 -8.705)"
                        fill="#36b176"
                    />
                    <rect
                        id="長方形_1709"
                        data-name="長方形 1709"
                        width="20.581"
                        height="2.945"
                        transform="translate(1.713 16.4)"
                        fill="#fff"
                    />
                    <path
                        id="長方形_1709_-_アウトライン"
                        data-name="長方形 1709 - アウトライン"
                        d="M-.5-.5H21.081V3.445H-.5ZM20.081.5H.5V2.445H20.081Z"
                        transform="translate(1.713 16.4)"
                        fill="#707070"
                    />
                    <path
                        id="長方形_1710"
                        data-name="長方形 1710"
                        d="M0-.5H20.581a.5.5,0,0,1,.5.5V2.945a.5.5,0,0,1-.5.5H0a.5.5,0,0,1-.5-.5V0A.5.5,0,0,1,0-.5ZM20.081.5H.5V2.445H20.081Z"
                        transform="translate(1.713 16.4)"
                        fill="#36b176"
                    />
                    <g
                        id="グループ_4335"
                        data-name="グループ 4335"
                        transform="translate(0 0)"
                    >
                        <g
                            id="グループ_4334"
                            data-name="グループ 4334"
                            clipPath="url(#clip-path)"
                        >
                            <path
                                id="線_864"
                                data-name="線 864"
                                d="M23.309.5H0A.5.5,0,0,1-.5,0,.5.5,0,0,1,0-.5H23.309a.5.5,0,0,1,.5.5A.5.5,0,0,1,23.309.5Z"
                                transform="translate(0.349 25.751)"
                                fill="#36b176"
                            />
                            <path
                                id="線_865"
                                data-name="線 865"
                                d="M0,6.906a.5.5,0,0,1-.5-.5V0A.5.5,0,0,1,0-.5.5.5,0,0,1,.5,0V6.406A.5.5,0,0,1,0,6.906Z"
                                transform="translate(9.742 19.345)"
                                fill="#36b176"
                            />
                            <path
                                id="線_866"
                                data-name="線 866"
                                d="M0,6.906a.5.5,0,0,1-.5-.5V0A.5.5,0,0,1,0-.5.5.5,0,0,1,.5,0V6.406A.5.5,0,0,1,0,6.906Z"
                                transform="translate(14.264 19.345)"
                                fill="#36b176"
                            />
                        </g>
                    </g>
                    <path
                        id="パス_1119"
                        data-name="パス 1119"
                        d="M12.322,29.142a.5.5,0,0,1-.5-.5V26.693a.5.5,0,0,1,.5-.5h1.949a.5.5,0,0,1,0,1H12.822v1.449A.5.5,0,0,1,12.322,29.142Z"
                        transform="translate(-6.595 -14.286)"
                        fill="#36b176"
                    />
                    <path
                        id="パス_1120"
                        data-name="パス 1120"
                        d="M23.728,29.142a.5.5,0,0,1-.5-.5V26.693a.5.5,0,0,1,.5-.5h1.949a.5.5,0,0,1,0,1H24.228v1.449A.5.5,0,0,1,23.728,29.142Z"
                        transform="translate(-12.699 -14.286)"
                        fill="#36b176"
                    />
                    <path
                        id="パス_1121"
                        data-name="パス 1121"
                        d="M35.133,29.142a.5.5,0,0,1-.5-.5V26.693a.5.5,0,0,1,.5-.5h1.949a.5.5,0,0,1,0,1H35.633v1.449A.5.5,0,0,1,35.133,29.142Z"
                        transform="translate(-18.803 -14.286)"
                        fill="#36b176"
                    />
                    <g
                        id="グループ_4337"
                        data-name="グループ 4337"
                        transform="translate(0 0)"
                    >
                        <g
                            id="グループ_4336"
                            data-name="グループ 4336"
                            clipPath="url(#clip-path)"
                        >
                            <path
                                id="パス_1122"
                                data-name="パス 1122"
                                d="M24.9,8.461H12.68a.5.5,0,0,1-.5-.5V3.649a.5.5,0,0,1,.286-.452L18.575.3A.5.5,0,0,1,19,.3l6.11,2.9a.5.5,0,0,1,.286.452V7.961A.5.5,0,0,1,24.9,8.461Zm-11.719-1H24.4v-3.5L18.789,1.3,13.18,3.965ZM17.741,6.5a.5.5,0,0,1-.5-.5V4.046a.5.5,0,0,1,.5-.5h1.949a.5.5,0,1,1,0,1H18.241V6A.5.5,0,0,1,17.741,6.5Z"
                                transform="translate(-6.786 -0.402)"
                                fill="#36b176"
                            />
                        </g>
                    </g>
                </g>
                <g
                    id="グループ_4333-2"
                    data-name="グループ 4333"
                    transform="translate(-1164.572 7.917)"
                >
                    <path
                        id="線_867"
                        data-name="線 867"
                        d="M7.144.5H0A.5.5,0,0,1-.5,0,.5.5,0,0,1,0-.5H7.144a.5.5,0,0,1,.5.5A.5.5,0,0,1,7.144.5Z"
                        transform="translate(1438.006 33.396)"
                        fill="#3bb47b"
                    />
                    <path
                        id="線_868"
                        data-name="線 868"
                        d="M0,7.644a.5.5,0,0,1-.5-.5V0A.5.5,0,0,1,0-.5.5.5,0,0,1,.5,0V7.144A.5.5,0,0,1,0,7.644Z"
                        transform="translate(1441.578 29.824)"
                        fill="#3bb47b"
                    />
                </g>
            </g>
        </>
    ),
    defaultProps: {
        color: 'primary.500',
        className: 'new-facility-icon',
    },
})

export const DeleteIcon = (props) => (
    // <Icon {...props} viewBox="0 0 12.84 15.75">
    <Icon {...props} viewBox="0 0 12.84 12.84">
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12.814"
            height="12.814"
            viewBox="0 0 12.814 12.814"
        >
            <g
                id="グループ_4320"
                data-name="グループ 4320"
                transform="translate(0 0)"
            >
                <g
                    id="グループ_4321"
                    data-name="グループ 4321"
                    transform="translate(0 0)"
                >
                    <path
                        id="パス_1113"
                        data-name="パス 1113"
                        d="M12.814,6.407A6.407,6.407,0,1,1,6.407,0a6.407,6.407,0,0,1,6.407,6.407M6.407,1.463a4.944,4.944,0,1,0,4.944,4.944A4.945,4.945,0,0,0,6.407,1.463"
                        transform="translate(0 0)"
                        fill="red"
                    />
                    <rect
                        id="長方形_1693"
                        data-name="長方形 1693"
                        width="11.293"
                        height="1.629"
                        transform="translate(1.838 9.824) rotate(-45)"
                        fill="red"
                    />
                    <rect
                        id="長方形_1694"
                        data-name="長方形 1694"
                        width="11.293"
                        height="1.629"
                        transform="translate(2.99 1.838) rotate(45)"
                        fill="red"
                    />
                </g>
            </g>
        </svg>
    </Icon>
)
