import get from 'lodash.get'

export class Paging {
    /**
     * @param {Object} options
     * @param {number} options.limit
     * @param {number} options.page
     */
    constructor(options) {
        let limit = get(options, 'limit', null)
        if (limit === null || limit > 200) {
            // NOTE: 保険。アプリのバリデーションルールでデフォルト値は指定できる
            limit = 20
        }
        const page = get(options, 'page', 1)
        const skip = limit * (page - 1)

        this.limit = limit
        this.skip = skip
        this.page = page
        this.perPage = limit
        this.type = 'total'
    }

    /**
     * @param {number} count
     * @param {number} threshold
     */
    setCount(count, threshold = null) {
        let c = count
        if (threshold && count > threshold) {
            this.type = 'lowerBound'
            c = threshold
        }
        this.count = c
        this.pageCount = Math.ceil(c / this.limit)
        return this
    }

    isEmpty() {
        return !this.limit || !this.count
    }
}
