import { Stack, HStack } from '@chakra-ui/react'
import { useContext } from 'react'

import { FacilityContext } from '..'
import {
    DateFormatComponent,
    TextHStackComponent,
    TextHeadingComponent,
    UrlComponent,
} from '../../FacilityItemComponents'
import { RecommendedPoint } from '../../JobOfferComponents'
import { ToggleViewingModeStateContext } from '@/components/Layout'

export const FacilityCommonItems = () => {
    const data = useContext(FacilityContext)
    const { isJobseekerViewing } = useContext(ToggleViewingModeStateContext)

    return (
        <>
            <TextHeadingComponent
                label="運営法人名"
                isObject={[data.corporation.name]}
            >
                <TextHStackComponent>
                    {data.corporation.name}
                </TextHStackComponent>
            </TextHeadingComponent>
            <TextHeadingComponent
                label="法人住所"
                isObject={[data.corporation.address]}
            >
                <TextHStackComponent>
                    {data.corporation.address}
                </TextHStackComponent>
            </TextHeadingComponent>
            {/* Salesforce */}
            {!isJobseekerViewing && (
                <TextHeadingComponent
                    label="SF情報"
                    isObject={[
                        data.sfId,
                        data.sfUrl,
                        data.contract,
                        data.feePercent,
                    ]}
                >
                    <Stack>
                        <TextHStackComponent label="施設SFID">
                            {data.sfId}
                        </TextHStackComponent>
                        <UrlComponent label="施設SFURL" url={data.sfUrl}>
                            {data.sfUrl}
                        </UrlComponent>
                        <HStack>
                            <TextHStackComponent label="施設の契約状況">
                                {data.contract}
                            </TextHStackComponent>
                            <TextHStackComponent
                                label="施設の手数料率"
                                endStr="%"
                            >
                                {data.feePercent}
                            </TextHStackComponent>
                        </HStack>
                    </Stack>
                </TextHeadingComponent>
            )}
        </>
    )
}

export const FacilityModifiedDateItem = () => {
    const data = useContext(FacilityContext)
    return (
        <TextHeadingComponent label="更新日" isObject={[data.modifiedDate]}>
            <Stack>
                <DateFormatComponent>{data.modifiedDate}</DateFormatComponent>
            </Stack>
        </TextHeadingComponent>
    )
}

export const FacilityRecommendedPointItems = () => {
    const data = useContext(FacilityContext)
    return (
        <>
            {data.salesTalks.map((item, index) => (
                <RecommendedPoint key={index} number={index + 1}>
                    {item.salesTalkPoint}
                </RecommendedPoint>
            ))}
        </>
    )
}
