import { Stack } from '@chakra-ui/react'
import { useContext } from 'react'

import { FacilityContext } from '..'
import {
    UrlComponent,
    CorporationUrl,
    DateFormatComponent,
    TextComponent,
    TextHeadingComponent,
    TextHStackComponent,
} from '../../FacilityItemComponents'
import { ToggleViewingModeStateContext } from '@/components/Layout'

export const KaigoOfficeItem = () => {
    const data = useContext(FacilityContext)
    const { isJobseekerViewing } = useContext(ToggleViewingModeStateContext)

    return (
        <TextHeadingComponent
            label="法人基本情報"
            isObject={[
                data.officeUrl,
                data.corporation.url,
                // 候補者説明モード切替時に介サURL、事業者番号しかデータが無い場合、
                // コンポーネントを非表示のみだと大項目だけ出てくるのでデータもnullにする
                !isJobseekerViewing ? data.kaisaUrl : null,
                !isJobseekerViewing ? data.officeCode : null,
                data.officeStartDate,
                data.otherServiceAttached,
                data.officeDirection,
            ]}
        >
            <Stack>
                <UrlComponent label="事業所URL" url={data.officeUrl}>
                    {data.officeUrl}
                </UrlComponent>
                <CorporationUrl label="法人URL" url={data.corporation.url}>
                    {data.corporation.url}
                </CorporationUrl>
                {!isJobseekerViewing && (
                    <>
                        <UrlComponent label="介サURL" url={data.kaisaUrl}>
                            {data.kaisaUrl}
                        </UrlComponent>
                        <TextHStackComponent label="事業者番号">
                            {data.officeCode}
                        </TextHStackComponent>
                    </>
                )}
                <DateFormatComponent label="事業開始年月日">
                    {data.officeStartDate}
                </DateFormatComponent>
                <TextComponent label="併設サービス">
                    {data.otherServiceAttached}
                </TextComponent>
                <TextComponent label="運営方針">
                    {data.officeDirection}
                </TextComponent>
            </Stack>
        </TextHeadingComponent>
    )
}

export const HoikuOfficeItem = () => {
    const data = useContext(FacilityContext)
    const { isJobseekerViewing } = useContext(ToggleViewingModeStateContext)

    return (
        <TextHeadingComponent
            label="法人基本情報"
            isObject={[
                data.officeUrl,
                data.corporation.url,
                // 候補者説明モード切替時にWamURL、事業者番号しかデータが無い場合、
                // コンポーネントを非表示のみだと大項目だけ出てくるのでデータもnullにする
                !isJobseekerViewing ? data.wamUrl : null,
                !isJobseekerViewing ? data.officeCode : null,
                data.officeStartDate,
                data.officeDirection,
            ]}
        >
            <Stack>
                {/* 下記3つのURLについては後日追加予定 */}

                <UrlComponent label="事業所URL" url={data.officeUrl}>
                    {data.officeUrl}
                </UrlComponent>
                <CorporationUrl label="法人URL" url={data.corporation.url}>
                    {data.corporation.url}
                </CorporationUrl>
                {!isJobseekerViewing && (
                    <>
                        <UrlComponent label="WamURL" url={data.wamUrl}>
                            {data.wamUrl}
                        </UrlComponent>
                        <TextHStackComponent label="事業者番号">
                            {data.officeCode}
                        </TextHStackComponent>
                    </>
                )}
                <DateFormatComponent label="事業開始年月日">
                    {data.officeStartDate}
                </DateFormatComponent>
                <TextComponent label="運営方針">
                    {data.officeDirection}
                </TextComponent>
            </Stack>
        </TextHeadingComponent>
    )
}

export const KangoOfficeItem = () => {
    const data = useContext(FacilityContext)
    const { isJobseekerViewing } = useContext(ToggleViewingModeStateContext)

    return (
        <>
            <TextHeadingComponent
                label="法人基本情報"
                isObject={[
                    // 候補者説明モード切替時にscuelURL、事業者番号しかデータが無い場合、
                    // コンポーネントを非表示のみだと大項目だけ出てくるのでデータもnullにする
                    !isJobseekerViewing ? data.corporation.url : null,
                    !isJobseekerViewing ? data.officeCode : null,
                    data.officeStartDate,
                ]}
            >
                <Stack>
                    {!isJobseekerViewing && (
                        <>
                            <UrlComponent
                                label="scuelURL"
                                url={data.corporation.url}
                            >
                                {data.corporation.url}
                            </UrlComponent>
                            <TextHStackComponent label="事業者番号">
                                {data.officeCode}
                            </TextHStackComponent>
                        </>
                    )}
                    <DateFormatComponent label="事業開始年月日">
                        {data.officeStartDate}
                    </DateFormatComponent>
                </Stack>
            </TextHeadingComponent>
        </>
    )
}
