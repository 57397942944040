import { Stack, Box, Text, HStack } from '@chakra-ui/react'
import parse from 'html-react-parser'
import PropTypes from 'prop-types'

import { GoodIcon } from '@/assets/icon'
const SITE = process.env.SITE
const kaigo = 'kaigo'
const hoiku = 'hoiku'
const kango = 'kango'
const RecommendTextTitle = ({ number }) => {
    return (
        <Text fontSize="18px" fontWeight="bold">
            {SITE === kaigo ? 'おすすめポイント' : 'セールスポイント'}
            {number}
        </Text>
    )
}

RecommendTextTitle.propTypes = {
    number: PropTypes.number,
}

export const RecommendedPoint = ({ children, number }) => {
    const html = children

    return (
        children && (
            <Stack>
                <HStack color="#FBC02D">
                    <GoodIcon color="#FBC02D" boxSize="16px" />
                    <RecommendTextTitle number={number} />
                </HStack>
                <Box bg="#FFF9CF" fontWeight="bold" padding="20px 30px">
                    <Stack fontSize="16px">
                        <Box>{parse(html)}</Box>
                    </Stack>
                </Box>
            </Stack>
        )
    )
}

RecommendedPoint.propTypes = {
    children: PropTypes.string,
    number: PropTypes.number,
}
