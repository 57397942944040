import { Button } from '@chakra-ui/react'
import PropTypes from 'prop-types'
import { useCallback } from 'react'

import { ChatIcon } from '@/assets/icon'
import { NODE_BB_BASE_URL } from '@/config'

export const useNewContact = () => {
    const url = `${NODE_BB_BASE_URL}/recent?pinned=1`

    const openNewContact = useCallback(() => {
        if (!url) {
            alert('URLが指定されていません。')
        } else {
            window.open(url, '_blank')
        }
    }, [url])

    return openNewContact
}

export const NewContactButton = ({ isHeader }) => {
    const openNewContact = useNewContact()

    return (
        <Button
            cursor="pointer"
            as="a"
            variant="link"
            onClick={openNewContact}
            {...(isHeader && {
                colorScheme: 'black',
                leftIcon: <ChatIcon />,
            })}
        >
            お問い合わせ(レコメンド掲示板)
        </Button>
    )
}

NewContactButton.propTypes = {
    backURL: PropTypes.string,
    close: PropTypes.any,
    isHeader: PropTypes.bool,
}
