import { badgeTheme } from './badge'
import { buttonTheme } from './button'
import { formTheme } from './form'
import { formLabelTheme } from './form-label'
import { headingTheme } from './heading'
import { inputTheme } from './input'
import { linkTheme } from './link'
import { numberInputTheme } from './number-input'
import { radioTheme } from './radio'
import { selectTheme } from './select'
import { tabTheme } from './tab'
import { tagTheme } from './tag'
import { textTheme } from './text'
import { textareaTheme } from './textarea'

export const components = {
    Input: inputTheme,
    Select: selectTheme,
    Form: formTheme,
    FormLabel: formLabelTheme,
    Textarea: textareaTheme,
    Radio: radioTheme,
    Badge: badgeTheme,
    Tag: tagTheme,
    Button: buttonTheme,
    NumberInput: numberInputTheme,
    Text: textTheme,
    Heading: headingTheme,
    Link: linkTheme,
    Tab: tabTheme,
}
