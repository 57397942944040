import { createSlice } from '@reduxjs/toolkit'

const initState = {
    sfId: '',
    detail: null,
    needsFormReset: false,
    isConfirmed: false,
}

export const jobseekerSlice = createSlice({
    name: 'jobseeker',
    initialState: initState,
    reducers: {
        updateSfId: (state, action) => {
            const { sfId } = action.payload
            state.sfId = sfId
        },
        updateJobseekerDetail: (state, action) => {
            const { detail } = action.payload
            state.sfId = detail.sfId
            state.detail = detail
        },
        confirmJobseeker: (state) => {
            state.isConfirmed = true
            state.needsFormReset = true
        },
        clearSfId: (state) => {
            state.sfId = ''
        },
        resetJobseeker: (state) => {
            state.sfId = initState.sfId
            state.detail = initState.detail
            state.isConfirmed = initState.isConfirmed
        },
        clearNeedsFormReset: (state) => {
            state.needsFormReset = false
        },
    },
})

export const {
    updateSfId,
    updateJobseekerDetail,
    confirmJobseeker,
    clearSfId,
    resetJobseeker,
    clearNeedsFormReset,
} = jobseekerSlice.actions

export const jobseekerReducer = jobseekerSlice.reducer

export const jobseekerSelector = (state) => state.jobseeker

export const getActiveJobseeker = (state) => state.jobseeker.detail

export const getActiveJobseekerId = (state) => state.jobseeker.sfId
