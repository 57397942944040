import { Stack } from '@chakra-ui/react'

import {
    HoikuEmployeesItem,
    HoikuHoursItem,
    HoikuItems,
    HoikuOfficeItem,
    HoikuUsersItem,
    FacilityCommonItems,
    FacilityModifiedDateItem,
    FacilityRecommendedPointItems,
} from '.'
import { useContext } from 'react'
import { ToggleViewingModeStateContext } from '@/components/Layout'

export const HoikuFacilityItems = () => {
    const { isJobseekerViewing } = useContext(ToggleViewingModeStateContext)

    return (
        <Stack>
            {/* Corporation */}
            <FacilityCommonItems />
            <HoikuOfficeItem />
            <HoikuHoursItem />
            <HoikuEmployeesItem />
            <HoikuUsersItem />
            <HoikuItems />
            <FacilityModifiedDateItem />
            {!isJobseekerViewing && <FacilityRecommendedPointItems />}
            {/* <TextComponent label="統一サービスタイプ名称">
                        {data.commonServiceType}
                    </TextComponent> */}
        </Stack>
    )
}
