import { useCallback, useMemo, useState } from 'react'

import { USE_SF_API } from '@/config'
// eslint-disable-next-line no-restricted-imports
import {
    useGetJobseekersQuery,
    useGetSfAccountDetailQuery,
    useLazyGetJobseekersQuery,
    useLazyGetSfAccountDetailQuery,
} from '@/features/jobseekers/api'

const useMergeJobseekers = (backendResult, sfResult) => {
    // どちらかが成功したら成功とする
    const mergedData = useMemo(
        () =>
            !backendResult.data && !sfResult.data
                ? undefined
                : {
                      ...backendResult.data,
                      ...sfResult.data,
                  },
        [backendResult.data, sfResult.data]
    )
    const mergedCurrentData = useMemo(
        () =>
            !backendResult.currentData && !sfResult.currentData
                ? undefined
                : {
                      ...backendResult.currentData,
                      ...sfResult.currentData,
                  },
        [backendResult.currentData, sfResult.currentData]
    )

    const mergedIsError = useMemo(
        () =>
            backendResult.isError &&
            (sfResult.isError || sfResult.data === undefined),
        [backendResult.isError, sfResult.isError, sfResult.data]
    )
    const mergedError = useMemo(
        () =>
            mergedIsError ? backendResult.error || sfResult.error : undefined,
        [backendResult.error, sfResult.error, mergedIsError]
    )

    /** @see https://redux-toolkit.js.org/rtk-query/api/created-api/hooks#signature-2 */
    return {
        originalArgs: backendResult.originalArgs || sfResult.originalArgs,
        data: mergedData,
        currentData: mergedCurrentData,
        error: mergedError,
        requestId: backendResult.requestId || sfResult.requestId,
        endpointName: backendResult.endpointName || sfResult.endpointName,
        startedTimeStamp:
            backendResult.startedTimeStamp || sfResult.startedTimeStamp,
        fulfilledTimeStamp:
            backendResult.fulfilledTimeStamp || sfResult.fulfilledTimeStamp,

        isUninitialized:
            backendResult.isUninitialized && sfResult.isUninitialized,
        isLoading: backendResult.isLoading && sfResult.isLoading,
        isFetching: backendResult.isFetching && sfResult.isFetching,
        isSuccess: backendResult.isSuccess || sfResult.isSuccess,
        isError: mergedIsError,
    }
}

/**
 * @param {string} sfId
 * @returns {import('@reduxjs/toolkit/query/react').TypedUseQueryStateResult<any>}
 */
export const useGetMergedJobseekerQuery = !USE_SF_API
    ? useGetJobseekersQuery
    : (sfId) => {
          const backendResult = useGetJobseekersQuery(sfId)
          const sfResult = useGetSfAccountDetailQuery(sfId)

          if (backendResult?.error?.status === 404) {
              backendResult.data = null
          }

          return useMergeJobseekers(backendResult, sfResult)
      }

/**
 * @param {string} sfId
 * @returns {[
 *     (sfId: string) => any,
 *     import('@reduxjs/toolkit/query/react').TypedUseQueryStateResult<any>
 * ]}
 */
export const useLazyGetMergedJobseekerQuery = !USE_SF_API
    ? useLazyGetJobseekersQuery
    : () => {
          // @ts-ignore
          const [fetchBackendJobseeker, backendResult] =
              useLazyGetJobseekersQuery()
          const [fetchSfJobseeker, sfResult] = useLazyGetSfAccountDetailQuery()

          const trigger = useCallback(
              (sfId) => {
                  const promise = Promise.allSettled([
                      fetchBackendJobseeker(sfId).unwrap(),
                      fetchSfJobseeker(sfId).unwrap(),
                  ]).then((results) => {
                      if (
                          results[0].status === 'rejected' &&
                          (results[1].status === 'rejected' ||
                              results[1].value === undefined)
                      ) {
                          return
                      }

                      // @ts-ignore
                      const [{ value: bkJs }, { value: sfJs }] = results
                      const jobseeker = {
                          ...bkJs,
                          ...sfJs,
                          source: { backend: !!bkJs, sf: !!sfJs },
                      } // 後勝ち
                      return jobseeker
                  })

                  return { unwrap: () => promise }
              },
              [fetchBackendJobseeker, fetchSfJobseeker]
          )

          const result = useMergeJobseekers(backendResult, sfResult)

          return [trigger, result]
      }
