import { Button, HStack, Input, Text } from '@chakra-ui/react'
import PropTypes from 'prop-types'
import { useState } from 'react'

export const InputSfid = ({ sfId, setSfId, next, disabled }) => {
    const [hasFocus, setHasFocus] = useState(false)

    return (
        <HStack>
            <Text>SFIDを入力</Text>
            <Input
                placeholder="SF12345678"
                value={sfId}
                onChange={(e) => {
                    const value = e.target.value
                        .trim()
                        .replace(
                            /[Ａ-Ｚａ-ｚ０-９]/g,
                            (c) => String.fromCharCode(c.charCodeAt(0) - 0xfee0) // 全角半角変換
                        )
                        .toUpperCase()
                    setSfId(value)
                    if (value.length === 10) {
                        setHasFocus(false)
                        next()
                    }
                }}
                onFocus={() => setHasFocus(true)}
                onBlur={() => setHasFocus(false)}
                name="inputsfid.field"
                disabled={disabled}
                textTransform="uppercase"
            />
            {(hasFocus || sfId.length !== 0) && (
                <Button
                    onClick={() => {
                        setSfId('')
                        setHasFocus(false)
                    }}
                >
                    ✖
                </Button>
            )}
        </HStack>
    )
}

InputSfid.propTypes = {
    sfId: PropTypes.string,
    setSfId: PropTypes.func.isRequired,
    next: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
}
