import {
    Button,
    AlertDialog,
    AlertDialogBody,
    AlertDialogHeader,
    AlertDialogOverlay,
    AlertDialogContent,
    AlertDialogCloseButton,
    AlertDialogFooter,
    HStack,
    VStack,
} from '@chakra-ui/react'
import PropTypes from 'prop-types'

import { JobseekerInfo } from '@/components/Elements'

export const SwitchSfIdDialog = ({
    jobseeker,
    leastDestructiveRef,
    onOk,
    onCancel,
    onClose,
    onSwitch,
    isOpen,
}) => {
    return (
        <AlertDialog
            size="4xl"
            isOpen={isOpen}
            onClose={onClose ?? onCancel}
            leastDestructiveRef={leastDestructiveRef}
        >
            <AlertDialogOverlay />
            <AlertDialogContent>
                <AlertDialogHeader textAlign="center" fontFamily="fonts">
                    {jobseeker?.sfId}様の検索画面に移行しますか?
                </AlertDialogHeader>
                <AlertDialogCloseButton onClick={onClose ?? onCancel} />
                <AlertDialogBody margin="auto">
                    <JobseekerInfo jobseeker={jobseeker} />
                </AlertDialogBody>
                <AlertDialogFooter>
                    <HStack marginRight="70px">
                        <Button autoFocus onClick={onOk} width="200px">
                            OK
                        </Button>
                        <Button onClick={onCancel} width="200px">
                            キャンセル
                        </Button>
                    </HStack>
                    <Button onClick={onSwitch} color="gray" minWidth="150px">
                        SFIDを切り替える
                    </Button>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    )
}

SwitchSfIdDialog.propTypes = {
    jobseeker: PropTypes.shape({
        sfId: PropTypes.string,
        prefecture: PropTypes.string,
        city: PropTypes.string,
        sex: PropTypes.string,
        age: PropTypes.number,
    }).isRequired,
    leastDestructiveRef: PropTypes.shape({
        current: PropTypes.any,
    }),
    onClose: PropTypes.func,
    onOk: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSwitch: PropTypes.func.isRequired,
    isOpen: PropTypes.bool.isRequired,
}
