import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalCloseButton,
} from '@chakra-ui/react'
import { Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react'
import PropTypes from 'prop-types'

import { CreateMaintenanceContainer } from '../components/CreateMaintenance'
import { CreateAnnouncementContainer } from './CreateAnnouncement'

/**
 * @type {import('react').FC<{
 *     isOpen: boolean
 *     onClose: () => void | Promise<void>
 *     notification: any
 * }>}
 */
export const NotificationModal = ({ isOpen, onClose, notification }) => {
    // 編集モードかどうか
    const isEditMode = notification !== null && notification !== undefined

    // メンテナンスかどうか
    const isMaintenance = notification?.__t === 'Maintenance'

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="4xl">
            <ModalOverlay />
            <ModalContent>
                <ModalCloseButton />
                <Tabs
                    variant="enclosed"
                    padding="8px"
                    colorScheme="primary"
                    defaultIndex={isMaintenance ? 1 : 0}
                >
                    <TabList borderColor="#a9a9a9">
                        <Tab isDisabled={isEditMode && isMaintenance}>
                            お知らせ
                        </Tab>
                        <Tab isDisabled={isEditMode && !isMaintenance}>
                            メンテナンス予定
                        </Tab>
                    </TabList>
                    <TabPanels>
                        <TabPanel>
                            <CreateAnnouncementContainer
                                onSuccess={onClose}
                                onCancel={onClose}
                                initAnnouncement={
                                    isEditMode && !isMaintenance
                                        ? notification
                                        : null
                                }
                            />
                        </TabPanel>
                        <TabPanel>
                            <CreateMaintenanceContainer
                                onClose={onClose}
                                initMaintenance={
                                    isEditMode && isMaintenance
                                        ? notification
                                        : null
                                }
                            />
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </ModalContent>
        </Modal>
    )
}

NotificationModal.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    notification: PropTypes.any,
}
