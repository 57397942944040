export default function getSearchRange(workingTime) {
    const [hour, minute] = workingTime.split(':')
    if (minute !== '00') {
        if (minute < '16') {
            return `${hour}:01～${hour}:15`
        } else if (minute < '31') {
            return `${hour}:16～${hour}:30`
        } else if (minute < '46') {
            return `${hour}:31～${hour}:45`
        }
    }
    if (minute === '00') {
        return `${
            hour === '00'
                ? '23'
                : (Number(hour) - 1).toString().padStart(2, '0')
        }:46～${hour}:00`
    }
    // 46～59
    return `${hour}:46～${
        hour === '23' ? '00' : (Number(hour) + 1).toString().padStart(2, '0')
    }:00`
}

export const hourFormat = '[01][0-9]|2[0-3]'
export const minuteFormat = '[0-5][0-9]'
export const timeRegExp = new RegExp(`^(${hourFormat}):(${minuteFormat})$`)
