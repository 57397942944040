/* eslint-disable react/prop-types */
import {
    Button as ChakraButton,
    Input as ChakraInput,
    InputGroup,
    InputRightAddon,
    InputLeftAddon,
    Select as ChakraSelect,
    FormControl,
    FormLabel,
    Checkbox as ChakraCheckbox,
    Text,
} from '@chakra-ui/react'
import { joiResolver } from '@hookform/resolvers/joi'
import { useForm, FormProvider, useFormContext } from 'react-hook-form'

/**
 * @type {React.FC<{
 *     onSubmit?: (data: any) => void
 *     createOnSubmit?: (
 *         methods: import('react-hook-form').UseFormReturn
 *     ) => (data: any) => void
 *     validator?: any
 *     children: React.ReactNode | React.ReactNode[]
 *     defaultValues: any
 * }> & {
 *     Button: typeof Button
 *     Input: typeof Input
 *     DateInput: typeof DateInput
 *     Select: typeof Select
 *     CheckBox: typeof CheckBox
 *     Control: typeof Control
 *     Label: typeof Label
 * }}
 */
export const NotificationForm = ({
    onSubmit,
    createOnSubmit,
    validator,
    children,
    defaultValues,
}) => {
    const methods = useForm({
        resolver: joiResolver(validator),
        defaultValues,
    })
    const { handleSubmit } = methods
    return (
        <form onSubmit={handleSubmit(onSubmit ?? createOnSubmit(methods))}>
            <FormProvider {...methods}>{children}</FormProvider>
        </form>
    )
}

/**
 * @type {React.FC<
 *     {
 *         type: 'button' | 'submit' | 'reset'
 *         disabled?: boolean
 *         onClick?: (
 *             e: import('react').MouseEvent<HTMLButtonElement>
 *         ) => Promise<void> | void
 *         children: React.ReactNode
 *     } & import('@chakra-ui/react').ButtonProps
 * >}
 */
const Button = ({ type, disabled, onClick, children, ...props }) => {
    const {
        formState: { isSubmitting },
    } = useFormContext()
    return (
        <ChakraButton
            type={type}
            disabled={disabled || (type === 'submit' && isSubmitting)}
            onClick={onClick}
            {...props}
        >
            {children}
        </ChakraButton>
    )
}

/**
 * @type {React.FC<
 *     {
 *         name: string
 *         type: string
 *         right?: React.ReactNode
 *         left?: React.ReactNode
 *     } & import('@chakra-ui/react').InputProps
 * >}
 */
const Input = ({ name, type, right, left, ...props }) => {
    const {
        register,
        formState: { errors },
    } = useFormContext()
    return (
        <FormControl>
            <InputGroup>
                {left && <InputLeftAddon>{left}</InputLeftAddon>}
                <ChakraInput type={type} {...props} {...register(name)} />
                {right && <InputRightAddon>{right}</InputRightAddon>}
            </InputGroup>
            {errors[name] && <Text color="red">{errors[name].message}</Text>}
        </FormControl>
    )
}

/**
 * @type {React.FC<
 *     {
 *         name: string
 *         type: string
 *     } & import('@chakra-ui/react').InputProps
 * >}
 */
const DateInput = ({ name, type, ...props }) => {
    const {
        register,
        formState: { errors },
    } = useFormContext()

    return (
        <FormControl>
            <InputGroup>
                <ChakraInput type={type} {...props} {...register(name)} />
            </InputGroup>
            {errors[name] && <Text color="red">{errors[name].message}</Text>}
        </FormControl>
    )
}

/**
 * @type {React.FC<
 *     {
 *         name: string
 *         options?: { label?: string; value: string }[]
 *         children?: React.ReactNode[]
 *     } & import('@chakra-ui/react').SelectProps
 * >}
 */
const Select = ({ name, options = [], children = [], ...props }) => {
    const { register } = useFormContext()
    return (
        <ChakraSelect {...register(name)} {...props}>
            {options.map(({ label, value }) => (
                <option key={value} value={value}>
                    {label ?? value}
                </option>
            ))}
            {children}
        </ChakraSelect>
    )
}

/**
 * @type {React.FC<{
 *     name: string
 *     children?: React.ReactNode | React.ReactNode[]
 * }>}
 */
const CheckBox = ({ name, children }) => {
    const { register } = useFormContext()
    return <ChakraCheckbox {...register(name)}>{children}</ChakraCheckbox>
}

/**
 * Registerでコントロールしているので非推奨
 *
 * @deprecated
 * @type {React.FC<{
 *     children?: React.ReactNode | React.ReactNode[]
 *     isRequired?: boolean
 * }>}
 */
const Control = ({ children, ...props }) => {
    return (
        <FormControl
            {...props}
            display="flex"
            whiteSpace="nowrap"
            alignItems="center"
            marginBottom="8px"
        >
            {children}
        </FormControl>
    )
}

/**
 * @type {React.FC<{
 *     children?: React.ReactNode | React.ReactNode[]
 *     [key: string]: any
 * }>}
 */
const Label = ({ children, ...props }) => {
    return (
        <FormLabel {...props} minWidth="120px">
            {children}
        </FormLabel>
    )
}

NotificationForm.Button = Button
NotificationForm.Input = Input
NotificationForm.DateInput = DateInput
NotificationForm.Select = Select
NotificationForm.CheckBox = CheckBox
NotificationForm.Control = Control
NotificationForm.Label = Label
