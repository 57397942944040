import site from 'shared/utils/site'

//雇用形態選択肢【介護、保育、看護は共通】
export const kaigoEmploymentChoices = [
    { label: '正社員', value: '正社員' },
    { label: 'パート', value: 'パート' },
    { label: '派遣', value: '派遣' },
    { label: '応援', value: '応援' },
    { label: '契約社員', value: '契約社員' },
    { label: 'その他', value: 'その他' },
    { label: '委託', value: '委託' },
    { label: '紹介予定派遣', value: '紹介予定派遣' },
]

export const posEmploymentChoices = [
    { label: '正社員', value: '正社員' },
    { label: '契約社員', value: '契約社員' },
    { label: 'パート', value: 'パート' },
    { label: '派遣', value: '派遣' },
    { label: '応援', value: '応援' },
]

export const employmentChoices = site.isPos()
    ? posEmploymentChoices
    : kaigoEmploymentChoices
