import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import qs from 'shared/utils/qs'

import {
    SF_API_URL,
    SF_CLIENT_ID,
    SF_CLIENT_SECRET,
    SF_REFRESH_TOKEN,
    USE_SF_API,
} from '@/config'
import { getUser } from '@/features/auth'

const setAccessToken = (accessToken) => {
    sessionStorage.setItem('access_token', accessToken)
}

const getAccessToken = () => {
    const accessToken = sessionStorage.getItem('access_token')
    return accessToken
}

const clearAccessToken = () => {
    sessionStorage.removeItem('access_token')
}

const baseQuery = fetchBaseQuery({
    baseUrl: SF_API_URL,
    prepareHeaders: (headers) => {
        const accessToken = getAccessToken()
        if (accessToken) {
            headers.set('authorization', `Bearer ${accessToken}`)
        }
        return headers
    },
    paramsSerializer: (params) => {
        return qs.stringify(params)
    },
})

const baseQueryWithAuth = async (args, api, extraOptions) => {
    const original = await baseQuery(args, api, extraOptions)
    if (
        !original.error ||
        (original.error.status !== 401 &&
            original.error.status !== 'FETCH_ERROR')
    ) {
        return original
    }
    const grantType = 'refresh_token'
    const clientId = SF_CLIENT_ID
    const clientSecret = SF_CLIENT_SECRET
    const refreshToken = SF_REFRESH_TOKEN
    const response = await baseQuery(
        {
            url: '/oauth2/token',
            method: 'POST',
            params: {
                grant_type: grantType,
                client_id: clientId,
                client_secret: clientSecret,
                refresh_token: refreshToken,
            },
        },
        api,
        extraOptions
    )
    const accessToken = response?.data?.access_token
    if (accessToken) {
        setAccessToken(accessToken)
    } else {
        clearAccessToken()
    }
    return await baseQuery(args, api, extraOptions)
}

export const sfApi = createApi({
    reducerPath: 'sfApi',
    baseQuery: baseQueryWithAuth,
    endpoints: (builder) => ({
        getMe: builder.query({
            queryFn: async (args, api, extraOptions, baseQuery) => {
                if (USE_SF_API) {
                    const user = getUser()
                    const userId = user.profile.user_id
                    // const userId = '0050o00000Yq7XGAAZ'
                    const result = await baseQuery({
                        url: `/data/v47.0/sobjects/User/${userId}`,
                    })
                    return result
                }
                return {
                    data: {
                        Id: '0050o00000Yq7XGAAZ',
                    },
                }
            },
        }),
    }),
})
