import { Stack, Text } from '@chakra-ui/react'
import PropTypes from 'prop-types'
import { useContext } from 'react'
import { ToggleViewingModeStateContext } from '../Layout'

/**
 * @typedef {import('@/types/api').components} ApiComponents
 *
 * @typedef {ApiComponents['schemas']['Jobseeker']} Jobseeker
 * @param {Object} props
 * @param {Jobseeker} props.jobseeker
 */
export const JobseekerInfo = ({ jobseeker }) => {
    const { isJobseekerViewing } = useContext(ToggleViewingModeStateContext)

    return jobseeker ? (
        <Stack>
            {!isJobseekerViewing && (
                <>
                    <Text>
                        住所：{jobseeker.prefecture}
                        {jobseeker.city}
                    </Text>
                    <Text>年齢：{jobseeker.age || '未回答'}</Text>
                    <Text>性別：{jobseeker.sex || '未回答'}</Text>
                </>
            )}
        </Stack>
    ) : null
}

JobseekerInfo.propTypes = {
    jobseeker: PropTypes.object.isRequired,
}
