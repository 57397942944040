import { Container } from '@chakra-ui/react'
import PropTypes from 'prop-types'

import { Head } from '../Head'

const ContentLayout = ({
    title,
    errorTitle,
    fluid = false,
    searchResult = false,
    children,
}) => {
    return (
        <Container
            flexGrow={1}
            maxW={fluid ? 'full' : searchResult ? '90vw' : '1200px'}
        >
            <Head title={title} errorTitle={errorTitle} />
            {children}
        </Container>
    )
}

ContentLayout.propTypes = {
    title: PropTypes.string,
    errorTitle: PropTypes.string,
    fluid: PropTypes.bool,
    searchResult: PropTypes.bool,
    children: PropTypes.node,
}

export { ContentLayout }
