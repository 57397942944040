import Joi from 'joi'

import objectId from './extensions/objectid.js'
import populated from './extensions/populated.js'
import ja from './locales/ja/index.js'

export const joi = Joi.defaults((schema) => {
    return schema.options({
        messages: {
            // @ts-ignore
            ja,
        },
        errors: {
            language: 'ja',
        },
        abortEarly: false,
    })
}).extend(objectId, populated, {
    type: /^(string|number)$/,
    prepare: (value, helpers) => {
        // console.log(
        //     'prepare',
        //     value,
        //     helpers,
        //     helpers.schema.$_getFlag('value')
        // )
        if (helpers.schema.$_getFlag('value')) {
            return { value: value?.value }
        }
    },
    rules: {
        value: {
            method() {
                return this.$_setFlag('value', true)
            },
        },
    },
})
