import { Heading } from '@chakra-ui/react'
import PropTypes from 'prop-types'

export const RecruitmentItem = ({ children }) => {
    return (
        <Heading width="150px" size="sm" color="grey">
            {children}
        </Heading>
    )
}
RecruitmentItem.propTypes = {
    children: PropTypes.string.isRequired,
}
