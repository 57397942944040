// see: https://redux-toolkit.js.org/api/createEntityAdapter

import { createSlice, createEntityAdapter } from '@reduxjs/toolkit'

/**
 * @typedef {object} Notification
 * @property {string} id ID
 * @property {boolean} read 既読
 * @property {number} timestamp 日時
 * @property {any} data
 */

/** @type {ReturnType<typeof createEntityAdapter<Notification>>} */
const notificationsAdapter = createEntityAdapter({
    selectId: (notification) => notification.id,
    sortComparer: (a, b) => b.timestamp - a.timestamp,
})

const initialState = notificationsAdapter.getInitialState()

export const notificationsSlice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        // 特定の通知を削除
        dismissNotification: (state, action) => {
            return notificationsAdapter.removeOne(state, action.payload)
        },
        // 特定の通知を既読に変更
        readNotification: (state, action) => {
            return notificationsAdapter.updateOne(state, {
                id: action.payload,
                changes: {
                    read: true,
                },
            })
        },
        putNotification: (state, action) => {
            return notificationsAdapter.upsertOne(state, {
                ...action.payload,
                timestamp: Date.now(),
            })
        },
    },
})

export const { dismissNotification, readNotification, putNotification } =
    notificationsSlice.actions

export const notificationsReducer = notificationsSlice.reducer

export const notificationsSelectors = notificationsAdapter.getSelectors(
    (state) => state.notifications
)
