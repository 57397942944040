import { Stack } from '@chakra-ui/react'
import { useContext } from 'react'
import { HoikuServiceCode, KaigoServiceCode } from 'shared/utils/serviceCode'

import { FacilityContext } from '..'

import {
    HoikuFacilityItems,
    KaigoFacilityItems,
    KangoItems,
    KangoOfficeItem,
    FacilityCommonItems,
    FacilityModifiedDateItem,
    FacilityRecommendedPointItems,
} from '.'
import { ToggleViewingModeStateContext } from '@/components/Layout'

export const KangoFacilityItems = () => {
    const data = useContext(FacilityContext)
    const { isJobseekerViewing } = useContext(ToggleViewingModeStateContext)

    if (KaigoServiceCode(data.serviceCode)) {
        return <KaigoFacilityItems />
    }
    if (HoikuServiceCode(data.serviceCode)) {
        return <HoikuFacilityItems />
    } else
        return (
            <>
                <Stack>
                    {/* Corporation */}
                    <FacilityCommonItems />
                    <KangoOfficeItem />
                    <KangoItems />
                    <FacilityModifiedDateItem />
                    {!isJobseekerViewing && <FacilityRecommendedPointItems />}
                    {/* <TextComponent label="統一サービスタイプ名称">
                        {data.commonServiceType}
                    </TextComponent> */}
                </Stack>
            </>
        )
}
