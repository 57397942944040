import chunk from 'lodash.chunk'

import { USE_SF_API } from '@/config'
import { backendApi } from '@/stores/backendApi'
import { sfApi } from '@/stores/sfApi'

// TODO: 営業所管理APIに移動したい
export const highPerformerApi = backendApi.injectEndpoints({
    endpoints: (builder) => {
        return {
            listHighPerformers: builder.query({
                query: (params) => ({
                    url: '/v1/business-offices',
                    params,
                }),
                providesTags: () => ['BusinessOffice'],
            }),
            putHighPerformer: builder.mutation({
                query: ({ _id, userId }) => ({
                    url: `/v1/business-offices/${_id}/hp/${userId}`,
                    method: 'PUT',
                }),
                invalidatesTags: ['BusinessOffice'],
            }),
            removeHighPerformer: builder.mutation({
                query: ({ _id, userId }) => ({
                    url: `/v1/business-offices/${_id}/hp/${userId}`,
                    method: 'DELETE',
                }),
                invalidatesTags: ['BusinessOffice'],
            }),
            getMyFeedbackTask: builder.query({
                query: (date) => ({
                    url: `/v1/feedback-tasks/me/${date}`,
                }),
                keepUnusedDataFor: 0,
            }),
            updateMyFeedbackTask: builder.mutation({
                query: ({ _id, value }) => ({
                    url: `/v1/feedback-tasks/me/${_id}`,
                    method: 'PUT',
                    body: {
                        value,
                    },
                }),
            }),
        }
    },
    overrideExisting: false,
})

export const userApi = sfApi.injectEndpoints({
    endpoints: (builder) => {
        return {
            searchUsers: builder.query({
                queryFn: async (
                    { q, office },
                    api,
                    extraOptions,
                    baseQuery
                ) => {
                    // console.log('queryFn', { q, office })
                    const LIMIT = 200,
                        PER_PAGE = 10,
                        FIELDS = [
                            'Name',
                            'Email',
                            'Department',
                            'Title',
                            'SalesOfficeName__c',
                            'region__c',
                            'RollName__c',
                            'IsActive',
                        ]
                    if (USE_SF_API) {
                        const where = (str = '') =>
                            str ? ` WHERE SalesOfficeName__c = '${str}'` : ''
                        // キーワード検索
                        if (q) {
                            const query = `FIND {${q.replace(
                                /[?&|!{}[\]()^~*:\\"'+-]/g,
                                '\\$&'
                            )}} IN ALL FIELDS RETURNING User(${FIELDS.join(
                                ','
                            )}${where(office)}) LIMIT ${LIMIT}`
                            const result = await baseQuery({
                                url: '/data/v47.0/search',
                                params: {
                                    q: query,
                                },
                            })
                            if (result.data) {
                                return {
                                    data: chunk(
                                        result.data.searchRecords,
                                        PER_PAGE
                                    ),
                                }
                            }
                            return result
                        }
                        // キーワードなし検索
                        const query = `SELECT ${FIELDS.join(
                            ','
                        )} FROM User${where(office)} LIMIT ${LIMIT}`
                        const result = await baseQuery({
                            url: '/data/v47.0/query',
                            params: {
                                q: query,
                            },
                        })
                        if (result.data) {
                            return {
                                data: chunk(result.data.records, PER_PAGE),
                            }
                        }
                        return result
                    }
                    return {
                        data: chunk(
                            Array.from({ length: LIMIT }, (_, i) => ({
                                Name: `テスト太郎(${i + 1})`,
                                Email: `m.nadai${i + 1}@a-sw.jp.invalid`,
                                Department: '開発',
                                Title: null,
                                SalesOfficeName__c: 'ASW',
                                region__c: null,
                                RollName__c: null,
                                IsActive: i % 2 === 0 ? true : false,
                            })),
                            PER_PAGE
                        ),
                    }
                },
            }),
        }
    },
    overrideExisting: false,
})
