import { Heading as ChakraHeading, Flex, Text } from '@chakra-ui/react'
import PropTypes from 'prop-types'

export const FacilityHeading = ({ children, props }) => {
    return (
        <Flex {...props}>
            <Text border="1.5px solid" bg="#3CB37A" color="#3CB37A" />
            <ChakraHeading fontSize="20px" fontWeight="bold" pl="15px">
                {children}
            </ChakraHeading>
        </Flex>
    )
}

FacilityHeading.propTypes = {
    children: PropTypes.string.isRequired,
    props: PropTypes.any,
}
