import { badgeTheme } from './badge'

export const tagTheme = {
    variants: {
        subtle: {
            container: badgeTheme.variants.subtle,
        },
    },
    fontFamily: 'fonts',
}
