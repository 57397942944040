// see: https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/theme/src/components/input.ts
// see: https://zenn.dev/tokutsu/articles/edd55fd6c0e7a9

import { floatingControlHeight } from './form'

export const inputTheme = {
    baseStyle: {
        field: {
            _disabled: {
                bg: '#EEF2F8',
            },
            _groupDisabled: {
                opacity: 1,
            },
        },
    },
    variants: {
        outline: {
            field: {
                bg: 'white',
            },
        },
    },
    sizes: {
        md: {
            field: {
                fontSize: 'sm',
                fontFamily: 'fonts',
            },
        },
        floating: {
            field: {
                h: floatingControlHeight,
                fontFamily: 'fonts',
                borderRadius: 'md',
                fontSize: '14px',
                px: '8.5px',
                py: '16px',
            },
        },
    },
    defaultProps: {
        focusBorderColor: 'primary.500',
        fontFamily: 'fonts',
    },
}
