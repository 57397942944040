import { ContentLayout } from '@/components/Layout'
import { SearchTimesTable } from '../components/SearchTimesTable'

export const SearchTimes = () => {
    return (
        <div style={{ marginBottom: '30px' }}>
            <ContentLayout title="検索回数">
                <SearchTimesTable />
            </ContentLayout>
        </div>
    )
}
