// see: https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/theme/src/components/select.ts
import { inputTheme } from './input'

export const selectTheme = {
    sizes: {
        md: {
            field: {
                ...inputTheme.sizes.md.field,
            },
        },
    },
    fontFamily: 'fonts',
    variants: inputTheme.variants,
    defaultProps: inputTheme.defaultProps,
}
