import { Link as ChakraLink, forwardRef } from '@chakra-ui/react'
import PropTypes from 'prop-types'

export const ExternalLink = forwardRef(function ExternalLink(
    { href, children, ...props },
    ref
) {
    return (
        <ChakraLink href={href} isExternal {...props}>
            {children}
        </ChakraLink>
    )
})

ExternalLink.propTypes = {
    href: PropTypes.string,
    children: PropTypes.node.isRequired,
}
