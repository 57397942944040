import { Box } from '@chakra-ui/react'
import PropTypes from 'prop-types'
import { createContext, useContext, useEffect, useMemo, useState } from 'react'

import { useUpdate } from './useTasks'

/**
 * @type {import('react').Context<{
 *     id: string
 *     item: import('./useTasks').Task
 *     moveStart: (update: Function | object) => void
 *     onUpdate: (update: Function | object) => void
 * }>}
 */
const TaskContext = createContext(undefined)

export const useTaskContext = () => {
    return useContext(TaskContext)
}

const Wrapper = ({ children }) => {
    return (
        <Box
            // my="2"
            w="full"
            maxH="100vh"
            transitionProperty="max-height, opacity"
            transitionDuration="0.35s"
            sx={{
                '.task-hidden & ': {
                    overflow: 'hidden',
                    maxH: 0,
                    opacity: 0,
                },
            }}
        >
            {children}
        </Box>
    )
}
Wrapper.propTypes = {
    children: PropTypes.node.isRequired,
}

export const TaskProvider = ({ id, item, children }) => {
    const [isVisible, setIsVisible] = useState(true)
    const onUpdate = useUpdate(id)
    const ctx = useMemo(() => {
        return {
            item,
            id,
            moveStart: (/** @type {() => {} | {}} */ update) => {
                setIsVisible(false)
                setTimeout(() => {
                    onUpdate(update, true)
                }, 350)
            },
            onUpdate: (/** @type {() => {} | {}} */ update) => {
                onUpdate(update)
            },
        }
    }, [setIsVisible, item, id])
    useEffect(() => {
        if (item.isDone) {
            setIsVisible(true)
        }
    }, [item.isDone])
    return (
        <TaskContext.Provider value={ctx}>
            <Box
                w="full"
                className={
                    !isVisible
                        ? 'task-hidden'
                        : item.isDone
                        ? 'task-done'
                        : undefined
                }
            >
                <Wrapper>{children}</Wrapper>
            </Box>
        </TaskContext.Provider>
    )
}
TaskProvider.propTypes = {
    children: PropTypes.node.isRequired,
    id: PropTypes.string.isRequired,
    item: PropTypes.object.isRequired,
}
