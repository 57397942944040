import { joi } from '../joi.js'

const toSortDirection = (value, helpers) => {
    if (value ?? true) {
        return value
    }
    if (value === 'asc') {
        return 1
    }
    if (value === 'desc') {
        return -1
    }
    return helpers.error('any.invalid')
}

export const requestValidator = joi.object({
    params: joi
        .object({
            version: joi.number().integer().required(),
        })
        .required(),
    query: joi.object().required(),
    body: joi.object().required(),
})

export const pagingValidator = joi.object({
    query: {
        limit: joi.number().integer().default(20),
        page: joi.number().integer(),
        sort: joi.string().empty(''),
        direction: joi
            .string()
            .empty(null)
            .custom(toSortDirection)
            .when('sort', {
                is: joi.not('').exist(),
                then: joi.required().valid('asc', 'desc', '1', '-1'),
            }),
    },
})
