import jwtDecode from 'jwt-decode'
import { User } from 'oidc-client-ts'
import generateUserId from 'shared/utils/auth'

import { OIDC_AUTHORITY, OIDC_CLIENT_ID, OIDC_REDIRECT_URI } from '@/config'

const KEY = 'redirect'
export const setRedirectPath = (value) => sessionStorage.setItem(KEY, value)
export const getRedirectPath = () => sessionStorage.getItem(KEY)
export const removeRedirectPath = () => sessionStorage.removeItem(KEY)

export const getUser = () => {
    const oidcStorage = sessionStorage.getItem(
        `oidc.user:${OIDC_AUTHORITY}:${OIDC_CLIENT_ID}`
    )
    if (!oidcStorage) {
        return null
    }
    return User.fromStorageString(oidcStorage)
}

export const getUserId = () => {
    const user = getUser()
    const { id_token } = user
    const decodedToken = jwtDecode(id_token)
    const email = decodedToken.email || decodedToken.preferred_username
    return generateUserId(email)
}

export const singleLogout = () => {
    const user = getUser()
    const { id_token } = user
    setRedirectPath('/logout')
    window.location.href = `${OIDC_AUTHORITY}/protocol/openid-connect/logout?id_token_hint=${id_token}&post_logout_redirect_uri=${OIDC_REDIRECT_URI}`
}
