import { Box, Text } from '@chakra-ui/react'

const Footer = () => (
    <Box
        bg="#eceff1"
        paddingTop="16px"
        paddingBottom="16px"
        textAlign="center"
        sx={{
            '@media print': {
                display: 'none',
            },
        }}
    >
        <Text fontSize="12px">Copyright © TRYT All rights reserved.</Text>
    </Box>
)

export { Footer }
