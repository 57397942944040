import { revisionSelector, setNextRevisionCheckTime, updateRevisionDialogIsOpen } from '@/stores'
import {
    Button,
    AlertDialog,
    AlertDialogBody,
    AlertDialogHeader,
    AlertDialogOverlay,
    AlertDialogContent,
    AlertDialogCloseButton,
    AlertDialogFooter,
    HStack,
    useDisclosure,
    Center
} from '@chakra-ui/react'
import PropTypes from 'prop-types'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'


export const RevisonCheckDialogDialog = ({
    leastDestructiveRef,
}) => {
    const { revisionDialogIsOpen } = useSelector(revisionSelector)
    const { onClose } = useDisclosure()
    const dispatch = useDispatch()

    const onOk = useCallback(() => {
        setNextRevisionCheckTime(60)
        window.location.reload()
    }, [])

    const onCancel = useCallback(() => {
        dispatch(updateRevisionDialogIsOpen({ revisionDialogIsOpen: false }))
        setNextRevisionCheckTime(60)
        onClose()
    }, [onClose])

    return (
        <AlertDialog
            size="4xl"
            isOpen={revisionDialogIsOpen}
            onClose={onCancel}
            leastDestructiveRef={leastDestructiveRef}
        >
            <AlertDialogOverlay />
            <AlertDialogContent>
                <AlertDialogHeader textAlign="center">
                    レコメンドシステム最新バージョンがリリースされました。
                </AlertDialogHeader>
                <AlertDialogCloseButton onClick={onCancel} />
                <AlertDialogBody textAlign="center">
                    画面を再読込みしてもよろしいですか？
                </AlertDialogBody>
                <AlertDialogFooter>
                    <Center h='100%' width="100%">
                        <HStack spacing='8px'>
                            <Button autoFocus onClick={onOk} width="200px">
                                はい
                            </Button>
                            <Button onClick={onCancel} width="200px">
                                いいえ
                            </Button>
                        </HStack>
                    </Center>
                </AlertDialogFooter>
            </AlertDialogContent>
        </AlertDialog>
    )
}

RevisonCheckDialogDialog.propTypes = {
    leastDestructiveRef: PropTypes.shape({
        current: PropTypes.any,
    }),
}
