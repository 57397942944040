import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons'
import {
    Button,
    Spacer,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    ModalCloseButton,
    Input,
    useDisclosure,
    Link,
    Text,
    Box,
    IconButton,
} from '@chakra-ui/react'
import { format } from 'date-fns'
import JSZip from 'jszip'
import chunk from 'lodash.chunk'
import { useRef, useState, useEffect, useMemo } from 'react'
import * as XLSX from 'xlsx'

import { useGetKpiItemsQuery } from '..'

import { Pagination, Table, TableContainer } from '@/components/Elements'
import { SITE } from '@/config'
import { Section, SectionTitle } from '@/features/dashboard'

export const Kpi = () => {
    const dashboardColumns = [
        {
            Header: () => {
                return 'KPI'
            },
            accessor: 'section',
            headerClassName: 'narrow-kpi',
        },
        {
            Header: () => {
                return (
                    <>
                        <Text>日次</Text>
                        {daily && <Text fontSize="xs">{daily}</Text>}
                    </>
                )
            },
            accessor: 'daily',
            cellClassName: 'right',
            Cell: ({ value, row }) => {
                if (value === null) return '-'
                else if (row.original.unit === '%') {
                    return `${Math.floor(value * 100)}${row.original.unit}`
                } else return `${value}${row.original.unit}`
            },
        },
        {
            Header: () => {
                return (
                    <>
                        <Text>週次</Text>
                        {weekly && <Text fontSize="xs">{weekly}</Text>}
                    </>
                )
            },
            accessor: 'weekly',
            cellClassName: 'right',
            Cell: ({ value, row }) => {
                const { isOpen, onOpen, onClose } = useDisclosure()
                const ref = useRef(null)
                if (value === null) return '-'
                else if (
                    value !== 0 &&
                    (row.original.id === 5 ||
                        row.original.id === 6 ||
                        row.original.id === 8)
                ) {
                    return (
                        <>
                            <Link
                                color="primary.500"
                                type="button"
                                as="button"
                                onClick={onOpen}
                            >
                                {`${value}${row.original.unit}`}
                            </Link>
                            <Modal isOpen={isOpen} onClose={onClose} size="4xl">
                                <ModalOverlay />
                                <ModalContent minW="1050px">
                                    <Box
                                        ref={ref}
                                        scrollMarginTop="var(--header-height)"
                                    >
                                        <ModalCloseButton />
                                        <ModalBody
                                            bg="primary.50"
                                            p={6}
                                            margin="40px"
                                        >
                                            <Text
                                                as="h5"
                                                textStyle="h2"
                                                color="black"
                                                mb="8px"
                                            >
                                                {`${row.original.section}`.slice(
                                                    0,
                                                    -1
                                                )}
                                                一覧（{weekly}）
                                            </Text>
                                            <UserList
                                                weekCompData={weekCompData}
                                                id={row.original.id}
                                                type="weekly"
                                                weekNoSearchData={
                                                    weekNoSearchData
                                                }
                                            />
                                        </ModalBody>
                                    </Box>
                                </ModalContent>
                            </Modal>
                        </>
                    )
                } else if (row.original.unit === '%') {
                    return `${Math.floor(value * 100)}${row.original.unit}`
                } else return `${value}${row.original.unit}`
            },
        },
        {
            Header: () => {
                return (
                    <>
                        <Text>月次</Text>
                        {monthly && <Text fontSize="xs">{monthly}</Text>}
                    </>
                )
            },
            accessor: 'monthly',
            cellClassName: 'right',
            Cell: ({ value, row }) => {
                const ref = useRef(null)
                const { isOpen, onOpen, onClose } = useDisclosure()
                if (value === null) return '-'
                else if (
                    value !== 0 &&
                    (row.original.id === 5 ||
                        row.original.id === 7 ||
                        row.original.id === 9)
                ) {
                    return (
                        <>
                            <Link
                                color="primary.500"
                                type="button"
                                as="button"
                                onClick={onOpen}
                            >
                                {`${value}人`}
                            </Link>
                            <Modal isOpen={isOpen} onClose={onClose} size="4xl">
                                <ModalOverlay />
                                <ModalContent minW="1050px">
                                    <Box
                                        ref={ref}
                                        scrollMarginTop="var(--header-height)"
                                    >
                                        <ModalCloseButton />
                                        <ModalBody
                                            bg="primary.50"
                                            p={6}
                                            margin="40px"
                                        >
                                            <Text
                                                as="h5"
                                                textStyle="h2"
                                                color="black"
                                                mb="8px"
                                            >
                                                {`${row.original.section}`.slice(
                                                    0,
                                                    -1
                                                )}
                                                一覧（{monthly}）
                                            </Text>
                                            <UserList
                                                monthCompData={monthCompData}
                                                id={row.original.id}
                                                type="monthly"
                                                monthNoSearchData={
                                                    monthNoSearchData
                                                }
                                            />
                                        </ModalBody>
                                    </Box>
                                </ModalContent>
                            </Modal>
                        </>
                    )
                } else if (row.original.unit === '%') {
                    return `${Math.floor(value * 100)}${row.original.unit}`
                } else return `${value}${row.original.unit}`
            },
        },
    ]

    //データ(仮)
    const dataFrame = [
        {
            id: 1,
            section: '提案まで至ったユーザーの数',
            unit: '人',
        },
        {
            id: 2,
            section: '発行済みアカウント数',
            unit: '件',
        },
        {
            id: 3,
            section: 'ログインユーザー数',
            unit: '人',
        },
        {
            id: 4,
            section: '一回以上検索したユーザー数',
            unit: '人',
        },
        {
            id: 5,
            section: 'ログイン履歴有だが、検索数0のユーザ数',
            unit: '人',
        },
        {
            id: 6,
            section: '前週に5回以上検索したが、今週は1以下になったユーザー数',
            unit: '人',
        },
        {
            id: 7,
            section: '前月に20回以上検索したが、今月は5以下になったユーザー数',
            unit: '人',
        },
        {
            id: 8,
            section: '前週に1回以下検索、今週は5以上になったユーザー数',
            unit: '人',
        },
        {
            id: 9,
            section: '前月に5回以下検索、今月は20以上になったユーザー数',
            unit: '人',
        },
        {
            id: 10,
            section: '総検索数',
            unit: '回',
        },
        {
            id: 11,
            section: '一人当たりの検索数',
            unit: '回',
        },
        {
            id: 12,
            section: '提案まで出来たユーザの割合',
            unit: '%',
        },
        {
            id: 13,
            section: '現時点で使っていないユーザの割合',
            unit: '%',
        },
    ]
    const KpiList = () => {
        return (
            <TableContainer layerStyle="dataTable">
                <Table data={calcData} columns={dashboardColumns} />
            </TableContainer>
        )
    }

    //昨日の日付を取得
    const yesterday = new Date()
    yesterday.setDate(yesterday.getDate() - 1)

    const yesterdayString = format(yesterday, 'yyyy-MM-dd')

    const [selectedDate, setSelectedDate] = useState(yesterdayString) // 日付を保持するステート
    const [daily, setDaily] = useState('') // 日次を保持するステート
    const [weekly, setWeekly] = useState('') // 週次を保持するステート
    const [monthly, setMonthly] = useState('') // 月次を保持するステート

    const { data: kpiItems, refetch } = useGetKpiItemsQuery(selectedDate) // kpiitemを取得するクエリ

    const [calcData, setCalcData] = useState([]) // 計算したデータを保持するステート

    //検索数0のユーザーデータを保持するステート
    const [weekNoSearchData, setWeekNoSearchData] = useState({})
    const [monthNoSearchData, setMonthNoSearchData] = useState({})

    const [weekCompData, setWeekCompData] = useState([]) // 週次の比較データを保持するステート
    const [monthCompData, setMonthCompData] = useState([]) // 月次の比較データを保持するステート

    useEffect(() => {
        handleDateChange(selectedDate)
    }, [])

    // kpiitemが変更されたときに呼ばれる関数
    // 必要な値を計算してステートに設定する
    useEffect(() => {
        if (!kpiItems) return

        // 必要な情報を取得・計算
        let dailyCalcData = []

        // 日次
        const dailyData = kpiItems.dailyItems
        const dailyDataItem = dailyData.filter(
            (item) => item.startDate === selectedDate
        )[0]
        try {
            // 1. 提案まで至ったユーザーの数
            let proposalsCount = 0
            dailyDataItem.loginUsers.forEach((user) => {
                if (user.proposalCount > 0) {
                    proposalsCount++
                }
            })
            dailyCalcData.push(proposalsCount)
            // 2. 発行済みアカウント数
            dailyCalcData.push(dailyDataItem.accounts)
            // 3. ログインユーザー数
            dailyCalcData.push(dailyDataItem.loginUsers.length)
            // 4. 一回以上検索したユーザー数
            dailyCalcData.push(
                dailyDataItem.loginUsers.filter((user) => user.searchCount > 0)
                    .length
            )
            // 5. ログイン履歴有だが、検索数0のユーザ数
            dailyCalcData.push(null)
            // 6. 前週に5回以上検索したが、今週は1以下になったユーザー数
            dailyCalcData.push(null)
            // 7. 前月に20回以上検索したが、今月は5以下になったユーザー数
            dailyCalcData.push(null)
            // 8. 前週に1回以下検索、今週は5以上になったユーザー数
            dailyCalcData.push(null)
            // 9. 前月に5回以下検索、今月は20以上になったユーザー数
            dailyCalcData.push(null)
            // 10.総検索数
            dailyCalcData.push(
                dailyDataItem.loginUsers.reduce(
                    (sum, user) => sum + user.searchCount,
                    0
                )
            )
            // 11.一人当たりの検索数
            dailyCalcData.push(
                Math.round((dailyCalcData[9] / dailyCalcData[2]) * 10) / 10
            )
            // 12.提案まで出来たユーザの割合
            dailyCalcData.push(
                Math.round((proposalsCount / dailyCalcData[2]) * 100) / 100
            )
            // 13.現時点で使っていないユーザの割合
            dailyCalcData.push(null)
        } catch (e) {
            dailyCalcData = []
            for (let i = 0; i < 13; i++) {
                dailyCalcData.push(null)
            }
        }

        // 週次
        let weeklyCalcData = calcMultiData(weekly, 1, 5, 'week')
        setWeekCompData([weeklyCalcData[1], weeklyCalcData[2]])
        setWeekNoSearchData({ searchZeroUsers: weeklyCalcData[3] })

        // 月次
        let monthlyCalcData = calcMultiData(monthly, 5, 20, 'month')
        setMonthCompData([monthlyCalcData[1], monthlyCalcData[2]])
        setMonthNoSearchData({ searchZeroUsers: monthlyCalcData[3] })

        // 計算したデータをdataFrameと同じ形式に変換
        let calcData = []
        for (let i = 0; i < 13; i++) {
            calcData.push({
                id: i + 1,
                section: dataFrame[i].section,
                daily: dailyCalcData[i],
                weekly: weeklyCalcData[0][i],
                monthly: monthlyCalcData[0][i],
                unit: dataFrame[i].unit,
            })
        }

        setCalcData(calcData)
    }, [kpiItems])

    const DecrementDate = () => {
        const date = new Date(selectedDate)
        const countDown = () => {
            date.setDate(date.getDate() - 1)
            const daily = date.toISOString().split('T')[0]
            handleDateChange(daily)
        }

        return (
            <>
                <IconButton
                    className="icon-button"
                    variant="unstyled"
                    aria-label="decrementdate"
                    isDisabled={!selectedDate}
                    icon={<ChevronLeftIcon w={8} h={8} />}
                    onClick={countDown}
                />
            </>
        )
    }

    const IncrementDate = () => {
        const date = new Date(selectedDate)
        let disableFlag = false
        if (selectedDate >= yesterdayString || !selectedDate) {
            disableFlag = true
        }
        const countUp = () => {
            date.setDate(date.getDate() + 1)
            const daily = date.toISOString().split('T')[0]
            handleDateChange(daily)
        }

        return (
            <>
                <IconButton
                    className="icon-button"
                    variant="unstyled"
                    aria-label="incrementdate"
                    isDisabled={disableFlag}
                    icon={<ChevronRightIcon w={8} h={8} />}
                    onClick={countUp}
                />
            </>
        )
    }

    const calcMultiData = (range, min, max, type) => {
        let calcData = []
        // 0. 週次のデータを日次データから取得
        const rangeData = range
            .replace(/\//g, '-')
            .replace(/\n/g, '')
            .split('~')
        // 先週のデータを取得
        let beforeRangeData
        if (type === 'week') {
            const startDate = new Date(rangeData[0])
            startDate.setDate(startDate.getDate() - 7)
            const endDate = new Date(rangeData[0])
            endDate.setDate(endDate.getDate() - 1)
            // yyyy-mm-ddに変換 0埋め
            let startDateMonth = ('0' + (startDate.getMonth() + 1)).slice(-2)
            let endDateMonth = ('0' + (endDate.getMonth() + 1)).slice(-2)
            let startDateDate = ('0' + startDate.getDate()).slice(-2)
            let endDateDate = ('0' + endDate.getDate()).slice(-2)
            beforeRangeData = [
                `${startDate.getFullYear()}-${startDateMonth}-${startDateDate}`,
                `${endDate.getFullYear()}-${endDateMonth}-${endDateDate}`,
            ]
        } else if (type === 'month') {
            const startDate = new Date(rangeData[0])
            startDate.setMonth(startDate.getMonth() - 1)
            startDate.setDate(1)
            const endDate = new Date(rangeData[0])
            endDate.setDate(0)
            // yyyy-mm-ddに変換 0埋め
            let startDateMonth = ('0' + (startDate.getMonth() + 1)).slice(-2)
            let endDateMonth = ('0' + (endDate.getMonth() + 1)).slice(-2)
            let startDateDate = ('0' + startDate.getDate()).slice(-2)
            let endDateDate = ('0' + endDate.getDate()).slice(-2)
            beforeRangeData = [
                `${startDate.getFullYear()}-${startDateMonth}-${startDateDate}`,
                `${endDate.getFullYear()}-${endDateMonth}-${endDateDate}`,
            ]
        }

        let filterMinSearchUsers
        let filterMaxSearchUsers
        let searchZeroUsers = {}
        try {
            // この週の日付を元に日次データを取得
            const rangeDailyData = kpiItems.dailyItems.filter((item) => {
                const startDate = new Date(rangeData[0])
                let endDate = new Date(rangeData[1])
                // endDateに1日足す
                endDate.setDate(endDate.getDate() + 1)
                const date = new Date(item.startDate)
                return startDate <= date && date <= endDate
            })
            const beforeRangeDailyData = kpiItems.dailyItems.filter((item) => {
                const startDate = new Date(beforeRangeData[0])
                let endDate = new Date(beforeRangeData[1])
                const date = new Date(item.startDate)
                return startDate <= date && date <= endDate
            })
            let proposalUser = {}
            let loginUsers = {}
            let searchUsers = {}
            let totalSearchCount = 0
            let searchCountByUser = {}
            // ユーザーは重複しないでカウント
            rangeDailyData.forEach((dailyData) => {
                dailyData.loginUsers.forEach((user) => {
                    // 提案まで至ったユーザーの数
                    if (user.proposalCount > 0 && !proposalUser[user.userId]) {
                        proposalUser[user.userId] = user
                    }
                    // 一人当たりの検索数
                    if (!searchCountByUser[user.userId]) {
                        searchCountByUser[user.userId] = { ...user }
                    } else {
                        searchCountByUser[user.userId] = {
                            ...searchCountByUser[user.userId],
                            searchCount:
                                searchCountByUser[user.userId].searchCount +
                                user.searchCount,
                        }
                    }
                    if (user.searchCount > 0) {
                        // 一回以上検索したユーザー数
                        if (!searchUsers[user.userId]) {
                            searchUsers[user.userId] = user
                        }
                        // 総検索数
                        totalSearchCount += user.searchCount
                    } else {
                        // ログイン履歴有だが、検索数0のユーザ数
                        if (!searchZeroUsers[user.userId]) {
                            searchZeroUsers[user.userId] = user
                        }
                    }
                    // ログインユーザー数
                    if (!loginUsers[user.userId]) {
                        loginUsers[user.userId] = user
                    }
                })
            })
            Object.keys(searchUsers).forEach((key) => {
                if (searchZeroUsers.hasOwnProperty(key)) {
                    delete searchZeroUsers[key]
                }
            })
            let beforeSearchCountByUser = {}
            beforeRangeDailyData.forEach((dailyData) => {
                dailyData.loginUsers.forEach((user) => {
                    // アカウントごとの検索数
                    if (!beforeSearchCountByUser[user.userId]) {
                        beforeSearchCountByUser[user.userId] = { ...user }
                    } else {
                        beforeSearchCountByUser[user.userId] = {
                            ...beforeSearchCountByUser[user.userId],
                            searchCount:
                                beforeSearchCountByUser[user.userId]
                                    .searchCount + user.searchCount,
                        }
                    }
                })
            })
            // 先週、先月利用していて今週、今月未ログインのユーザーは検索数0として扱う
            Object.keys(beforeSearchCountByUser).forEach((key) => {
                if (!searchCountByUser.hasOwnProperty(key)) {
                    searchCountByUser[key] = {
                        ...beforeSearchCountByUser[key],
                        searchCount: 0,
                    }
                }
            })
            // 1. 提案まで至ったユーザーの数
            calcData.push(Object.keys(proposalUser).length)
            // 2. 発行済みアカウント数
            calcData.push(rangeDailyData[0].accounts)
            // 3. ログインユーザー数
            calcData.push(Object.keys(loginUsers).length)
            // 4. 一回以上検索したユーザー数
            calcData.push(Object.keys(searchUsers).length)
            let notUseUser
            // アカウントごとの検索数から、前範囲にmax回以上検索したが、今範囲はmin以下になったユーザー数を取得
            filterMinSearchUsers = Object.keys(beforeSearchCountByUser)
                .filter((userId) => {
                    if (!searchCountByUser[userId]) return false
                    return (
                        searchCountByUser[userId].searchCount <= min &&
                        beforeSearchCountByUser[userId].searchCount >= max
                    )
                })
                .reduce((obj, userId) => {
                    obj[userId] = beforeSearchCountByUser[userId]
                    return obj
                }, {})
            // アカウントごとの検索数から、前範囲にmin回以下検索したが、今範囲はmax以上になったユーザー数を取得
            filterMaxSearchUsers = Object.keys(beforeSearchCountByUser)
                .filter((userId) => {
                    if (!searchCountByUser[userId]) return false
                    return (
                        searchCountByUser[userId].searchCount >= max &&
                        beforeSearchCountByUser[userId].searchCount <= min
                    )
                })
                .reduce((obj, userId) => {
                    obj[userId] = beforeSearchCountByUser[userId]
                    return obj
                }, {})
            if (type === 'week') {
                // 5. ログイン履歴有だが、検索数0のユーザ数
                calcData.push(Object.keys(searchZeroUsers).length)
                // 6. 前範囲にmax回以上検索したが、今範囲はmin以下になったユーザー数
                calcData.push(Object.keys(filterMinSearchUsers).length)
                // 7. 前月に20回以上検索したが、今月は5以下になったユーザー数
                calcData.push(null)
                // 8. 前週に1回以下検索、今週は5以上になったユーザー数
                calcData.push(Object.keys(filterMaxSearchUsers).length)
                // 9. 前月に5回以下検索、今月は20以上になったユーザー数
                calcData.push(null)
                // 前週に5回以上検索したが、今週は1以下になったユーザー数/発行済みアカウント数
                notUseUser = calcData[5] / calcData[1]
            } else if (type === 'month') {
                // 5. ログイン履歴有だが、検索数0のユーザ数
                calcData.push(Object.keys(searchZeroUsers).length)
                // 6. 前週に5回以上検索したが、今週は1以下になったユーザー数
                calcData.push(null)
                // 7. 前月に20回以上検索したが、今月は5以下になったユーザー数
                calcData.push(Object.keys(filterMinSearchUsers).length)
                // 8. 前週に1回以下検索、今週は5以上になったユーザー数
                calcData.push(null)
                // 9. 前月に5回以下検索、今月は20以上になったユーザー数
                calcData.push(Object.keys(filterMaxSearchUsers).length)
                // 前月に20回以上検索したが、今月は5以下になったユーザー数/発行済みアカウント数
                notUseUser = calcData[6] / calcData[1]
            }
            // 10.総検索数
            calcData.push(totalSearchCount)
            // 11.一人当たりの検索数
            calcData.push(
                Math.round((totalSearchCount / calcData[2]) * 10) / 10
            )
            // 12.提案まで出来たユーザの割合
            calcData.push(Math.round((calcData[0] / calcData[2]) * 100) / 100)
            // 13.現時点で使っていないユーザの割合
            calcData.push(Math.round(notUseUser * 100) / 100)
        } catch (e) {
            calcData = []
            for (let i = 0; i < 13; i++) {
                calcData.push(null)
            }
        }
        return [
            calcData,
            filterMinSearchUsers,
            filterMaxSearchUsers,
            searchZeroUsers,
        ]
    }

    // 日付が変更されたときに呼ばれる関数
    const handleDateChange = (value) => {
        const newDate = value
        setSelectedDate(newDate)

        if (!value) {
            return
        }

        if (selectedDate) {
            refetch(selectedDate)
        }

        // 日次を設定
        const date = new Date(newDate)
        //const options = { year: 'numeric', month: 'long', day: 'numeric' }
        setDaily(date.toLocaleDateString(undefined))

        const yesterday = new Date(yesterdayString)

        // 週次を計算
        const weekStart = new Date(date)
        weekStart.setDate(date.getDate() - date.getDay()) // 週の開始日（日曜日として）
        const weekEnd = new Date(weekStart)
        weekEnd.setDate(weekStart.getDate() + 6) // 週の終了日（土曜日として）
        let selectedWeekEnd
        //weekEndがyesterdayより前の日付だったらweekEndがendDateになる
        if (weekEnd < yesterday) {
            selectedWeekEnd = weekEnd
        } else {
            selectedWeekEnd = yesterday
        }

        setWeekly(
            `${weekStart.toLocaleDateString(
                undefined
            )}~\n${selectedWeekEnd.toLocaleDateString(undefined)}`
        )
        // 月次を設定
        const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1)
        //月次のendDate
        const lastDayOfMonth = new Date(
            date.getFullYear(),
            date.getMonth() + 1,
            0
        )
        //データがある日にち（昨日）
        let selectedEndDate
        //lastDayOfMonthがyesterdayより前の日付だったらlastDayOfMonthがendDateになる
        if (lastDayOfMonth < yesterday) {
            selectedEndDate = lastDayOfMonth
        } else {
            selectedEndDate = yesterday
        }

        setMonthly(
            `${firstDayOfMonth.toLocaleDateString(
                undefined
            )}~\n${selectedEndDate.toLocaleDateString(undefined)}`
        )
    }

    // CSVダウンロード
    const handleDownload = () => {
        const data = convertToExcel()
        downloadCsv(data)
    }

    // CSV変換
    const convertToExcel = () => {
        let returnData = {}

        let dailyData = []
        dailyData = [
            [
                '日付',
                '提案まで至ったユーザーの数 (人)',
                '発行済みアカウント数 (件)',
                'ログインユーザー数 (人)',
                '一回以上検索したユーザー数 (人)',
                '総検索数 (回)',
                '一人当たりの検索数 (回)',
                '提案まで出来たユーザの割合 (%)',
            ],
        ]

        let weeklyData = {}
        weeklyData.numData = [
            [
                '日付',
                '提案まで至ったユーザーの数 (人)',
                '発行済みアカウント数 (件)',
                'ログインユーザー数 (人)',
                '一回以上検索したユーザー数 (人)',
                'ログイン履歴有、検索数0のユーザー数 (人)',
                '前週に5回以上検索したが、今週は1以下になったユーザー数 (人)',
                '前週に1回以下検索、今週は5以上になったユーザー数 (人)',
                '総検索数 (回)',
                '一人当たりの検索数 (回)',
                '提案まで出来たユーザの割合 (%)',
                '現時点で使っていないユーザの割合 (%)',
            ],
        ]

        // [ 'ユーザーID', '事業部', '営業所', '役職', '社歴' ]
        weeklyData.beforeOne = {}
        weeklyData.beforeFive = {}
        weeklyData.zeroSearch = {}
        let list = []

        for (let i = 1; i < kpiItems.weeklyItems.length; i++) {
            //昨日の日付が週次、月次のendDateより前の時はファイル名のendDateを昨日の日付までにする
            let endDate
            if (kpiItems.weeklyItems[i].endDate > yesterdayString) {
                endDate = yesterdayString
            } else endDate = kpiItems.weeklyItems[i].endDate
            const range = kpiItems.weeklyItems[i].startDate + '~' + endDate
            const rangeData = calcMultiData(range, 1, 5, 'week')
            list.push([
                range,
                rangeData[0][0],
                rangeData[0][1],
                rangeData[0][2],
                rangeData[0][3],
                rangeData[0][4],
                rangeData[0][5],
                rangeData[0][7],
                rangeData[0][9],
                rangeData[0][10],
                Math.round(rangeData[0][11] * 100),
                Math.round(rangeData[0][12] * 100),
            ])
            weeklyData.beforeOne[range] = {
                data: rangeData[1],
            }
            weeklyData.beforeFive[range] = {
                data: rangeData[2],
            }
            weeklyData.zeroSearch[range] = {
                data: rangeData[3],
            }
        }

        // weeklyData.numberを日付順にソート 0行目はヘッダーなので1行目から
        list = list.sort((a, b) => {
            const dateA = new Date(a[0].split('~')[0])
            const dateB = new Date(b[0].split('~')[0])
            // @ts-ignore
            return dateA - dateB
        })
        weeklyData.numData.push(...list)

        let monthlyData = {}
        monthlyData.data = [
            [
                '日付',
                '提案まで至ったユーザー数 (人)',
                '発行済みアカウント数 (件)',
                'ログインユーザー数 (人)',
                '一回以上検索したユーザー数 (人)',
                'ログイン履歴有、検索数0のユーザー数 (人)',
                '前月に20回以上検索、今月は5回以下のユーザー数 (人)',
                '前月に5回以下検索、今月は20以上のユーザー数 (人)',
                '総検索数 (回)',
                '一人当たりの検索数 (回)',
                '提案出来たユーザの割合 (%)',
                '現時点で使っていないユーザの割合 (%)',
            ],
        ]
        // [ 'ユーザーID', '事業部', '営業所', '役職', '社歴' ],
        monthlyData.beforeFive = {}
        monthlyData.beforeTwenty = {}
        monthlyData.zeroSearch = {}

        for (let i = 1; i < kpiItems.monthlyItems.length; i++) {
            const kpiStartDate = kpiItems.monthlyItems[i].startDate
            const kpiEndDate = kpiItems.monthlyItems[i].endDate
            //yesterdayString(昨日の日付)とkpiEndDateを比較して前の日付がendDateになる
            const endDate =
                kpiEndDate > yesterdayString ? yesterdayString : kpiEndDate

            const range = kpiStartDate + '~' + endDate
            const rangeData = calcMultiData(range, 5, 20, 'month')
            monthlyData.data.push([
                range,
                rangeData[0][0],
                rangeData[0][1],
                rangeData[0][2],
                rangeData[0][3],
                rangeData[0][4],
                rangeData[0][6],
                rangeData[0][8],
                rangeData[0][9],
                rangeData[0][10],
                Math.round(rangeData[0][11] * 100),
                Math.round(rangeData[0][12] * 100),
            ])
            monthlyData.beforeFive[range] = {
                data: rangeData[1],
            }
            monthlyData.beforeTwenty[range] = {
                data: rangeData[2],
            }
            monthlyData.zeroSearch[range] = {
                data: rangeData[3],
            }
        }
        // 月の始まりを取得
        const date = new Date(selectedDate)
        // 月の日数を取得
        const dateItem = new Date(
            date.getFullYear(),
            date.getMonth() + 1,
            0
        ).getDate()

        // kpiitemからループでstartDateが当月のyyyy-mm-ddのデータを取得
        let dailyData2 = []

        for (let i = 0; i < dateItem; i++) {
            const dateString = `${date.getFullYear()}-${(
                '0' +
                (date.getMonth() + 1)
            ).slice(-2)}-${('0' + (i + 1)).slice(-2)}`
            // @ts-ignore
            const dailyDataItem = kpiItems.dailyItems.filter(
                (item) => item.startDate === dateString
            )[0]
            dailyData2.push(dailyDataItem)

            if (!dailyDataItem) {
                continue
            }

            let proposalCount = 0
            let searchAccount = 0
            let searchCount = 0

            for (let j = 0; j < dailyDataItem.loginUsers.length; j++) {
                if (dailyDataItem.loginUsers[j].proposalCount > 0) {
                    proposalCount++
                }

                if (dailyDataItem.loginUsers[j].searchCount > 0) {
                    searchAccount++
                    searchCount += dailyDataItem.loginUsers[j].searchCount
                }
            }

            dailyData.push([
                dailyDataItem.startDate,
                proposalCount,
                dailyDataItem.accounts,
                dailyDataItem.loginUsers.length,
                searchAccount,
                searchCount,
                Math.round(
                    (searchCount / dailyDataItem.loginUsers.length) * 10
                ) / 10,
                Math.round(
                    (proposalCount / dailyDataItem.loginUsers.length) * 100
                ),
            ])
        }

        returnData.daily = dailyData
        returnData.weekly = weeklyData
        returnData.monthly = monthlyData
        return returnData
    }

    const downloadCsv = async (data) => {
        const zip = new JSZip()

        // 選択された日付のデータを取得
        const date = new Date(selectedDate)
        // 選択された月の日数を取得

        // ここでCSVデータを生成する。例えば、data変数にCSV形式の文字列をセット。
        const dailyData = data.daily
        const weeklyData = data.weekly.numData
        const weeklyUsersData = data.weekly
        delete weeklyUsersData.numData
        const monthlyData = data.monthly.data
        const monthlyUsersData = data.monthly
        delete monthlyUsersData.data

        const monthString = `${date.getFullYear()}${(
            '0' +
            (date.getMonth() + 1)
        ).slice(-2)}`
        const varDatas = [dailyData, weeklyData, monthlyData]
        const fileName = ['daily', 'weekly', 'monthly']

        for (let i = 0; i < fileName.length; i++) {
            const wb = XLSX.utils.book_new()
            const ws = XLSX.utils.aoa_to_sheet(varDatas[i])
            XLSX.utils.book_append_sheet(
                wb,
                ws,
                `${fileName[i]}_KPI_${monthString}`
            )
            const binaryData = XLSX.write(wb, {
                bookType: 'xlsx',
                type: 'binary',
            })
            // Blobオブジェクトを明示的に作成
            const blob = new Blob([s2ab(binaryData)], {
                type: 'application/octet-stream',
            })
            // yyyymmの形式でファイル名を作成
            zip.file(`${fileName[i]}_KPI_${monthString}.xlsx`, blob)
        }

        // 週次のユーザー情報をエクセルに出力
        /*
            前週に週1回以下検索、今週は5以上になったユーザー weeklyUsersData.beforeOne
            前週に週5回以上検索、今週は1以下になったユーザー weeklyUsersData.beforeFive
            ログイン履歴有、検索数0のユーザー weeklyUsersData.zeroSearch
            の3シートを作成
            週ごとにファイルを作成する
        */
        const weekSheetName = [
            '前週に週5回以上検索、今週は1以下になったユーザー',
            '前週に週1回以下検索、今週は5以上になったユーザー',
            'ログイン履歴有、検索数0のユーザー',
        ]
        const monthSheetName = [
            '前月に月20回以上検索、今月は5以下になったユーザー',
            '前月に月5回以下検索、今月は20以上になったユーザー',
            'ログイン履歴有、検索数0のユーザー',
        ]
        const weeklyItems = kpiItems.weeklyItems
        const monthlyItems = kpiItems.monthlyItems

        const sheetName = [weekSheetName, monthSheetName]
        const items = [weeklyItems, monthlyItems]
        const usersData = [weeklyUsersData, monthlyUsersData]
        for (let i = 0; i < sheetName.length; i++) {
            for (let j = 1; j < items[i].length; j++) {
                const wb2 = XLSX.utils.book_new()
                // yyyymmddyyyymmddの形式でファイル名を作成 例：2023082720230902
                // 3シートの作成 ループで回す
                const usersDataKeys = Object.keys(usersData[i])
                for (let k = 0; k < usersDataKeys.length; k++) {
                    const ws2 = XLSX.utils.aoa_to_sheet([
                        ['ユーザーID', '事業部', '営業所', '役職', '社歴'],
                    ])
                    const usersDataItem = usersData[i][usersDataKeys[k]]
                    const kpiEndDate = items[i][j].endDate
                    const endDate =
                        kpiEndDate > yesterdayString
                            ? yesterdayString
                            : kpiEndDate
                    const range = `${items[i][j].startDate}~${endDate}`
                    if (usersDataItem[range]) {
                        const users = usersDataItem[range].data
                        // 社歴はuser.hireDateから計算
                        // usersの中身がない場合はスキップ
                        if (users && Object.keys(users).length !== 0) {
                            const usersKeys = Object.keys(users)
                            usersKeys.forEach((key) => {
                                const user = users[key]
                                // hireDateがない場合は - を入れる
                                let year
                                if (!user.hireDate) {
                                    year = '-'
                                } else {
                                    const hireDate = new Date(user.hireDate)
                                    const nowDate = new Date()
                                    // @ts-ignore
                                    const diff = nowDate - hireDate
                                    const diffDate = new Date(diff)
                                    year = diffDate.getFullYear() - 1970 + 1
                                }
                                XLSX.utils.sheet_add_aoa(
                                    ws2,
                                    [
                                        [
                                            user.userId,
                                            user.department,
                                            user.officeName,
                                            user.title,
                                            year,
                                        ],
                                    ],
                                    { origin: -1 }
                                )
                            })
                        }
                    }
                    XLSX.utils.book_append_sheet(wb2, ws2, sheetName[i][k])
                }
                const binaryData2 = XLSX.write(wb2, {
                    bookType: 'xlsx',
                    type: 'binary',
                })
                // Blobオブジェクトを明示的に作成
                const blob2 = new Blob([s2ab(binaryData2)], {
                    type: 'application/octet-stream',
                })

                //昨日の日付が週次、月次のendDateより前の時はファイル名のendDateを昨日の日付までにする
                let fileEndDate
                if (items[i][j].endDate > yesterdayString) {
                    fileEndDate = yesterdayString
                } else fileEndDate = items[i][j].endDate

                // yyyymmの形式でファイル名を作成
                zip.file(
                    `${fileName[i + 1]}/${SITE}_${fileName[i + 1]}_KPI_${items[
                        i
                    ][j].startDate.replace(/\//g, '-')}_${fileEndDate.replace(
                        /\//g,
                        '-'
                    )}.xlsx`,
                    blob2
                )
            }
        }

        const content = await zip.generateAsync({ type: 'blob' })
        // ダウンロードリンクを生成
        const url = window.URL.createObjectURL(content)
        const a = document.createElement('a')
        a.href = url
        a.download = `${SITE}_KPI_${monthString}.zip`

        // ダウンロードリンクを自動クリック
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
    }

    function s2ab(s) {
        const buf = new ArrayBuffer(s.length)
        const view = new Uint8Array(buf)
        for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff
        return buf
    }

    return (
        <Section>
            <Box className="title-container" display="flex">
                <SectionTitle>KPI</SectionTitle>
                <Spacer />
                <DecrementDate />
                <Input
                    type="date"
                    maxW="150px"
                    max={yesterdayString}
                    value={selectedDate}
                    onChange={(event) => {
                        handleDateChange(event.target.value)
                    }}
                />
                <IncrementDate />
                <Button onClick={handleDownload}>CSV</Button>
            </Box>
            <KpiList />
        </Section>
    )
}
const userColumns = [
    {
        Header: 'ユーザーID',
        accessor: 'userId',
    },
    {
        Header: '事業部',
        accessor: 'department',
    },
    {
        Header: '営業所',
        accessor: 'officeName',
    },
    {
        Header: '役職',
        accessor: 'title',
    },
    {
        Header: '社歴',
        accessor: 'hireDate',
    },
]
const UserList = (data) => {
    //console.log('############data###########', data)
    let userDataArray = []
    const [page, setPage] = useState(1)

    //ログイン履歴有だが、検索数0のユーザ数
    if (data.id === 5 && data.type === 'weekly') {
        userDataArray = Object.values(data.weekNoSearchData.searchZeroUsers)
    }
    if (data.id === 5 && data.type === 'monthly') {
        userDataArray = Object.values(data.monthNoSearchData.searchZeroUsers)
    }
    //前週に5回以上検索したが、今週は1以下になったユーザー
    if (data.id === 6) {
        userDataArray = Object.values(data.weekCompData[0])
    }
    //前週に1回以下検索、今週は5以上になったユーザー数
    if (data.id === 8) {
        userDataArray = Object.values(data.weekCompData[1])
    }
    //前月に20回以上検索したが、今月は5以下になったユーザー数
    if (data.id === 7) {
        userDataArray = Object.values(data.monthCompData[0])
    }
    //前月に5回以下検索、今月は20以上になったユーザー数
    if (data.id === 9) {
        userDataArray = Object.values(data.monthCompData[1])
    }

    //社歴を計算
    const generateServiceYears = (HireDate) => {
        if (!HireDate) {
            return null
        }
        const targetDate = new Date(HireDate)
        const currentDate = new Date()

        // @ts-ignore
        const timeDifference = currentDate - targetDate
        const millisecondsPerDay = 24 * 60 * 60 * 1000

        const elapsedDays = Math.floor(timeDifference / millisecondsPerDay)
        const elapsedYears = Math.floor(elapsedDays / 365)

        return `${elapsedYears + 1}年目`
    }

    userDataArray = userDataArray.map((user) => ({
        ...user,
        hireDate: generateServiceYears(user.hireDate),
    }))

    const items = useMemo(() => {
        if (!userDataArray) {
            return []
        }
        return chunk(userDataArray, 10)
    }, [userDataArray])

    if (userDataArray.length === 0) {
        return <Box>該当するユーザーはいません</Box>
    }

    return (
        <>
            <TableContainer layerStyle="dataTable" pb="20px">
                <Table data={items[page - 1]} columns={userColumns} />
            </TableContainer>
            <Box
                className="pagination-container"
                mx="-24px"
                mb="-24px"
                py={4}
                bg="white"
            >
                <Pagination
                    totalCount={items.length}
                    currentPage={page}
                    handlePageChanged={setPage}
                />
            </Box>
        </>
    )
}
