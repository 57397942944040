import { Box, HStack, VStack } from '@chakra-ui/react'
import PropTypes from 'prop-types'

export const StationName = ({ station, isAbandonedStation }) => {
    const lines =
        station.lines ??
        (station.routes ? station.routes.map((route) => route.line) : [])
    const companies = station.company
        ? [station.company]
        : station.routes
        ? station.routes.map((route) => route.company)
        : []

    return (
        <HStack
            fontSize={companies[0] && lines[0] && '18px'}
            fontFamily="fonts"
            textDecoration={
                isAbandonedStation && companies[0] && lines[0] && 'line-through'
            }
        >
            <Box>{station.name}</Box>
            <VStack>
                <Box>{companies[0]}</Box>
            </VStack>
            <VStack>
                <Box> {lines[0]}</Box>
            </VStack>
        </HStack>
    )
}

StationName.propTypes = {
    station: PropTypes.shape({
        name: PropTypes.string.isRequired,
        lines: PropTypes.arrayOf(PropTypes.string),
        company: PropTypes.string,
        routes: PropTypes.arrayOf(
            PropTypes.shape({
                line: PropTypes.string,
                company: PropTypes.string,
            })
        ),
    }).isRequired,
    isAbandonedStation: PropTypes.bool,
}
