import { Link as ChakraLink } from '@chakra-ui/react'
import PropTypes from 'prop-types'
import { Link as RouterLink } from 'react-router-dom'

export const Link = ({ to, children, ...props }) => {
    return (
        <ChakraLink to={to} as={RouterLink} {...props}>
            {children}
        </ChakraLink>
    )
}

Link.propTypes = {
    to: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
}
