import cloneDeep from 'lodash.clonedeep'
import PropTypes from 'prop-types'
import {
    createContext,
    useCallback,
    useContext,
    useMemo,
    useReducer,
} from 'react'

/**
 * @typedef {Object} Tasks
 * @property {Object<string, Task>} entities
 * @property {string[]} ids
 *
 * @typedef {Object} Task
 * @property {boolean} isDone
 * @property {any} data
 *
 * @typedef Action
 * @property {string} type
 * @property {any} [payload]
 */

/** @type {import('react').Context<Tasks>} */
const TasksContext = createContext(undefined)
const DispatchContext = createContext(undefined)

/** @type {import('react').Reducer<Tasks, Action>} */
const reducer = (state, action) => {
    switch (action.type) {
        case 'UPDATE_ONE': {
            const { id, isDone = null, update } = action.payload
            const ids = isDone
                ? state.ids.filter((_id) => _id !== id).concat(id) // 完了に変わったものを末尾へ移動
                : state.ids
            const prev = state.entities[id]
            return {
                ids,
                entities: {
                    ...state.entities,
                    [id]: {
                        isDone: isDone ?? prev.isDone,
                        data: Object.assign(cloneDeep(prev.data), update),
                    },
                },
            }
        }
        default: {
            throw Error('Unknown action: ' + action.type)
        }
    }
}

/** @param {() => Tasks} initializer */
export const useTasks = (initializer) => {
    const [state, dispatch] = useReducer(reducer, null, initializer)
    return {
        state,
        dispatch,
    }
}

export const useTasksContext = () => {
    const { ids, entities } = useContext(TasksContext)
    const [progress, isCompleted, items] = useMemo(() => {
        const { progress, items } = ids.reduce(
            (o, id) => {
                const item = entities[id]
                o.items.push(item)
                if (item.isDone) {
                    o.progress += 1
                }
                return o
            },
            {
                progress: 0,
                items: [],
            }
        )
        return [progress, progress && progress === items.length, items]
    }, [ids, entities])
    return {
        isCompleted,
        progress,
        items,
        entities,
        ids,
    }
}

export const useUpdate = (id) => {
    const dispatch = useContext(DispatchContext)
    return useCallback(
        (update, isDone) => {
            dispatch({
                type: 'UPDATE_ONE',
                payload: {
                    id,
                    update: typeof update === 'function' ? update() : update,
                    isDone,
                },
            })
        },
        [dispatch]
    )
}

export const TasksProvider = ({ children, state, dispatch }) => {
    return (
        <TasksContext.Provider value={state}>
            <DispatchContext.Provider value={dispatch}>
                {children}
            </DispatchContext.Provider>
        </TasksContext.Provider>
    )
}
TasksProvider.propTypes = {
    children: PropTypes.node.isRequired,
    state: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
}
