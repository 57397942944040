import { backendApi } from '@/stores/backendApi'

export const KpiApi = backendApi.injectEndpoints({
    endpoints: (builder) => {
        return {
            getKpiItems: builder.query({
                query: (date) => ({
                    url: `/v1/kpi-items/${date}`,
                }),
            }),
        }
    },
    overrideExisting: false,
})

export const useGetKpiItemsQuery = KpiApi.endpoints.getKpiItems.useQuery
