import { HStack } from '@chakra-ui/react'
import PropTypes from 'prop-types'

import { PaginationItem } from './Pagination'

export const MinimumPagination = ({
    totalCount,
    currentPage,
    handlePageChanged,
}) => {
    return (
        <HStack w="full" justifyContent="center" align="center">
            <PaginationItem
                key="previous"
                aria-label="Previous"
                isDisabled={currentPage === 1}
                onClick={() => handlePageChanged(currentPage - 1)}
            >
                <span aria-hidden="true">&lt;</span>
            </PaginationItem>
            <PaginationItem
                key="next"
                aria-label="Next"
                isDisabled={currentPage === totalCount}
                onClick={() => handlePageChanged(currentPage + 1)}
            >
                <span aria-hidden="true">&gt;</span>
            </PaginationItem>
        </HStack>
    )
}

MinimumPagination.propTypes = {
    totalCount: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
    handlePageChanged: PropTypes.func.isRequired,
}
