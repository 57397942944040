import { HStack, Text } from '@chakra-ui/react'
import PropTypes from 'prop-types'
import { useCallback } from 'react'

export const DateFormatComponent = ({ label, children }) => {
    const modifiedDateFormat = useCallback(() => {
        if (children) {
            const date = new Date(children)
            return `${date.getFullYear()}年${
                date.getMonth() + 1
            }月${date.getDate()}日`
        }
        return undefined
    }, [children])()

    return (
        children && (
            <HStack>
                {label && (
                    <Text
                        fontSize="md"
                        whiteSpace="nowrap"
                        fontWeight="bold"
                        color="grey"
                    >
                        {label}
                    </Text>
                )}
                <Text>{modifiedDateFormat}</Text>
            </HStack>
        )
    )
}

DateFormatComponent.propTypes = {
    label: PropTypes.string,
    children: PropTypes.string,
}
