import { Text, Link, useDisclosure } from '@chakra-ui/react'
import { format, parseISO } from 'date-fns'

import { stationBusTableCellPropType } from '../types/stationBusTableCellPropType'

// eslint-disable-next-line no-restricted-imports
import { LinkStationModal } from '@/features/stations/components/LinkStationModal'

// 当システム側駅データ
export const StationDetail = ({ data: stations, row: { index } }) => {
    const station = stations[index]
    return (
        <Text textDecoration={station.deletedAt && 'line-through'}>
            {station.name}&emsp;
            {station.routes[0].company}&emsp;
            {station.routes[0].line}
        </Text>
    )
}
StationDetail.propTypes = stationBusTableCellPropType

// 駅探側駅データ
export const EkitanStationDetail = ({ data: stations, row: { index } }) => {
    const station = stations[index]
    return <Text>{station.ekitan?.name}</Text>
}
EkitanStationDetail.propTypes = stationBusTableCellPropType

// 更新情報
export const StationLinkedBy = ({ data: stations, row: { index } }) => {
    const station = stations[index]
    return (
        <>
            <Text fontSize="12px" whiteSpace="nowrap">
                {station.updatedAt
                    ? format(
                          parseISO(station.updatedAt),
                          'yyyy年MM月dd日 HH:mm'
                      )
                    : ''}
            </Text>
            <Text fontSize="12px">
                {station.updatedBy ? `${station.updatedBy}` : ''}
            </Text>
        </>
    )
}
StationLinkedBy.propTypes = stationBusTableCellPropType

// 紐づけボタン
export const StationLinkage = ({ data: stations, row: { index } }) => {
    const station = stations[index]
    const { isOpen, onOpen, onClose } = useDisclosure({
        defaultIsOpen: false,
    })

    return (
        <>
            <Link
                minW="40px"
                fontSize="18px"
                color="#3CB37A"
                fontWeight="bolder"
                textAlign="center"
                as="button"
                onClick={onOpen}
            >
                編集
            </Link>
            <LinkStationModal
                isOpen={isOpen}
                onLink={() => {}}
                onClose={onClose}
                station={station}
            />
        </>
    )
}
StationLinkage.propTypes = stationBusTableCellPropType
