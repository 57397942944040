import { isRejectedWithValue } from '@reduxjs/toolkit'
import tk from '@reduxjs/toolkit'

import { history } from '@/lib/history'

/**
 * Log a warning and show a toast!
 *
 * @see {@link: https://redux-toolkit.js.org/rtk-query/usage/error-handling#handling-errors-at-a-macro-level}
 */
export const rtkQueryErrorLogger = (/* store */) => (next) => (action) => {
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
    if (!isRejectedWithValue(action)) {
        return next(action)
    }

    // SalesForce API 接続不可時
    if (['getSfAccountDetail'].includes(action?.meta?.arg?.endpointName)) {
        console.log({ sfApiFailed: action })
        return next(action)
    }

    // Sorry ページ表示
    if (
        action.payload?.status === 'PARSING_ERROR' &&
        action.payload?.originalStatus === 503
    ) {
        console.log({ rejected: action })
        window.location.reload()
        return next(action)
    }

    // FIXME: 暫定対応でエラー画面にとばす。画面ごとのエラー処理ができなくなるので直す。
    console.log({ rejected: action })
    if (action.payload?.status !== 404) {
        /**
         * @typedef {import('@reduxjs/toolkit/query').FetchBaseQueryMeta} FetchBaseQueryMeta
         * @type {FetchBaseQueryMeta | null}
         */
        const meta = action?.meta?.baseQueryMeta
        const state = {
            message: 'Request failed.',
            error: {
                payload: action.payload,
            },
        }
        if (meta?.response?.headers?.has('x-app-version')) {
            state.error.meta = {
                version: meta?.response?.headers?.get('x-app-version'),
            }
        }
        if (action.payload?.status === 400 || action.payload?.status === 401) {
            history.push('', state)
        } else if (
            action.payload?.status === 501 &&
            action.payload?.data?.name === 'MongoServerError'
        ) {
            history.push('', state)
        } else {
            history.push('/error', state)
        }
    }
    return next(action)
}
