export const getPaymentKeysAndValues = (data) => {
    if (data.hourlyIncomeMin || data.hourlyIncomeMax) {
        return [
            {
                from: 'hourlyIncomeMin',
                to: 'hourlyIncomeMax',
            },
            {
                from: Math.floor(data.hourlyIncomeMin * 10) / 10,
                to: Math.floor(data.hourlyIncomeMax * 10) / 10,
            },
            '時給',
            '円',
        ]
    }
    if (data.annualIncomeMin || data.annualIncomeMax) {
        return [
            {
                from: 'annualIncomeMin',
                to: 'annualIncomeMax',
            },
            {
                from: Math.floor(data.annualIncomeMin * 10) / 10,
                to: Math.floor(data.annualIncomeMax * 10) / 10,
            },
            '年収',
            '万円',
        ]
    }
    if (data.monthlyIncomeMin || data.monthlyIncomeMax) {
        return [
            {
                from: 'monthlyIncomeMin',
                to: 'monthlyIncomeMax',
            },
            {
                from: Math.floor(data.monthlyIncomeMin * 10) / 10,
                to: Math.floor(data.monthlyIncomeMax * 10) / 10,
            },
            '月給',
            '万円',
        ]
    }
    if (data.daylyIncomeMin || data.daylyIncomeMax) {
        return [
            {
                from: 'daylyIncomeMin',
                to: 'daylyIncomeMax',
            },
            {
                from: Math.floor(data.daylyIncomeMin * 10) / 10,
                to: Math.floor(data.daylyIncomeMax * 10) / 10,
            },
            '日給',
            '円',
        ]
    }
    return []
}

export const getPaymentValuesFromUnit = (data, unit) => {
    if (unit === '時給') {
        return [
            {
                from: 'hourlyIncomeMin',
                to: 'hourlyIncomeMax',
            },
            {
                from: Math.floor(data.hourlyIncomeMin * 10) / 10,
                to: Math.floor(data.hourlyIncomeMax * 10) / 10,
            },
            '円',
        ]
    }
    if (unit === '年収') {
        return [
            {
                from: 'annualIncomeMin',
                to: 'annualIncomeMax',
            },
            {
                from: Math.floor(data.annualIncomeMin * 10) / 10,
                to: Math.floor(data.annualIncomeMax * 10) / 10,
            },
            '万円',
        ]
    }
    if (unit === '月給') {
        return [
            {
                from: 'monthlyIncomeMin',
                to: 'monthlyIncomeMax',
            },
            {
                from: Math.floor(data.monthlyIncomeMin * 10) / 10,
                to: Math.floor(data.monthlyIncomeMax * 10) / 10,
            },
            '万円',
        ]
    }
    if (unit === '日給') {
        return [
            {
                from: 'daylyIncomeMin',
                to: 'daylyIncomeMax',
            },
            {
                from: Math.floor(data.daylyIncomeMin * 10) / 10,
                to: Math.floor(data.daylyIncomeMax * 10) / 10,
            },
            '円',
        ]
    }
    return []
}
