// TODO
export default {
    'any.required': '{{#label}}を入力してください',
    'string.empty': '{{#label}}を入力してください',
    'object.base': '{{#label}}を入力してください',
    'number.base': '{{#label}}を数値で入力してください',
    'number.min': '{{:#limit}}以上で入力してください',
    'number.max': '{{:#limit}}以下で入力してください',
    'number.integer': '{{#label}}を整数で入力してください',
}
