import { Stack, HStack, Box } from '@chakra-ui/react'
import PropTypes from 'prop-types'
import { useContext } from 'react'

import { FacilityContext } from '..'
import {
    TextHeadingComponent,
    TextHStackComponent,
} from '../../FacilityItemComponents'

export const KaigoEmployeesItem = () => {
    const data = useContext(FacilityContext)
    return (
        <TextHeadingComponent
            label="従業員"
            isObject={[
                data.officeEmployeeCount,
                data.nursingExperienceRatio.over5y,
                data.nursingExperienceRatio.over10y,
                data.nightShiftStaffCountMin,
                data.nightShiftStaffCountAvg,
                data.employeeInfoMaleRatio,
                data.employeeInfoFemaleRatio,
            ]}
        >
            <Stack>
                <TextHStackComponent label="事業者総従業員数">
                    {data.officeEmployeeCount}
                </TextHStackComponent>
                <HStack>
                    <TextHStackComponent label="介護経験5年以上職員比率">
                        {data.nursingExperienceRatio.over5y}
                    </TextHStackComponent>
                    <TextHStackComponent label="介護経験10年以上職員比率">
                        {data.nursingExperienceRatio.over10y}
                    </TextHStackComponent>
                </HStack>
                <HStack>
                    <TextHStackComponent label="夜勤平均スタッフ人数">
                        {data.nightShiftStaffCountAvg}
                    </TextHStackComponent>
                    <TextHStackComponent label="夜勤最小スタッフ人数">
                        {data.nightShiftStaffCountMin}
                    </TextHStackComponent>
                </HStack>
                <HStack>
                    <TextHStackComponent label="従業員男性比率" endStr="%">
                        {data.employeeInfoMaleRatio}
                    </TextHStackComponent>
                    <TextHStackComponent label="従業員女性比率" endStr="%">
                        {data.employeeInfoFemaleRatio}
                    </TextHStackComponent>
                </HStack>
            </Stack>
        </TextHeadingComponent>
    )
}

const HoikuEmployee = ({
    fullTimeLabel,
    partTimeLabel,
    fullTimeData,
    partTimeData,
}) => {
    return fullTimeData || partTimeData ? (
        <HStack>
            <Box flex="1" minWidth="250px">
                <TextHStackComponent label={fullTimeLabel}>
                    {fullTimeData}
                </TextHStackComponent>
            </Box>
            <Box flex="1" minWidth="250px">
                <TextHStackComponent label={partTimeLabel}>
                    {partTimeData}
                </TextHStackComponent>
            </Box>
        </HStack>
    ) : null
}
HoikuEmployee.propTypes = {
    fullTimeLabel: PropTypes.string,
    partTimeLabel: PropTypes.string,
    fullTimeData: PropTypes.any,
    partTimeData: PropTypes.any,
}

export const HoikuEmployeesItem = () => {
    const data = useContext(FacilityContext)
    return (
        <>
            <TextHeadingComponent
                label="従業員"
                isObject={[
                    data.totalNumberOfWorkersFullTime,
                    data.totalNumberOfWorkersPartTime,
                    data.numberOfNurseryschoolTeachersFullTime,
                    data.numberOfNurseryschoolTeachersPartTime,
                    data.numberOfTeachersOrChildcareWorkersFullTime,
                    data.numberOfTeachersOrChildcareWorkersPartTime,
                    data.numberOfChildcareWorkersFullTime,
                    data.numberOfChildcareWorkersPartTime,
                    data.numberOfChildcareGiversFullTime,
                    data.numberOfChildcareGiversPartTime,
                    data.numberOfTeachersFullTime,
                    data.numberOfTeachersPartTime,
                    data.numberOfNursesFullTime,
                    data.numberOfNursesPartTime,
                ]}
            >
                <Stack>
                    <HoikuEmployee
                        fullTimeLabel="合計_常勤"
                        partTimeLabel="合計_非常勤"
                        fullTimeData={data.totalNumberOfWorkersFullTime}
                        partTimeData={data.totalNumberOfWorkersPartTime}
                    />
                    <HoikuEmployee
                        fullTimeLabel="保育教諭_常勤"
                        partTimeLabel="保育教諭_非常勤"
                        fullTimeData={
                            data.numberOfNurseryschoolTeachersFullTime
                        }
                        partTimeData={
                            data.numberOfNurseryschoolTeachersPartTime
                        }
                    />
                    <HoikuEmployee
                        fullTimeLabel="教諭又は保育士_常勤"
                        partTimeLabel="教諭又は保育士_非常勤"
                        fullTimeData={
                            data.numberOfTeachersOrChildcareWorkersFullTime
                        }
                        partTimeData={
                            data.numberOfTeachersOrChildcareWorkersPartTime
                        }
                    />
                    <HoikuEmployee
                        fullTimeLabel="保育士_常勤"
                        partTimeLabel="保育士_非常勤"
                        fullTimeData={data.numberOfChildcareWorkersFullTime}
                        partTimeData={data.numberOfChildcareWorkersPartTime}
                    />
                    <HoikuEmployee
                        fullTimeLabel="保育従事者_常勤"
                        partTimeLabel="保育従事者_非常勤"
                        fullTimeData={data.numberOfChildcareGiversFullTime}
                        partTimeData={data.numberOfChildcareGiversPartTime}
                    />
                    <HoikuEmployee
                        fullTimeLabel="教諭_常勤"
                        partTimeLabel="教諭_非常勤"
                        fullTimeData={data.numberOfTeachersFullTime}
                        partTimeData={data.numberOfTeachersPartTime}
                    />
                    <HoikuEmployee
                        fullTimeLabel="看護師_常勤"
                        partTimeLabel="看護師_非常勤"
                        fullTimeData={data.numberOfNursesFullTime}
                        partTimeData={data.numberOfNursesPartTime}
                    />
                </Stack>
            </TextHeadingComponent>
            <TextHeadingComponent
                label="職員一人当たりの子どもの数"
                isObject={[data.numberOfChildrenPerStaff]}
            >
                <TextHStackComponent label="">
                    {data.numberOfChildrenPerStaff}
                </TextHStackComponent>
            </TextHeadingComponent>
        </>
    )
}
