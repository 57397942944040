import { Box, Text } from '@chakra-ui/react'
import { format } from 'date-fns'
import PropTypes from 'prop-types'

/**
 * @function
 * @param {Date} date
 * @returns {string}
 */
const dateTextShort = (date) => format(date, 'yyyy年MM月dd日')

/**
 * @function
 * @param {Date} date
 * @returns {string}
 */
const dateEndText = (date) => format(date, 'HH:mm')

/**
 * @function
 * @param {{ begin: Date; end: Date | undefined }} args
 * @returns {string}
 */
const durationText = ({ begin, end }) =>
    dateEndText(begin) + '～' + (end ? dateEndText(end) : '')

/**
 * 文字数調整
 *
 * @function
 * @param {string} content
 * @returns {string}
 */
const contentText = (content) => {
    if (content.length > 40) {
        return content.slice(0, 40) + '...'
    } else {
        return content
    }
}

/** @type {import('react').FC<any>} */
const NotificationPublishDate = ({ notification }) =>
    // durationの有無で表示を変える
    notification.duration ? (
        <div
            style={{
                marginRight: '1rem',
                display: 'flex',
            }}
        >
            <p
                style={{
                    marginRight: '1rem',
                }}
            >
                {dateTextShort(notification.duration.begin)}
            </p>
            <p>{durationText(notification.duration)}</p>
        </div>
    ) : (
        <div
            style={{
                marginRight: '1rem',
            }}
        >
            {dateTextShort(notification.period.begin)}
        </div>
    )
NotificationPublishDate.propTypes = {
    notification: PropTypes.any,
}

/** @type {import('react').FC<any>} */
const AnnouncementContent = ({ notification: announcement }) => (
    <>
        {/* notification.urlがある場合はリンク付きで表示 */}
        {announcement.url ? (
            <a
                href={announcement.url}
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: 'underline' }}
            >
                {contentText(announcement.content)}
            </a>
        ) : (
            contentText(announcement.content)
        )}
    </>
)
AnnouncementContent.propTypes = {
    notification: PropTypes.any,
}

/** @type {import('react').FC<any>} */
const MaintenanceContent = () => (
    <div>
        メンテナンス作業のため、レコメンドシステムをご利用いただけません。
    </div>
)
MaintenanceContent.propTypes = {
    notification: PropTypes.any,
}

/** @type {import('react').FC<any>} */
const NotificationContent = ({ notification }) =>
    notification.content ? (
        <AnnouncementContent notification={notification} />
    ) : (
        <MaintenanceContent notification={notification} />
    )
NotificationContent.propTypes = {
    notification: PropTypes.any,
}
/** @type {import('react').FC<{ notifications: any[]; maxWidth: boolean }>} */
export const NotificationTable = ({ notifications, maxWidth }) => {
    return notifications.filter(
        (notification) => notification.period.begin < new Date()
    ).length === 0 ? (
        <Box
            display="flex"
            marginX={5}
            pt={maxWidth ? '5px' : '0px'}
            style={{
                margin: maxWidth ? '0.5rem auto 0px' : '0.2rem auto 0px',
                padding: '1px 5px',
                width: '100%',
                maxWidth: maxWidth ? '1000px' : '',
                background: '#fff',
            }}
        >
            <Text color="#616161" fontSize="18px">
                お知らせはありません
            </Text>
        </Box>
    ) : (
        <Box
            style={{
                margin: '0.5rem auto 0px',
                padding: '1px 5px',
                width: '100%',
                maxWidth: maxWidth ? '1000px' : '',
                background: '#fff',
            }}
        >
            {notifications.map((notification) => (
                <Box key={notification._id} display="flex" marginX={5} color="#616161">
                    <NotificationPublishDate notification={notification} />
                    <NotificationContent notification={notification} />
                </Box>
            ))}
        </Box>
    )
}

NotificationTable.propTypes = {
    notifications: PropTypes.array.isRequired,
    maxWidth: PropTypes.bool,
}

/** @type {import('react').FC<any>} */
const DeleteNotificationButton = ({ notification }) => {
    return (
        <Text
            onClick={notification.operations.delete}
            style={{
                fontWeight: 'bolder',
                cursor: 'pointer',
                marginLeft: '1rem',
            }}
            color="#E53E3E"
        >
            削除
        </Text>
    )
}
DeleteNotificationButton.propTypes = {
    notification: PropTypes.any,
}

/** @type {import('react').FC<any>} */
const EditNotificationButton = ({ notification }) => {
    return (
        <Text
            onClick={notification.operations.edit}
            style={{
                color: '#3CB37A',
                fontWeight: 'bolder',
                cursor: 'pointer',
                marginLeft: 'auto',
            }}
        >
            編集
        </Text>
    )
}
EditNotificationButton.propTypes = {
    notification: PropTypes.any,
}

/** @type {import('react').FC<{ notifications: any[] }>} */
export const AdminNotificationTable = ({ notifications }) => {
    if (notifications.length === 0) {
        return (
            <Box display="flex" marginX={5}
                style={{
                    margin: '0.5rem auto 20px auto',
                    padding: '1px 5px',
                    width: '100%',
                    maxWidth: '1000px',
                    background: '#fff',
                }}
            >
                <Text color="#616161" fontSize="18px">
                    お知らせはありません
                </Text>
            </Box>
        )
    }
    return (
        <Box
            style={{
                margin: '0.5rem auto 20px auto',
                padding: '1px 5px',
                width: '100%',
                maxWidth: '1000px',
                background: '#fff',
            }}
        >
            {notifications.map((notification) => (
                <Box
                    color="#616161"
                    key={notification._id}
                    display="flex"
                    marginX={5}
                    style={{
                        background:
                            notification.period.begin > new Date() ||
                            notification.period.end < new Date() ||
                            notification.status === 2
                                ? '#C1C1C1'
                                : '#fff',
                    }}
                >
                    <NotificationPublishDate notification={notification} />
                    <NotificationContent notification={notification} />
                    <EditNotificationButton notification={notification} />
                    <DeleteNotificationButton notification={notification} />
                </Box>
            ))}
        </Box>
    )
}

AdminNotificationTable.propTypes = {
    notifications: PropTypes.array.isRequired,
}
