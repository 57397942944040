import qs from 'qs'
import { ARRAY_LIMIT } from '@/config/index.js'

export default {
    /**
     * @param {any} obj
     * @param {qs.IStringifyOptions} options
     * @returns
     */
    stringify: (obj, options = {}) => {
        return qs.stringify(obj, {
            ...options,
        })
    },
    parse: (str) => {
        return qs.parse(str, {
            arrayLimit: parseInt(ARRAY_LIMIT),
        })
    },
}
