// see: https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/theme/src/components/textarea.ts
import { inputTheme } from './input'

export const textareaTheme = {
    variants: {
        outline: inputTheme.variants.outline.field,
    },
    sizes: {
        md: inputTheme.sizes.md.field,
    },
    fontFamily: 'fonts',
    defaultProps: inputTheme.defaultProps,
}
