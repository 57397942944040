import site from 'shared/utils/site'

//診療科目マッピング
export const kangoposClinicalDepartmentMappings = {
    小児科: [
        '新生児科',
        '小児外科',
        '小児耳鼻咽喉科',
        '小児皮膚科',
        '小児眼科',
    ],
    内科: [
        '糖尿病内科',
        '腎臓内科・外科',
        '内分泌科',
        '漢方内科',
        '老年内科',
        '感染症内科',
        '腫瘍内科・外科',
    ],
    産科: ['産婦人科'],
    婦人科: ['乳腺外科', '産婦人科'],
    整形外科: ['リマウチ科'],
    消化器科: ['胃腸科', '肝臓内科・外科'],
    歯科: ['矯正歯科', '歯科口腔外科', '小児歯科'],
    '精神科・神経科': ['精神科', '心療内科', '児童精神科'],
    呼吸器科: ['呼吸器内科', '呼吸器外科', '気管食道科'],
    その他: [
        '放射線科',
        '麻酔科',
        '臨床検査・病理診断',
        'ペインクリニック',
        '性病科',
        '肛門科',
    ],
}

export const clinicalDepartmentMappings = site.isKango()
    ? kangoposClinicalDepartmentMappings
    : site.isPos()
    ? kangoposClinicalDepartmentMappings
    : null
