import { components } from './components'
import { foundations } from './foundations'
import { globalStyles } from './global-styles'
import { layerStyles } from './layer-styles'
import { textStyles } from './text-styles'

export const appTheme = {
    ...foundations,
    layerStyles,
    textStyles,
    components,
    styles: {
        global: globalStyles,
    },
}
