import { backendApi } from '@/stores/backendApi'
import { sfApi } from '@/stores/sfApi'

const jobseekersApi = backendApi.injectEndpoints({
    endpoints: (builder) => {
        return {
            getJobseekers: builder.query({
                query: (id) => ({
                    url: `/v1/jobseekers/${id}`,
                }),
            }),
        }
    },
    overrideExisting: false,
})

export const { useGetJobseekersQuery, useLazyGetJobseekersQuery } =
    jobseekersApi

const sfAccountApi = sfApi.injectEndpoints({
    endpoints: (builder) => {
        const keyAndColumnPairs = [
            { key: 'code', column: 'Id' },
            { key: 'sfId', column: 'CD__c' },
            { key: 'prefecture', column: 'BillingState' },
            { key: 'city', column: 'BillingCity' },
            {
                key: 'postCode',
                column: 'BillingPostalCode',
                transform: (postCode) =>
                    postCode
                        .toString()
                        .replace(/[^0-9]/g, '')
                        .substring(0, 7),
            },
            { key: 'sex', column: 'Sex__c' },
            { key: 'age', column: 'Age__c' },
            { key: 'qualifications', column: 'yuusensikakusyuukeiyou__c' },
            { key: 'sfUpdatedAt', column: 'LastModifiedDate' },
            { key: 'personContactId', column: 'PersonContactId' },
        ]

        return {
            getSfAccountDetail: builder.query({
                query: (sfId) => {
                    const sanitizedSfId = sfId.replaceAll("'", "''")
                    const columns = keyAndColumnPairs.map(
                        ({ column }) => column
                    )
                    const query = `SELECT ${columns.join(',')}
FROM Account
WHERE Cd__c = '${sanitizedSfId}'`
                    return {
                        url: '/data/v47.0/query/',
                        params: { q: query },
                    }
                },
                transformResponse: (response) => {
                    const account = response?.records?.[0]
                    if (!account) {
                        return undefined
                    }
                    const jobseeker = {}
                    keyAndColumnPairs.forEach(({ key, column, transform }) => {
                        if (
                            account[column] === null ||
                            account[column] === undefined
                        ) {
                            return
                        }

                        transform ??= (value) => value
                        jobseeker[key] = transform(account[column])
                    })
                    return jobseeker
                },
            }),
        }
    },
})

export const { useGetSfAccountDetailQuery, useLazyGetSfAccountDetailQuery } =
    sfAccountApi
