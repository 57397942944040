import { validTravelModes } from '../../config/travelModeChoices.js'
import { joi } from '../joi.js'

const {
    string: stringSchema,
    number: numberSchema,
    any: anySchema,
    array: arraySchema,
} = joi.types()
const string = stringSchema.empty([null, ''])
const integer = numberSchema.empty([null, '']).integer()
const entity = joi.any().empty(null).required()
const requiredArray = arraySchema.$.min(1).rule({
    message: '{{#label}} を入力してください',
})

// export const addressSchema = joi.object({
//     postCode: joi.any().strip(),
//     prefecture: entity.label('都道府県'),
//     city: entity.label('市区町村'),
//     street: string,
//     location: entity,
// })

export const addressSchema = joi
    .object()
    .unknown(true)
    .keys({
        prefecture: entity.label('都道府県'),
        city: entity.label('市区町村'),
        street: string,
        location: entity,
    })

export const locationSchema = joi
    .object()
    .unknown(true)
    .keys({
        geometry: entity,
    })
    .label('住所')
    .messages({
        '*': '{{#label}} を入力してください',
    })

const locationSearchValidator = joi
    .object({
        serviceCode: joi.array().label('施設形態'),
        offer: {
            qualification: requiredArray.label('保有資格'),
            workShift: requiredArray.label('勤務形態'),
            employmentType: joi.array().label('雇用形態'),
            status: integer.default(0),
        },
    })
    .alter({
        client: (schema) =>
            schema.concat(
                joi.object({
                    requiredTime: joi.object({
                        departure: string
                            .valid('address', 'station', 'location')
                            .required(),
                        address: joi.alternatives().conditional('departure', {
                            is: 'address',
                            then: addressSchema.required().label('エリア'),
                            otherwise: anySchema,
                        }),
                        station: joi.alternatives().conditional('departure', {
                            is: 'station',
                            then: requiredArray.label('最寄駅'),
                            otherwise: anySchema,
                        }),
                        location: joi.alternatives().conditional('departure', {
                            is: 'location',
                            then: locationSchema.required().label('住所'),
                            otherwise: anySchema,
                        }),
                        type: joi.alternatives().conditional('departure', {
                            is: 'station',
                            then: anySchema.strip(),
                            otherwise: entity.required().label('通勤手段'),
                        }),
                        time: integer.max(60).required().label('移動時間'),
                    }),
                    offer: {
                        income: {
                            monthly: integer.required().label('月給'),
                        },
                    },
                    extendIncome: joi.number().required(),
                    extendCostTime: joi.number().required(),
                })
            ),
        server: (schema) =>
            schema.concat(
                joi.object({
                    requiredTime: joi
                        .object({
                            stationCode: joi.array().items(integer).min(1),
                            location: joi.object({
                                lat: joi.number().required(),
                                lng: joi.number().required(),
                            }),
                            time: integer.max(75).required(), // キャッシュの上限？
                            type: string.valid(...validTravelModes),
                        })
                        .xor('stationCode', 'location')
                        .with('location', 'type'),
                })
            ),
    })

export const locationSearchValidatorForClient =
    locationSearchValidator.tailor('client')

export const locationSearchValidatorForServer =
    locationSearchValidator.tailor('server')
