export const floatingControlHeight = '50px'

const activeControlStyles = {
    pt: '23.5px', // 50 - 21 - 5.5
    pb: '5.5px',
}

const activeLabelStyles = {
    transform: 'translateY(-10.5px)', // 16 - 5.5
}

export const formTheme = {
    variants: {
        floating: {
            container: {
                label: {
                    m: 0,
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    zIndex: 2,
                    px: '8.5px',
                    py: '16px',
                    pointerEvents: 'none',
                    transformOrigin: '0 0',
                    transition: 'transform .1s ease-in-out',
                },
                'input:not(.chakra-select__input)': {
                    '&:focus, &:not(:placeholder-shown)': {
                        ...activeControlStyles,
                        '~label': {
                            ...activeLabelStyles,
                        },
                    },
                },
                // chakra-react-select
                '.floating-select': {
                    '.chakra-select__value-container': {
                        minH: floatingControlHeight,
                    },
                    '&:focus-within, &.chakra-select--has-value': {
                        '.chakra-select__value-container': {
                            ...activeControlStyles,
                            px: '6.5px',
                        },
                        '~label': {
                            ...activeLabelStyles,
                        },
                    },
                    '.chakra-select__single-value': {
                        transform: 'none',
                    },
                },
            },
        },
    },
}
