import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet-async'
import Site from 'shared/utils/site'

//まだ共通化されてないらしい
//元々使ってたところ：frontend\src\components\Layout\MainLayout\Header\Header.jsx
const siteName = Site.isKaigo()
    ? '介護'
    : Site.isHoiku()
    ? '保育'
    : Site.isKango()
    ? '看護'
    : Site.isPos()
    ? 'POS'
    : null

// TODO: 内容を決める
const Head = ({ title = '', errorTitle = '', description = '' }) => {
    return (
        <Helmet
            title={
                title
                    ? `${title} | TRYT ${siteName}事業所検索`
                    : errorTitle
                    ? `${errorTitle} | Forward`
                    : undefined
            }
            defaultTitle={`TRYT ${siteName}事業所検索`}
        >
            <meta name="description" content={description} />
        </Helmet>
    )
}

Head.propTypes = {
    title: PropTypes.string,
    errorTitle: PropTypes.string,
    description: PropTypes.string,
}

export { Head }
