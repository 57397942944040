//契約の有無選択肢
export const contractChoices = [
    {
        label: '契約無',
        value: '契約無',
    },
    {
        label: '契約有',
        options: [
            {
                label: '契約有（法人一括｜紹介・派遣）',
                value: '契約有（法人一括｜紹介・派遣）',
            },
            {
                label: '契約有（法人一括｜紹介のみ）',
                value: '契約有（法人一括｜紹介のみ）',
            },
            {
                label: '契約有（法人＆施設｜派遣のみ）',
                value: '契約有（法人＆施設｜派遣のみ）',
            },
            {
                label: '契約有（施設単体｜紹介・派遣）',
                value: '契約有（施設単体｜紹介・派遣）',
            },
            {
                label: '契約有（法人＆施設｜紹介・派遣）',
                value: '契約有（法人＆施設｜紹介・派遣）',
            },
            {
                label: '契約有（施設単体｜派遣のみ）',
                value: '契約有（施設単体｜派遣のみ）',
            },
            {
                label: '契約有（法人＆施設｜紹介のみ）',
                value: '契約有（法人＆施設｜紹介のみ）',
            },
            {
                label: '契約有（施設単体｜紹介のみ）',
                value: '契約有（施設単体｜紹介のみ）',
            },
        ],
    },
]
