import site from 'shared/utils/site'

//勤務形態マッピング
export const workShiftMappings = {
    フル常勤: ['フルタイム'],
    日勤常勤: ['フルタイム'],
    夜勤常勤: ['フルタイム'],
    日勤パート: ['パート'],
    夜勤パート: ['パート'],
}

const kangoRecodeWorkShiftMappings = {
    夜勤パート: ['夜勤バイト'],
    フルタイムパート: ['フルパート'],
}
const notkangoRecodeWorkShiftMappings = {
    夜勤パート: ['夜勤バイト'],
}

export const recodeWorkShiftMappings = site.isKango()
    ? kangoRecodeWorkShiftMappings
    : site.isPos()
    ? kangoRecodeWorkShiftMappings
    : notkangoRecodeWorkShiftMappings
