import {
    Button,
    HStack,
    VStack,
    Box,
    Text,
    useDisclosure,
    Link,
} from '@chakra-ui/react'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { useRef } from 'react'

import { UserIcon } from '@/assets/icon'
import { ExternalLink } from '@/components/Elements'

const QualificationsBalloon = ({ jobseeker, isOpen }) => {
    const qualifications = jobseeker.qualifications
    if (!qualifications || !isOpen) {
        return
    }
    return (
        <Box
            width="100%"
            position="absolute"
            top="2em"
            left="0"
            border="1px"
            borderRadius="3px"
            backgroundColor="rgba(255,255,255,0.8)" /* TODO: 透明度適切な値に変更 */
            zIndex="10"
            padding={1}
            textAlign="center"
        >
            <Text>{jobseeker.qualifications}</Text>
        </Box>
    )
}

QualificationsBalloon.propTypes = {
    jobseeker: PropTypes.shape({
        qualifications: PropTypes.string,
    }).isRequired,
    isOpen: PropTypes.bool,
}

export const ConfirmedSfId = ({ jobseeker, onClick }) => {
    const { isOpen, onOpen, onClose } = useDisclosure({
        defaultIsOpen: true,
    })

    const ref = useRef(null)
    useEffect(() => {
        const rawDom = ref.current
        if (!rawDom) {
            return
        }

        const onClick = (e) => {
            if (rawDom.contains(e.target)) {
                onOpen()
            } else {
                onClose()
            }
        }

        document.addEventListener('click', onClick)
        return () => document.removeEventListener('click', onClick)
    }, [ref, onOpen, onClose])

    const href = `https://tryt-group.lightning.force.com/lightning/r/Account/${jobseeker.code}/view`

    return (
        <VStack position="relative" ref={ref}>
            <HStack height="100%" spacing="5px">
                <ExternalLink href={href}>{jobseeker.sfId}</ExternalLink>
                <Text>|</Text>
                <HStack spacing="5px">
                    <UserIcon />
                    <Text>{jobseeker.prefecture}</Text>
                    <Text>{jobseeker.age && `${jobseeker.age}歳`}</Text>
                    <Text>{jobseeker.sex}</Text>
                </HStack>
                <Button size="xs" onClick={onClick}>
                    ✖
                </Button>
            </HStack>
            <QualificationsBalloon jobseeker={jobseeker} isOpen={isOpen} />
        </VStack>
    )
}

ConfirmedSfId.propTypes = {
    jobseeker: PropTypes.shape({
        sfId: PropTypes.string.isRequired,
        prefecture: PropTypes.string,
        age: PropTypes.number,
        sex: PropTypes.string,
        code: PropTypes.string,
        qualifications: PropTypes.string,
    }).isRequired,
    onClick: PropTypes.func,
}
