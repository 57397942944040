import { HamburgerIcon } from '@chakra-ui/icons'
import {
    Stack,
    Heading,
    Button,
    Text,
    Divider,
    Code,
    Box,
    Flex,
    Container,
    Spacer,
    Menu,
    MenuButton,
    IconButton,
    MenuList,
    HStack,
} from '@chakra-ui/react'
import PropTypes from 'prop-types'
import { useCallback } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'

import { Head } from '../Head'
import { Footer } from './MainLayout/Footer'
import { HamburgerMenu } from './MainLayout/Header/HamburgerMenu'

import { ExtraMenuIcon } from '@/assets/icon'
import { LogoMenu } from '@/components/Layout/LogoMenu'

export const ErrorLayout = (props) => {
    const { resetErrorBoundary } = props
    const location = useLocation()
    const navigate = useNavigate()
    const reset = useCallback(() => {
        // resetErrorBoundary && resetErrorBoundary()
        navigate('/')
    }, [resetErrorBoundary, navigate])

    /** @type {{ code?: number; message?: string; error?: any }} */
    const state = location.state
    const code = state?.code ?? props?.code ?? 500
    const message = state?.message || props?.message
    const error = state?.error || props?.error
    const date = new Date()

    // メンテナンス画面表示
    if (props?.error?.name === 'ChunkLoadError') {
        window.location.reload()
    }

    return (
        <Flex direction="column" w="100vw" h="100vh">
            <Box p="16px 10px 16px 10px">
                <HStack>
                    <LogoMenu isDisplay={false} />
                    <Spacer />
                    <Box>
                        <Menu>
                            <MenuButton
                                as={IconButton}
                                icon={<ExtraMenuIcon />}
                                rounded="md"
                                variant="ghost"
                                fontSize="24.38px"
                                width="50px"
                                height="50px"
                                _hover={{
                                    bg: '#EAEEFA',
                                }}
                                _active={{
                                    bg: '#EAEEFA',
                                }}
                            />
                            <MenuList>
                                <HamburgerMenu isDisplay={false} />
                            </MenuList>
                        </Menu>
                    </Box>
                </HStack>
            </Box>
            <Box as="main" flex={1}>
                <Head errorTitle={`ERROR ${code}`} />
                <Divider />
                <Heading
                    as="h1"
                    fontSize="64px"
                    color="primary.500"
                    m="60px"
                    textAlign="center"
                >
                    ERROR
                </Heading>
                <Container>
                    <Stack
                        w="100%"
                        gap={6}
                        mb="1.5rem"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Stack textAlign="center">
                            <Box display="flex" margin="0 auto">
                                <Text>{code}</Text>&nbsp;&nbsp;
                                <Text>
                                    {message || 'システムエラーが発生しました'}
                                </Text>
                            </Box>
                            <Text fontSize="14px">
                                発生日時：{date.toLocaleString()}
                            </Text>
                        </Stack>
                        {error && (
                            <Code
                                colorScheme="red"
                                p={2}
                                as="pre"
                                whiteSpace="pre-wrap"
                                width="62%"
                            >
                                {error?.stack ?? JSON.stringify(error)}
                            </Code>
                        )}
                        <Button onClick={reset} variant="gradient" width="62%">
                            TOPページ
                        </Button>
                    </Stack>
                </Container>
            </Box>
            <Box as="footer" width="100%">
                <Footer />
            </Box>
        </Flex>
    )
}
ErrorLayout.propTypes = {
    error: PropTypes.any,
    resetErrorBoundary: PropTypes.func,
    code: PropTypes.number,
    message: PropTypes.string,
}
