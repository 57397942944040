import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    compare: {
        ids: [],
    },
    search: {
        query: {},
    },
}

export const facilitiesSlice = createSlice({
    name: 'facilities',
    initialState,
    reducers: {
        setSearchFacilities: (state, action) => {
            state.search = action.payload
        },
    },
})

export const facilitiesReducer = facilitiesSlice.reducer

export const { setSearchFacilities } = facilitiesSlice.actions

export const selectSearchFacilities = (state) => state.facilities.search

export const selectCompareFacilities = (state) => state.facilities.compare
