// see: https://github.com/sideway/joi/pull/2377
export default (base) => ({
    type: 'populated',
    base: base
        .object({ _id: base.objectid().required() })
        .alter({ server: (schema) => schema.cast('depopulate') })
        .meta({ baseType: 'object' }),
    cast: {
        depopulate: {
            from: () => true,
            to: (value) => value._id,
        },
    },
})
