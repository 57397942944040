import { Box, Image, Grid, GridItem } from '@chakra-ui/react'
import PropTypes from 'prop-types'
import { createContext, useReducer } from 'react'

import { RevisonCheckDialogDialog } from '../../../features/modals/revisioncheck/revisionCheckDialog'
import { Footer } from './Footer'
import { Header } from './Header/Header'

import Logo from '@/assets/img/TRYT_colour_posi.png'

export const FreewordStateContext = createContext(undefined)
export const FreewordDispatchContext = createContext(undefined)

export const ToggleViewingModeStateContext = createContext(undefined)
export const ToggleViewingModeDispatchContext = createContext(undefined)

const FreewordProvider = ({ children }) => {
    const [state, dispatch] = useReducer(
        (state, action) => {
            switch (action.type) {
                case 'FREEWORD_SEARCH':
                    return { ...state, searchClear: action.onClear }
                case 'FREEWORD_FILTER':
                    return { ...state, filterClear: action.onClear }
            }
            return { onClear: action.onClear }
        },
        { onClear: () => {} }
    )

    return (
        <FreewordDispatchContext.Provider value={dispatch}>
            <FreewordStateContext.Provider value={state}>
                {children}
            </FreewordStateContext.Provider>
        </FreewordDispatchContext.Provider>
    )
}
FreewordProvider.propTypes = {
    children: PropTypes.node.isRequired,
}

const initialState = { isJobseekerViewing: false }

const reducer = (state, action) => {
    switch (action.type) {
        case 'TOGGLE_VIEWING_MODE': {
            return { ...state, isJobseekerViewing: !state.isJobseekerViewing }
        }
        default: {
            throw Error()
        }
    }
}

const ToggleViewingModeProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState)

    return (
        <ToggleViewingModeDispatchContext.Provider value={dispatch}>
            <ToggleViewingModeStateContext.Provider value={state}>
                {children}
            </ToggleViewingModeStateContext.Provider>
        </ToggleViewingModeDispatchContext.Provider>
    )
}
ToggleViewingModeProvider.propTypes = {
    children: PropTypes.node.isRequired,
}

const MainLayout = ({ children }) => {
    return (
        <Box
            minHeight="100vh"
            sx={{
                //ヘッダの高さ
                '--header-height': '110px',
                '@media print': {
                    '.main-layout': {
                        overflow: 'visible',
                        header: {
                            position: 'static',
                            '.sticky-nav-bar': {
                                display: 'none',
                            },
                            '.print-logo': {
                                display: 'block',
                            },
                        },
                    },
                },
            }}
        >
            <FreewordProvider>
                <ToggleViewingModeProvider>
                    {/* see: https://developer.mozilla.org/ja/docs/Web/CSS/Layout_cookbook/Sticky_footers */}
                    <Grid
                        h="full"
                        gridTemplateRows="auto 1fr auto"
                        className="main-layout"
                        //see: https://stackoverflow.com/a/65791501
                        overflow="auto"
                    >
                        <GridItem
                            as="header"
                            position="sticky"
                            top={0}
                            zIndex="banner"
                            bg="white"
                            minW={0}
                        >
                            <Box className="sticky-nav-bar">
                                <Header />
                            </Box>
                            <Image
                                alt="ロゴ"
                                w="200px"
                                minW="200px"
                                h="45.74px"
                                marginTop={2}
                                src={Logo}
                                display="none"
                                className="print-logo"
                            />
                        </GridItem>
                        <GridItem as="main">{children}</GridItem>
                        <GridItem as="footer">
                            <Footer />
                        </GridItem>
                    </Grid>
                </ToggleViewingModeProvider>
            </FreewordProvider>
            <RevisonCheckDialogDialog />
        </Box>
    )
}
MainLayout.propTypes = {
    children: PropTypes.node.isRequired,
}

export { MainLayout }
