import { createSlice, createEntityAdapter } from '@reduxjs/toolkit'
import cloneDeep from 'lodash.clonedeep'
import omit from 'lodash.omit'
import qs from 'shared/utils/qs'

const searchAdapter = createEntityAdapter({
    selectId: (history) => history.path,
    sortComparer: (a, b) => b.timestamp - a.timestamp,
})

export const searchSlice = createSlice({
    name: 'search',
    initialState: searchAdapter.getInitialState({
        formData: null,
        freeword: '',
    }),
    reducers: {
        pushSearchHistory: (state, action) => {
            searchAdapter.setOne(state, action.payload)
        },
        setSearchFormData: (state, action) => {
            state.formData = cloneDeep(action.payload)
        },
        clearSearchFormData: (state, action) => {
            state.formData = null
        },
        setFreeword: (state, action) => {
            state.freeword = action.payload
        },
    },
})

export const prepareHistory = ({ query, count, description }) => {
    if (
        !Object.keys(query).some(
            (key) => !['q', 'sort', 'direction'].includes(key)
        )
    ) {
        // NOTE: キーワード・並び順以外ないときは保存しないのでそのチェック
        return null
    }
    return {
        // フリーワードを除外する
        // マップか地図かを保存しなくなった
        path:
            '/facilities' +
            qs.stringify(omit(query, 'q'), {
                addQueryPrefix: true,
            }),
        // フリーワードがあるときは件数を使わない
        count: query?.q ? null : count,
        description,
    }
}

export const {
    pushSearchHistory,
    setSearchFormData,
    clearSearchFormData,
    setFreeword,
} = searchSlice.actions

export const searchReducer = searchSlice.reducer

export const searchSelectors = searchAdapter.getSelectors(
    (state) => state.search
)
