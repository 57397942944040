import PropTypes from 'prop-types'
import { useState } from 'react'

import { highPerformerApi } from '..'

import { getUserId } from '@/features/auth'

export const HighPerformersOnly = ({ children }) => {
    const [userId] = useState(() => getUserId())
    const { data } = highPerformerApi.endpoints.listHighPerformers.useQuery({
        hp: userId,
    })
    if (data?.count) {
        return <>{children}</>
    }
    return null
}
HighPerformersOnly.propTypes = {
    children: PropTypes.node.isRequired,
}
