import { CloseIcon } from '@chakra-ui/icons'
import {
    Input,
    InputGroup,
    InputRightElement,
    Box,
    Icon,
} from '@chakra-ui/react'
import PropTypes from 'prop-types'

import { SearchIcon, FreewordFilterIcon } from '@/assets/icon'

export const FreewordInput = ({
    onSubmit = undefined,
    inputRef = undefined,
    onClear,
    value,
    ...props
}) => {
    return (
        <InputGroup>
            <Input
                {...props}
                ref={inputRef}
                sx={{ paddingRight: value ? 16 : 10 }}
                maxLength={50}
            />
            <InputRightElement right={3}>
                <Box
                    as="button"
                    color="primary.500"
                    onClick={onClear}
                    sx={{ visibility: value ? 'visible' : 'hidden' }}
                    type="button"
                    marginRight={2}
                >
                    <Icon as={CloseIcon} />
                </Box>
                <Box
                    as="button"
                    color="primary.500"
                    onClick={onSubmit}
                    type={onSubmit ? 'button' : 'submit'}
                >
                    <SearchIcon />
                </Box>
            </InputRightElement>
        </InputGroup>
    )
}
FreewordInput.propTypes = {
    onSubmit: PropTypes.func,
    inputRef: PropTypes.object,
}
export const FreewordFilterInput = ({
    onSubmit = undefined,
    inputRef = undefined,
    onClear,
    value,
    ...props
}) => {
    return (
        <InputGroup width="285px">
            <Input
                {...props}
                ref={inputRef}
                sx={{ paddingRight: value ? 16 : 10 }}
                maxLength={50}
            />
            <InputRightElement right={3}>
                {value && (
                   <Box
                   as="button"
                   color="primary.500"
                   onClick={onClear}
                   visibility={!value ? 'hidden' : undefined}
                   type="button"
                   marginRight={2}
               >
                   <Icon as={CloseIcon} />
               </Box>                  
                )}
                <Box
                    as="button"
                    color="primary.500"
                    onClick={onSubmit}
                    type={onSubmit ? 'button' : 'submit'}
                >
                    <FreewordFilterIcon />
                </Box>
            </InputRightElement>
        </InputGroup>
    )
}
FreewordInput.propTypes = {
    onSubmit: PropTypes.func,
    inputRef: PropTypes.object,
}