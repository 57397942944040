import {
    Box,
    Button,
    Modal,
    ModalBody,
    ModalOverlay,
    ModalContent,
    ModalCloseButton,
    ModalHeader,
    ModalFooter,
    VStack,
    AlertDialogHeader,
} from '@chakra-ui/react'
import PropTypes from 'prop-types'

export const ResetSfIdModal = ({ onReset, onCancel, onClose, isOpen }) => {
    return (
        <Modal size="4xl" isOpen={isOpen} onClose={onClose ?? onCancel}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    <AlertDialogHeader textAlign="center" fontFamily="fonts">
                        SFIDを切り替えますか?
                    </AlertDialogHeader>
                    <ModalCloseButton onClick={onClose ?? onCancel} />
                </ModalHeader>
                <ModalBody>
                    <VStack>
                        <Box>
                            <Button
                                w="300px"
                                mb="20px"
                                autoFocus
                                onClick={onReset}
                            >
                                OK
                            </Button>
                            <Button
                                w="300px"
                                ml="10px"
                                mb="20px"
                                onClick={onCancel}
                            >
                                キャンセル
                            </Button>
                        </Box>
                    </VStack>
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}

ResetSfIdModal.propTypes = {
    onReset: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onClose: PropTypes.func,
    isOpen: PropTypes.bool.isRequired,
}
