import Axios from 'axios'
import { User } from 'oidc-client-ts'
import qs from 'shared/utils/qs'

import { API_URL, OIDC_AUTHORITY, OIDC_CLIENT_ID } from '@/config'

function paramsSerializer(params) {
    return qs.stringify(params)
}

function getUser() {
    const oidcStorage = localStorage.getItem(
        `oidc.user:${OIDC_AUTHORITY}:${OIDC_CLIENT_ID}`
    )
    if (!oidcStorage) {
        return null
    }
    return User.fromStorageString(oidcStorage)
}

function authRequestInterceptor(config) {
    const user = getUser()
    const token = user?.access_token
    if (token) {
        config.headers.authorization = `Bearer ${token}`
    }
    config.headers.Accept = 'application/json'
    return config
}

export const axios = Axios.create({
    baseURL: API_URL,
    paramsSerializer,
})

axios.interceptors.request.use(authRequestInterceptor)
axios.interceptors.response.use(
    (response) => {
        return response.data
    },
    (error) => {
        // const originalRequest = error.config
        // const message = error.response?.data?.message || error.message
        // useNotificationStore.getState().addNotification({
        //     type: 'error',
        //     title: 'Error',
        //     message,
        // })

        return Promise.reject(error)
    }
)

/**
 * @template T
 * @typedef {Object} Collection
 * @property {T[]} items
 */

/**
 * @template T
 * @typedef {Object} CollectionWithPaging
 * @property {T[]} items
 * @property {Paging} paging
 */

/**
 * @typedef {Object} Paging
 * @property {number} count
 * @property {number} perPage
 * @property {number} page
 * @property {number} pageCount
 * @property {number} current
 */
