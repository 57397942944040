import { useContext } from 'react'

import { FacilityContext } from '..'
import {
    TextHeadingComponent,
    TextHStackComponent,
} from '../../FacilityItemComponents'

export const HoikuItems = () => {
    const data = useContext(FacilityContext)

    return (
        <>
            <TextHeadingComponent
                label="開所曜日"
                isObject={[data.businessDayDay, data.receieve]}
            >
                <TextHStackComponent label="">
                    {data.businessDayDay}
                </TextHStackComponent>
            </TextHeadingComponent>
            {/* 病児保育の受け入れのデータは今後追加される予定 */}
            <TextHeadingComponent
                label="病児保育の受け入れ"
                isObject={[data.receive]}
            >
                <TextHStackComponent label="">
                    {data.receieve}
                </TextHStackComponent>
            </TextHeadingComponent>
        </>
    )
}
