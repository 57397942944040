import { useAuth } from 'react-oidc-context'
import { Navigate, useLocation } from 'react-router-dom'

import { getRedirectPath } from '../api'

const AuthCallback = () => {
    const redirectPath = getRedirectPath()
    const location = useLocation()
    const auth = useAuth()
    console.log('AuthCallback', auth, location, redirectPath)

    if (!location.search && redirectPath !== '/logout') {
        return <Navigate to="/login" />
    }
    if (auth.isLoading) {
        return <div>Loading...</div>
    }

    return <Navigate to={redirectPath || '/'} replace />
}

export { AuthCallback }
