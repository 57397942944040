// import { axios } from '@/lib/axios'
// import axios from 'axios'
import { backendApi } from '@/stores/backendApi'
/**
 * @typedef {import('@/types/api').operations} ApiOperations
 *
 * @typedef {ApiOperations['list-v1-proposals']['responses']['200']['content']['application/json']} ListProposals
 *
 * @typedef {ApiOperations['list-v1-proposals']['parameters']['query']} ListProposalsParams
 *
 * @typedef {ApiOperations['get-v1-proposals-:id']['responses']['200']['content']['application/json']} GetProposals
 *
 * @typedef {ApiOperations['list-v1-facilities']['responses']['200']['content']['application/json']} ListFacilities
 *
 * @typedef {ApiOperations['list-v1-facilities']['parameters']['query']} ListFacilitiesParams
 *
 * @typedef {ApiOperations['get-v1-facilities-:id']['responses']['200']['content']['application/json']} GetFacilities
 *
 * @typedef {ApiOperations['list-v1-facilities-selections']['responses']['200']['content']['application/json']} ListFacilitiesSelection
 *
 * @typedef {ApiOperations['list-v1-facilities-selections']['parameters']['query']} ListFacilitiesSelectionParams
 */
export const DashboardApi = backendApi.injectEndpoints({
    endpoints: (builder) => {
        return {
            /** @type {ReturnType<typeof builder.query<ListProposals, ListProposalsParams>>} */
            listProposals: builder.query({
                query: (params) => ({
                    url: '/v1/proposals',
                    params,
                }),
                providesTags: () => ['Proposal'],
            }),
            /** @type {ReturnType<typeof builder.query<GetProposals, string>>} */
            getProposals: builder.query({
                query: (_id) => ({
                    url: `/v1/proposals/${_id}`,
                }),
                invalidatesTags: ['Proposal'],
            }),
            /** @type {ReturnType<typeof builder.query<ListFacilities, ListFacilitiesParams>>} */
            listFacilities: builder.query({
                query: (params) => ({
                    url: '/v1/facilities',
                    params,
                }),
            }),
            /** @type {ReturnType<typeof builder.query<GetFacilities, string>>} */
            getFacilities: builder.query({
                query: (_id) => ({
                    url: `/v1/facilities/${_id}`,
                }),
            }),
            /** @type {ReturnType<typeof builder.query<ListFacilities, ListFacilitiesSelectionParams>>} */
            listFacilitiesSelection: builder.query({
                query: (params) => ({
                    url: '/v1/facilities/selections',
                    params,
                }),
                providesTags: ['Selection'],
            }),
        }
    },
    overrideExisting: false,
})
export const {
    useListProposalsQuery,
    useGetProposalsQuery,
    useListFacilitiesQuery,
    useGetFacilitiesQuery,
    useListFacilitiesSelectionQuery,
} = DashboardApi
export default DashboardApi.reducer
