import PropTypes from 'prop-types'

import { useAdmin } from '../hooks'

import { ErrorLayout } from '@/components/Layout'

export const AdminGuard = ({ children }) => {
    const { isAdmin } = useAdmin()
    // console.log('useAdmin', isAdmin, data)
    if (isAdmin) {
        return <>{children}</>
    } else {
        return <ErrorLayout code={403} message="Forbidden" />
    }
}
// AdminGuard.whyDidYouRender = true
AdminGuard.propTypes = {
    children: PropTypes.node.isRequired,
}
