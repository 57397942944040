//kaigoCommonServiceTypeChoicesに0(その他)を加えたもの
const kaigoServiceCode = [
    '0',
    '101',
    '102',
    '103',
    '104',
    '105',
    '106',
    '107',
    '108',
    '109',
    '110',
    '111',
    '112',
    '113',
    '114',
    '115',
    '116',
    '117',
    '118',
    '119',
    '120',
    '121',
    '122',
    '123',
    '124',
    '201',
    '202',
    '203',
    '204',
    '205',
    '206',
    '207',
    '208',
    '209',
    '210',
    '211',
    '212',
    '213',
    '214',
    '215',
    '216',
    '217',
    '218',
    '219',
    '220',
    '221',
    '222',
    '223',
    '224',
    '225',
    '226',
    '227',
    '228',
]
//hoikuCommonServiceTypeChoicesから220,221,222,223,224,225,226を除いたもの
const hoikuServiceCode = [
    '401',
    '402',
    '403',
    '404',
    '405',
    '406',
    '407',
    '408',
    '409',
    '410',
    '411',
    '412',
    '413',
]
//全serviceCodeから104,321,423をのぞいたものがkangoCommonServiceTypeChoices
const kangoServiceCode = ['301', '302', '303', '304', '305', '306', '310']

export const KaigoServiceCode = (serviceCode) => {
    return kaigoServiceCode.includes(serviceCode)
}
export const HoikuServiceCode = (serviceCode) => {
    return hoikuServiceCode.includes(serviceCode)
}
export const KangoServiceCode = (serviceCode) => {
    return kangoServiceCode.includes(serviceCode)
}
