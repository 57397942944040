import { backendApi } from '@/stores/backendApi'

export const AdminApi = backendApi.injectEndpoints({
    endpoints: (builder) => {
        return {
            listHistories: builder.query({
                query: (params) => ({
                    url: '/v1/search-histories',
                    params,
                }),
                providesTags: ['SearchHistory'],
            }),
        }
    },
    overrideExisting: false,
})
export const { useListHistoriesQuery } = AdminApi
