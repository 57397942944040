import { createListenerMiddleware, isAnyOf } from '@reduxjs/toolkit'

import { confirmJobseeker, resetJobseeker, clearSearchFormData } from '@/stores'

/**
 * 求職者情報の変更で一時保存フォームを破棄する
 *
 * @see {@link https://redux-toolkit.js.org/api/createListenerMiddleware}
 */
export const clearSearchFormMiddleware = createListenerMiddleware()
clearSearchFormMiddleware.startListening({
    matcher: isAnyOf(confirmJobseeker, resetJobseeker),
    effect: (action, listenerApi) => {
        // 求職者変更で編集中のフォームデータ破棄
        listenerApi.dispatch(clearSearchFormData())
    },
})
